import {Layout} from '@/components/common';
import {useEffect, useState} from 'react';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {BoardList} from '@/types/boards.type';
import {getBoardDetailService} from '@/services/boardService';
import {parseHtmlContentWithSanitize} from '@/utils/parse';
import {replaceDateFormat} from '@/utils/format';

export function NoticeDetail() {
  const navigate = useNavigate();
  const {state: locationState} = useLocation();
  const [noticeData, setNoticeData] = useState<BoardList>();

  const {id: noticeId} = useParams<{id: string}>();

  useEffect(() => {
    if (!noticeId) {
      navigate('/notice');
      return;
    }

    // url 입력을 통해 바로 접근했을때
    if (!locationState) {
      fetchNoticeData(noticeId);
      return;
    }

    setNoticeData(locationState);
  }, []);

  async function fetchNoticeData(noticeId: string) {
    try {
      const res = await getBoardDetailService(noticeId);
      setNoticeData(res);
    } catch (e: any) {
      alert(e); // modal
    }
  }

  return (
    <Layout>
      <div className="flex justify-center my-8">
        <h1 className="text-3xl font-semibold">공지사항</h1>
      </div>
      {noticeData && (
        <>
          <div className="w-full px-4 my-4 pb-2 border-b border-gray-400">
            <h1 className="text-2xl font-bold">{noticeData.title}</h1>
          </div>
          <div className="w-full flex mb-16">
            <span className="text-sm text-gray-400 pl-[16px]">
              작성자:&nbsp;{noticeData.author.name}
            </span>
            <span className="text-sm text-gray-400 ml-auto">
              {replaceDateFormat(noticeData.createdAt, 'YY.MM.DD(ddd) HH:MM')}
            </span>
          </div>
          <div className="w-full px-4 mb-8">
            {parseHtmlContentWithSanitize(noticeData.content)}
          </div>
        </>
      )}
      {!noticeData && <div>잘못된 요청이거나 오류가 발생했습니다.</div>}
    </Layout>
  );
}
