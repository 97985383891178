import {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {IoPlay, IoAttach} from 'react-icons/io5';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';
import {
  Layout,
  PrimarySquareButton,
  SquareButton,
  TableHeader,
  TableRow,
  TableData,
  SecondarySquareButton,
  PrimarySquareButtonWithIcon,
  TableRowEmpty,
} from '@/components/common';
import {CourseInfoCard} from '@/components/CourseInfoCard';
import {searchCourseDetailService} from '@/services/courseService';
import {
  getHomeworkListService,
  getMyHomeworkListService,
} from '@/services/chapterService';
import {
  RegisterCourse,
  AttendStatus,
  Chapter,
  HomeworkForChapter,
} from '@/types/course.type';
import {useChapter} from '@/hooks/useChapter';
import useModal from '@/hooks/useModal';
import {getToday} from '@/utils/format';
import {Attachment} from '@/types/common.type';

export function CourseDetail() {
  const navigate = useNavigate();
  const modal = useModal();
  const siteData = useRecoilValue(siteState);
  const [courseInfo, setCourseInfo] = useState<RegisterCourse>();
  const [homeworkList, setHomeworkList] = useState<HomeworkForChapter[]>([]);
  const [myHomeworkList, setMyHomeworkList] = useState([]);
  const {id: courseId} = useParams<{id: string}>();
  const [showHomework, setShowHomework] = useState(false);
  const [isEduPeriod, setIsEduPeriod] = useState(true);
  if (!courseId) {
    alert('해당 강의가 없습니다.');
    navigate('/course');
    return null;
  }
  const {chapterList, isLoading} = useChapter(courseId);

  useEffect(() => {
    // 현재 페이지의 코스 정보 요청
    fetchCourseInfo();

    // 과제 리스트 조회
    fetchHomeworkList();

    // 제출한 과제 리스트 조회
    fetchMyHomeworkList();
  }, [siteData]);

  useEffect(() => {
    if (courseInfo) {
      const now = getToday();
      if (now < courseInfo.startsAt || now > courseInfo.endsAt) {
        setIsEduPeriod(false);
      }
    }
  }, [courseInfo]);

  // 현재 코스 정보 조회
  async function fetchCourseInfo() {
    if (!courseId) {
      alert('잘못된 요청이거나 해당 교육 과정이 없습니다.');
      navigate('/register');
      return;
    }

    try {
      const courseInfoResponse = await searchCourseDetailService(courseId);
      setCourseInfo(courseInfoResponse);
    } catch (e: any) {
      // console.log(e);
    }
  }

  // 현재 코스의 챕터별 과제 리스트 조회
  async function fetchHomeworkList() {
    try {
      const res = await getHomeworkListService();
      setHomeworkList(res.content);
    } catch (e: any) {
      // console.log(e);
    }
  }

  // 제출한 과제 리스트 조회
  async function fetchMyHomeworkList() {
    try {
      const res = await getMyHomeworkListService();
      setMyHomeworkList(res.content);
    } catch (e: any) {
      // console.log(e);
    }
  }

  // 강의 자료 다운로드
  function handleDownload(attachments: Attachment[]) {
    const fileUrl = attachments[0]?.fileUrl ?? '';
    if (!fileUrl) {
      return;
    }
    window.location.assign(fileUrl);
  }

  // 출석 상태 생성
  const genAttendStatus = (attendStatus?: string): AttendStatus => {
    switch (attendStatus) {
      case 'ATTEND':
        return {
          type: 'primary',
          value: '출석',
        };
      case 'NOT_ATTEND':
        return {
          type: 'secondary',
          value: '결석',
        };
      case 'E_LEAVE':
        return {
          type: 'secondary',
          value: '조퇴',
        };
      default:
        return {
          type: 'disabled',
          value: '예정',
        };
    }
  };

  function handleChapterClick(clickedChapter: string) {
    navigate(`/homework/${courseId}?chapter=${clickedChapter}`);
  }

  return (
    <Layout>
      {/* 상단 수강 정보 카드 */}
      <CourseInfoCard courseInfo={courseInfo} />

      {/* 버튼 영역 */}
      <div className="flex justify-center my-4">
        <PrimarySquareButton onClick={() => navigate('/course')} text="목록" />
        <SecondarySquareButton
          text="수료증 출력"
          onClick={() => navigate('/certificate')}
        />
      </div>

      {/* 렉쳐 리스트 */}
      <div className="w-full px-4 mt-10">
        <table className="w-full">
          <thead className="bg-primary bg-opacity-10">
            <TableRow header>
              <TableHeader hiddenMobile>번호</TableHeader>
              <TableHeader>강의</TableHeader>
              <TableHeader>영상</TableHeader>
              <TableHeader hiddenMobile>수강률</TableHeader>
              <TableHeader hiddenTablet>출석</TableHeader>
              <TableHeader>과제</TableHeader>
              <TableHeader>자료</TableHeader>
            </TableRow>
          </thead>
          <tbody className="border-b border-disabled">
            {!isLoading &&
              chapterList.length > 0 &&
              chapterList.map((item: Chapter, index: number) => {
                const attendStatus = genAttendStatus(item.attendStatus);
                if (item.status === 'ACTIVE')
                  return (
                    <TableRow key={index}>
                      {/* 번호 */}
                      <TableData hiddenMobile>{index + 1}</TableData>
                      {/* 강의명 */}
                      <TableData>{item.name}</TableData>
                      {/* 영상 링크 */}
                      <TableData>
                        <PrimarySquareButtonWithIcon
                          onClick={() => {
                            isEduPeriod
                              ? navigate(
                                  `/lecture/${courseId}?chapter=${item.id}`,
                                )
                              : alert(
                                  '교육기간이 아닙니다. 교육기간을 확인해주세요. ',
                                );
                          }}
                          icon={<IoPlay style={{verticalAlign: 'middle'}} />}
                        />
                      </TableData>
                      {/* 수강률 */}
                      <TableData hiddenMobile>{item.progressRate}%</TableData>
                      {/* 출석 */}
                      <TableData hiddenTablet>
                        <SquareButton
                          text={attendStatus.value}
                          type={attendStatus.type}
                          hasNoHoverEffect={true}
                        />
                      </TableData>
                      {/* 과제 */}
                      <TableData>
                        {item.homeWorkCount === 0 && (
                          <SquareButton type="disabled" text="없음" />
                        )}
                        {item.homeWorkCount !== 0 && (
                          <SquareButton
                            type="secondary"
                            text="보기"
                            onClick={() => handleChapterClick(item.id)}
                          />
                        )}
                      </TableData>
                      {/* 자료 */}
                      <TableData>
                        <button
                          onClick={() => handleDownload(item.attachments)}
                          className={`${
                            item.attachments.length > 0
                              ? 'text-xl cursor-pointer'
                              : 'text-xl text-disabled cursor-default'
                          }`}>
                          <IoAttach />
                        </button>
                      </TableData>
                    </TableRow>
                  );
              })}
            {!isLoading && chapterList.length === 0 && (
              <TableRowEmpty text="강의가 없습니다." />
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
