import {useQuery} from 'react-query';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';
import {
  reqGetChapterList,
  reqGetLectureList,
  reqGetXProgress,
} from '@/api/courseApi';
import {Chapter} from '@/types/chapter.type';
import {XProgress} from '@/types/common.type';

export function useChapter(courseId: string) {
  const siteData = useRecoilValue(siteState);
  const siteId = siteData?.id;

  const {
    data: chapterList,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['chapterList', {siteId, courseId}],
    queryFn: async ({queryKey}) => {
      const {siteId, courseId} = queryKey[1] as {
        siteId: string;
        courseId: string;
      };

      // 현재 코스의 렉쳐 리스트 요청
      const lectureListResponse = await reqGetLectureList(courseId);
      const lectureList = lectureListResponse.data.data.content;

      // 렉쳐마다의 챕터 조회
      const chapterListResponse = await reqGetChapterList(lectureList[0].id);
      const chapterList = chapterListResponse.data.data.content;

      // 챕터별 수강률 조회
      const chapterProgressResponse = await reqGetXProgress({
        xType: 'CHAPTER',
        siteId,
        courseId,
      });
      const chapterProgress = chapterProgressResponse.data.data;

      // const newChapterList = chapterList.map((item: Chapter) => {
      //   for (const chapterProg of chapterProgress) {
      //     if (chapterProg.chapterId === item.id) {
      //       return {...item, ...chapterProg};
      //     }
      //   }
      // });
      //
      // return newChapterList.filter((data: Chapter) => data !== undefined);

      return chapterList
        .map((item: Chapter) => {
          const chapterProg = chapterProgress.find(
            (data: XProgress) => data.chapterId === item.id,
          );
          if (chapterProg) {
            return {...item, ...chapterProg};
          }
          return item;
        })
        .filter((data: Chapter) => data !== undefined);
    },
    enabled: !!siteId && !!courseId,
    initialData: [],
  });

  return {chapterList, refetch, isLoading};
}
