import {useState, ChangeEvent} from 'react';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';
import {Link} from 'react-router-dom';
import {Layout, Input} from '@/components/common';
import {IoPhonePortraitSharp, IoKeypad} from 'react-icons/io5';
import {encodeHyphenPhoneNumber, decodeHyphenPhoneNumber} from '@/utils/format';
import {ValidatePhoneSchema} from '@/utils/validator';
import {
  requestSmsCodeService,
  validateSmsCodeService,
  findEmailService,
} from '@/services/userService';

export function UserFindEmail() {
  const siteData = useRecoilValue(siteState);
  const [phone, setPhone] = useState('');
  const [phoneCaption, setPhoneCaption] = useState('');
  const [code, setCode] = useState('');
  const [isSentCode, setIsSentCode] = useState(false);
  const [isPhoneChecked, setIsPhoneChecked] = useState(false);
  const [verifiedId, setVerifiedId] = useState('');
  const [email, setEmail] = useState('');

  if (!siteData) return null;

  const {logo} = siteData;

  function handlePhoneChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const newValue = encodeHyphenPhoneNumber(value);
    setPhone(newValue);

    try {
      ValidatePhoneSchema.validateSync(decodeHyphenPhoneNumber(phone));
      setPhoneCaption('');
    } catch (e: any) {
      setPhoneCaption(e.message);
    }
  }

  function handleCodeChange(e: ChangeEvent<HTMLInputElement>) {
    setCode(e.target.value);
  }

  async function sendVerificationCode() {
    const decodedPhone = decodeHyphenPhoneNumber(phone);
    try {
      ValidatePhoneSchema.validateSync(decodedPhone);
      setPhoneCaption('');
    } catch (e: any) {
      setPhoneCaption(e.message);
      return;
    }

    try {
      await requestSmsCodeService(siteData!.id, 'FIND_EMAIL', decodedPhone);
      setIsSentCode(true);
      alert('인증 문자가 발송되었습니다.');
    } catch (e: any) {
      alert(e.data.error.message);
    }
  }

  async function verifyVerificationCode() {
    if (code.length === 0) {
      return;
    }

    const decodedPhone = decodeHyphenPhoneNumber(phone);

    try {
      const verifiedId = await validateSmsCodeService(
        siteData!.id,
        'FIND_EMAIL',
        decodedPhone,
        code,
      );
      setIsPhoneChecked(true);
      setUserEmail(verifiedId);
    } catch (e: any) {
      alert(e.data.error.message);
    }
  }

  async function setUserEmail(verifiedId: string) {
    const decodedPhone = decodeHyphenPhoneNumber(phone);
    try {
      const userEmail = await findEmailService(
        siteData!.id,
        decodedPhone,
        verifiedId,
      );
      setEmail(userEmail);
    } catch (e: any) {
      alert(e.data.error.message);
    }
  }

  return (
    <Layout isHiddenFooter isSignHeader>
      <div className="w-full h-full mb-0 flex flex-col items-center py-[30%] md:py-[20%] lg:py-[20%]">
        <div className="w-full max-w-[500px] flex flex-col items-center">
          {/* 기관 로고 */}
          <img
            src={logo}
            alt="기관 로고"
            className="w-[200px] md:w-[215px] lg:w-[230px]"
          />
          {!email || !isPhoneChecked ? (
            <>
              <h2 className="text-lg md:text-2xl font-semibold text-secondary mt-8">
                이메일 찾기
              </h2>
              <form className="w-full flex flex-col items-center px-8 mb-10 mt-4">
                <Input
                  id="phone"
                  type="text"
                  placeholder="휴대폰 번호"
                  value={phone}
                  maxLength={13}
                  onChange={handlePhoneChange}
                  onKeyDown={event => {
                    if (event.keyCode === 13) sendVerificationCode();
                  }}
                  addClass={`${isPhoneChecked && 'bg-readOnly'}`}
                  leftIcon={
                    <IoPhonePortraitSharp className="text-lg md:text-xl" />
                  }
                  rightIcon={
                    <span
                      onClick={
                        isSentCode && isPhoneChecked
                          ? undefined
                          : sendVerificationCode
                      }
                      className={`w-auto whitespace-nowrap px-3 py-1 cursor-pointer border border-secondary bg-transparent text-center text-secondary font-semibold text-sm w-[50px] transition-all duration-150 rounded-full ${
                        isPhoneChecked
                          ? 'button-success cursor-default'
                          : 'hover:bg-secondary hover:text-white'
                      }`}>
                      인증 요청
                    </span>
                  }
                />
                {phoneCaption && (
                  <p className="text-red-500 w-full text-sm pl-8">
                    {phoneCaption}
                  </p>
                )}
                {!isPhoneChecked && isSentCode && (
                  <Input
                    id="code"
                    type="text"
                    placeholder="인증번호"
                    value={code}
                    maxLength={13}
                    onChange={handleCodeChange}
                    onKeyDown={event => {
                      if (event.keyCode === 13) verifyVerificationCode();
                    }}
                    addClass={`${isPhoneChecked && 'bg-readOnly'}`}
                    leftIcon={<IoKeypad className="text-lg md:text-xl" />}
                    rightIcon={
                      <span
                        onClick={
                          isSentCode && isPhoneChecked
                            ? undefined
                            : verifyVerificationCode
                        }
                        className={`w-auto whitespace-nowrap px-3 py-1 cursor-pointer border border-secondary bg-transparent text-center text-secondary font-semibold text-sm w-[50px] transition-all duration-150 rounded-full ${
                          isPhoneChecked
                            ? 'button-success cursor-default'
                            : 'hover:bg-secondary hover:text-white'
                        }`}>
                        확인
                      </span>
                    }
                  />
                )}
              </form>
            </>
          ) : (
            <div className="mt-12 mb-8 text-lg">
              <span className="block md:inline-block">등록된 이메일은</span>
              <span className="text-xl text-primary block my-2 md:inline-block md:mx-2">
                {email}
              </span>
              <span className="block md:inline-block">입니다.</span>
            </div>
          )}
          <div className="w-full flex justify-center space-x-4 mt-4">
            <Link
              to="/sign-in"
              className="text-sm font-semibold text-gray-500 hover:underline px-2">
              로그인 하기
            </Link>
            <Link
              to="/user/find/password"
              className="text-sm font-semibold text-gray-500 hover:underline px-2">
              비밀번호 찾기
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
