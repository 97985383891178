import {useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import {IoAdd} from 'react-icons/io5';
import {Container, CircleButton} from '@/components/common';
import {useBoards} from '@/hooks/useBoards';
import {ComponentWithChildren, CardHeaderProps} from '@/types/common.type';
import {BoardList} from '@/types/boards.type';
import {replaceDateFormat} from '@/utils/format';
import {Site} from '@/types/site.type';

interface MainBoardProps {
  siteData: Site;
}

function CardWrapper({children, className}: ComponentWithChildren) {
  return (
    <div
      className={`max-w-[450px] w-full h-[190px] md:h-[200px] lg:h-[210px] border-shadow flex flex-col radius-xl py-5 px-6 w-auto mx-2 md:w-[48%] rounded-xl ${className}`}>
      {children}
    </div>
  );
}

function CardHeader({title, path}: CardHeaderProps) {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between items-center mb-3">
      <h2 className="text-base font-semibold md:text-lg text-primary">
        {title}
      </h2>
      <CircleButton
        icon={<IoAdd />}
        onClick={() => {
          navigate(path);
        }}
      />
    </div>
  );
}

export function MainBoards({siteData}: MainBoardProps) {
  const {
    data: noticeData,
    isLoading: isLoadingNotice,
    refetch: refetchNotice,
  } = useBoards({
    category: 'Notice',
  });

  const {
    data: qnaData,
    isLoading: isLoadingQna,
    refetch: refetchQna,
  } = useBoards({
    category: 'SERVICE',
  });

  useEffect(() => {
    refetchNotice();
    refetchQna();
  }, [siteData]);

  return (
    <Container className="md:items-between justify-evenly md:flex-row">
      {/* 공지사항 카드 */}
      <CardWrapper className="mt-12">
        {/* 공지사항 카드 헤더 */}
        <CardHeader title="공지사항" path="/notice" />
        {/* 공지사항 카드 내용 */}
        <ul>
          {!isLoadingNotice &&
            noticeData.length > 0 &&
            noticeData.slice(0, 4).map((item: BoardList, index: number) => {
              return (
                <Link to={`/notice/${item.id}`} key={index}>
                  <li className="w-full flex justify-between items-center my-2 text-sm lg:text-base">
                    <p className="truncate pr-4">{item.title}</p>
                    <p className="text-gray-400 text-sm">
                      {replaceDateFormat(item.createdAt, 'YY.MM.DD(ddd)')}
                    </p>
                  </li>
                </Link>
              );
            })}
          {!isLoadingNotice && noticeData.length === 0 && (
            <li className="w-full flex justify-between items-center my-2 text-sm lg:text-base">
              <p className="text-gray-400 text-sm">공지 사항이 없습니다.</p>
            </li>
          )}
        </ul>
      </CardWrapper>

      {/* 문의하기 카드 */}
      <CardWrapper className="md:mt-12 mt-6">
        {/* 문의하기 카드 헤더 */}
        <CardHeader title="FAQ" path="/question" />
        {/* 문의하기 카드 내용 */}
        <ul>
          {!isLoadingQna &&
            qnaData.length > 0 &&
            qnaData.slice(0, 4).map((item: BoardList, index: number) => {
              return (
                <Link to={`/question/service/${item.id}`} key={index}>
                  <li className="w-full flex justify-between items-center my-2 text-sm lg:text-base">
                    <p className="truncate pr-4">{item.title}</p>
                    <p className="text-gray-400 text-sm">답변보기</p>
                  </li>
                </Link>
              );
            })}
          {!isLoadingQna && qnaData.length === 0 && (
            <li className="w-full flex justify-between items-center my-2 text-sm lg:text-base">
              <p className="text-gray-400 text-sm">문의 사항이 없습니다.</p>
            </li>
          )}
        </ul>
      </CardWrapper>
    </Container>
  );
}
