import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Api} from '@/App';

interface BaseResponse<T> {
  apiVersion: string;
  txid: string;
  data: T;
  error?: {
    status: number;
    code: string;
    message: string;
    description: string;
  };
}

export async function requestGet<T>(
  uri: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<BaseResponse<T>>> {
  return await Api.get<BaseResponse<T>>(uri, config);
}

export async function requestPost<T>(
  uri: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<BaseResponse<T>>> {
  return await Api.post<BaseResponse<T>>(uri, data, config);
}

export async function requestPut<T>(
  uri: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<BaseResponse<T>>> {
  return await Api.put<BaseResponse<T>>(uri, data, config);
}

export async function requestDelete<T>(
  uri: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<BaseResponse<T>>> {
  return await Api.delete<BaseResponse<T>>(uri, config);
}
