import {useState, ChangeEvent, MouseEvent, useEffect} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import useUser from '@/hooks/useUser';
import siteState from '@/stores/siteAtom';
import {Layout, Input} from '@/components/common';
import {IoMailOutline, IoLockClosedOutline} from 'react-icons/io5';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import {
  ValidateEmailSchema,
  ValidatePasswordSchema,
  ValidateSignInSchema,
} from '@/utils/validator';
import {signInService} from '@/services/userService';
import Modal from '@/components/common/Modal';
import useModal from '@/hooks/useModal';

const INIT_FORM_STATE = {email: '', password: ''};

export function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectQuery = queryParams.get('redirect');
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const {refreshUserState} = useUser();
  const siteData = useRecoilValue(siteState);
  const [form, setForm] = useState(INIT_FORM_STATE);
  const [formCaption, setFormCaption] = useState(INIT_FORM_STATE);
  const [visiblePassword, setVisiblePassword] = useState(false);

  const {logo} = siteData ? siteData : {logo: null};

  function handleForm(e: ChangeEvent<HTMLInputElement>) {
    const {value, id} = e.target;
    const newForm = {...form, [id]: value};
    setForm(newForm);

    try {
      switch (id) {
        case 'email':
          ValidateEmailSchema.validateSync(value);
          break;
        case 'password':
          ValidatePasswordSchema.validateSync(value);
          break;
      }
      setFormCaption({
        ...formCaption,
        [id]: '',
      });
    } catch (e: any) {
      setFormCaption({
        ...formCaption,
        [id]: e.message,
      });
    }
  }

  async function handleSubmit(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const {email, password} = form;
    try {
      ValidateSignInSchema.validateSync({email, password});
    } catch (e) {
      openModal({
        title: '로그인 실패',
        message: '로그인 정보를 올바르게 입력해주세요.',
      });
      return;
    }

    const isSuccess = await signInService(siteData?.id || '', email, password);

    if (!isSuccess) {
      openModal({
        title: '로그인 실패',
        message: '로그인 정보를 확인해주세요.',
      });
      return;
    }

    await refreshUserState();

    if (redirectQuery) {
      navigate(redirectQuery);
      return;
    }
    navigate('/');
  }

  useEffect(() => {
    const handleBackClick = (e: PopStateEvent) => {
      // 뒤로 가기 이벤트가 발생했을 때
      if (redirectQuery) {
        navigate('/');
      }
    };
    window.addEventListener('popstate', handleBackClick);
  }, []);

  return (
    <Layout isHiddenFooter isSignHeader>
      <Modal
        isShowing={isShowing}
        type="error"
        title={modalData?.title}
        message={modalData?.message}
        buttons={[{text: '확인', style: 'normal', onClick: closeModal}]}
      />
      <div className="w-full h-full mb-0 flex flex-col items-center py-[30%] md:py-[20%] lg:py-[20%]">
        <div className="w-full max-w-[500px] flex flex-col items-center">
          {/* 기관 로고 */}
          {logo && (
            <img
              src={logo}
              alt="기관 로고"
              className="w-[200px] md:w-[215px] lg:w-[230px]"
            />
          )}

          {/* 로그인 폼 */}
          <form className="w-full flex flex-col items-center px-8 my-10">
            <Input
              id="email"
              type="email"
              placeholder="이메일"
              value={form.email}
              onChange={handleForm}
              leftIcon={<IoMailOutline className="text-lg md:text-xl" />}
            />
            {formCaption.email && (
              <p className="text-red-500 w-full text-sm pl-8">
                {formCaption.email}
              </p>
            )}
            <Input
              id="password"
              type={visiblePassword ? 'text' : 'password'}
              placeholder="비밀번호"
              value={form.password}
              onChange={handleForm}
              leftIcon={<IoLockClosedOutline className="text-lg md:text-xl" />}
              rightIcon={
                visiblePassword ? (
                  <AiOutlineEye
                    className="cursor-pointer text-2xl"
                    onClick={() => setVisiblePassword(prev => !prev)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="cursor-pointer text-xl md:text-2xl"
                    onClick={() => setVisiblePassword(prev => !prev)}
                  />
                )
              }
            />
            {formCaption.password && (
              <p className="text-red-500 w-full text-sm pl-8">
                {formCaption.password}
              </p>
            )}
            <button
              type="submit"
              onClick={handleSubmit}
              className="w-28 text-sm md:text-base md:w-32 border border-transparent mt-4 py-2 bg-primary text-white rounded-full transition-all ease-in-out delay-50 hover:bg-white hover:border-primary hover:text-primary">
              로그인
            </button>
          </form>

          {/* 로그인 관련 페이지 이동 버튼 */}
          <div className="w-full flex justify-evenly">
            <Link
              to="/user/find/email"
              className="text-sm font-semibold text-gray-500 hover:underline px-2">
              이메일 찾기
            </Link>
            <Link
              to="/user/find/password"
              className="text-sm font-semibold text-gray-500 hover:underline px-2">
              비밀번호 찾기
            </Link>
            <Link
              to="/sign-up"
              className="text-sm text-primary hover:underline">
              회원가입
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
