import {Api} from '@/App';
import {ChangeUserInfo} from '@/types/user.type';

export function reqPostSignIn(siteId: string, email: string, password: string) {
  return Api.post('/users/sign-in', {
    siteId,
    email,
    password,
  });
}

export function reqGetUserInfo(siteId: string, userId: string) {
  return Api.get(`/users/${userId}`, {
    params: {
      code: 'S01',
      siteId,
    },
  });
}

export function reqValidateAuth({
  siteId,
  phone,
  telecom,
  birthDate,
  name,
  gender,
}: {
  siteId: string;
  phone: string;
  telecom: string; // KT, SKT
  birthDate: string;
  name: string;
  gender: string;
}) {
  return Api.post(`/users/auth-verification`, {
    siteId,
    phone,
    telecom,
    birthDate,
    name,
    gender,
  });
}

export function reqSendSmsCode(
  siteId: string,
  type: string,
  target: string,
  user?: string,
) {
  return Api.post('/users/auth-request', {
    siteId,
    user: user ?? undefined,
    type: type,
    target,
  });
}

export function reqValidateSmsCode(
  siteId: string,
  type: string,
  target: string,
  code: string,
  user?: string,
) {
  return Api.post('users/auth-confirm', {
    siteId,
    user: user ?? undefined,
    type: type,
    target,
    code,
  });
}

export function reqSendEmailCode(
  siteId: string,
  type: string,
  target: string,
  user?: string,
) {
  return Api.post('/users/auth-request', {
    siteId,
    user: user ?? null,
    type: type,
    target,
  });
}

export function reqValidateEmailCode(
  siteId: string,
  type: string,
  target: string,
  code: string,
  user?: string,
) {
  return Api.post('users/auth-confirm', {
    siteId,
    user: user ?? null,
    type: type,
    target,
    code,
  });
}

interface ReqSignUpParams {
  siteId?: string;
  authId: string;
  name: string;
  email: string;
  phone: string;
  password: string;
  privacyPolicy: string;
  servicePolicy: string;
}

export function reqSignUp(data: ReqSignUpParams) {
  return Api.post('/users/sign-up', data);
}

export function reqFindEmail(siteId: string, phone: string, authId: string) {
  return Api.post('users/find-email', {
    siteId,
    phone,
    authId,
  });
}

export function reqResetPassword(
  siteId: string,
  email: string,
  newPassword: string,
  authId: string,
) {
  return Api.post('/users/new-password', {
    siteId,
    email,
    newPassword,
    authId,
  });
}

interface ReqUpdateUserInfo {
  phone?: string;
  newPassword?: string;
}

export function reqUpdateUserInfo(userId: string, data: ReqUpdateUserInfo) {
  return Api.put(`/users/${userId}`, {
    ...data,
  });
}

export function reqWithdrawUser(userId: string, password: string) {
  return Api.delete(`/users/${userId}`, {
    data: {
      password,
    },
  });
}

export function reqIsDuplicatedEmail(siteId: string, email: string) {
  return Api.post('/users/check-email', {
    siteId,
    email,
  });
}

export function reqChangeUserInfo(userId: string, changeInfo: ChangeUserInfo) {
  const {password, newPassword} = changeInfo;

  return Api.put(`/users/${userId}/password`, {
    password,
    newPassword,
  });
}

export function reqDeleteUser(password: string, userId: string) {
  return Api.delete(`/users/${userId}`, {
    data: {password},
  });
}
