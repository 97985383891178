import {useEffect} from 'react';
import {useNavigate, Link, useLocation} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {
  Layout,
  TableData,
  TableHeader,
  TableRow,
  TableRowEmpty,
  Skeleton,
  Pagination,
  InputSearch,
} from '@/components/common';
import {SearchResult} from '@/components/SearchResult';
import {useBoards} from '@/hooks/useBoards';
import siteState from '@/stores/siteAtom';
import {BoardList} from '@/types/boards.type';
import {replaceDateFormat, genTableIndex} from '@/utils/format';
import {BsPencilFill} from 'react-icons/bs';

export function QuestionCourse() {
  const navigate = useNavigate();
  const siteData = useRecoilValue(siteState);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');

  const {
    data: qnaData,
    isLoading,
    paginationInfo,
    onChangePage,
    refetch,
  } = useBoards({
    category: 'QNA',
    searchQuery,
  });

  function searchQuestionList(query: string) {
    if (!query) {
      navigate('/question/course');
      return;
    }
    navigate(`/question/course?search=${query}`);
  }

  // 페이지 새로고침 시 siteId에 값이 할당되기 전후 처리
  useEffect(() => {
    refetch();
  }, [siteData, searchQuery]);

  return (
    <Layout>
      {/* 문의하기 탭 */}
      <div className="flex justify-center my-8 space-x-6">
        <div className="text-3xl font-semibold">
          <Link to={`/question`} className="text-disabled">
            FAQ
          </Link>
        </div>
        <div className="text-3xl font-semibold">
          <Link to={`/question/course`}>교육 문의</Link>
        </div>
      </div>
      {/* 내용 */}
      <div className="w-full flex flex-col items-center">
        <div className="w-[calc(80%)] max-w-[500px]">
          <InputSearch
            placeholder="검색어를 입력하세요."
            onSearch={searchQuestionList}
          />
        </div>
      </div>
      {searchQuery && <SearchResult text={searchQuery} />}
      <div className="w-full px-4 mt-10">
        <div className="w-full flex items-center justify-between mb-4">
          <select className="border-none focus:ring-0">
            <option value="latest">최신순</option>
          </select>
          <button
            onClick={() => navigate('/question/course/write')}
            className="flex items-center space-x-2 px-2 md:px-3 bg-secondary text-white border rounded-full h-[30px] text-sm md:text-base md:h-[35px]">
            <BsPencilFill />
            <span>글쓰기</span>
          </button>
        </div>
        <table className="w-full">
          <thead className="bg-primary bg-opacity-10">
            <TableRow header>
              <TableHeader hiddenMobile>번호</TableHeader>
              <TableHeader>교육명</TableHeader>
              <TableHeader>제목</TableHeader>
              <TableHeader hiddenTablet>일시</TableHeader>
            </TableRow>
          </thead>
          <tbody className="border-b border-disabled">
            {!isLoading &&
              qnaData.length > 0 &&
              qnaData.map((item: BoardList, index: number) => {
                const rowIndex = genTableIndex(
                  paginationInfo.currentPage,
                  paginationInfo.size,
                  index,
                );
                return (
                  <TableRow
                    key={index}
                    addClass="cursor-pointer"
                    onClick={() =>
                      navigate(`/question/course/${item.id}`, {state: item})
                    }>
                    <TableData hiddenMobile>{rowIndex}</TableData>
                    <TableData addClass={'text-start'}>
                      {item.typeId === 0 ? '기타' : item.name}
                    </TableData>
                    <TableData addClass={'text-start'}>{item.title}</TableData>
                    <TableData hiddenTablet>
                      {replaceDateFormat(item.createdAt, 'YY.MM.DD(ddd) HH:MM')}
                    </TableData>
                  </TableRow>
                );
              })}
            {!isLoading && qnaData.length === 0 && (
              <TableRowEmpty text="검색 결과 없음" />
            )}
            {isLoading && <Skeleton />}
          </tbody>
        </table>
      </div>
      <div className="my-8">
        <Pagination
          totalCount={paginationInfo.total}
          pageSize={paginationInfo.size}
          currentPage={paginationInfo.currentPage}
          totalPage={paginationInfo.totalPages}
          onClick={onChangePage}
        />
      </div>
    </Layout>
  );
}
