import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {IoPlay} from 'react-icons/io5';
import {
  Layout,
  InputSearch,
  TableHeader,
  TableRow,
  TableData,
  PrimarySquareButton,
  PrimarySquareButtonWithIcon,
  TableRowEmpty,
  Skeleton,
  Pagination,
} from '@/components/common';
import {SearchResult} from '@/components/SearchResult';
import {useMyCourse} from '@/hooks/useMyCourse';
import {RegisterCourse} from '@/types/course.type';
import {genTableIndex, genPerDate} from '@/utils/format';
import Modal from '@/components/common/Modal';
import useModal from '@/hooks/useModal';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';

export function Course() {
  const siteData = useRecoilValue(siteState);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const host = window.location.host.split('.')[0];

  const {
    myCourseList,
    isLoading,
    paginationInfo,
    onChangePage,
    refetchMyCourses,
  } = useMyCourse(searchQuery);

  function searchCourseList(query: string) {
    if (!query) {
      navigate('/course');
      return;
    }
    navigate(`/course?search=${query}`);
  }

  useEffect(() => {
    if (siteData && myCourseList && myCourseList.length >= 2) {
      let firstCategoryCompleted = false;
      let secondCategoryCompleted = false;
      myCourseList.map((myCourse: RegisterCourse) => {
        if (myCourse.completeDate.length > 0) {
          if (myCourse.category === '가 영역') {
            firstCategoryCompleted = true;
          } else if (myCourse.category === '나 영역') {
            secondCategoryCompleted = true;
          }
        }
      });
      if (!(firstCategoryCompleted && secondCategoryCompleted)) {
        return;
      }
      if (siteData?.variation?.surveyUrl === '') {
        return;
      }
      openModal({
        type: 'alert',
        title: '설문조사 진행 안내',
        message: `교육과정 수강이 완료되었습니다. 설문조사를 진행해주시길 바랍니다.</br>
                  <a style="text-decoration: underline; color:#1a0dab" target="_blank" href=${siteData?.variation.surveyUrl}>[바로가기]</a></br> `,
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              closeModal();
            },
          },
        ],
      });
    }
  }, [siteData, myCourseList]);

  useEffect(() => {
    refetchMyCourses();
  }, [searchQuery, siteData]);

  return (
    <Layout>
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
      <div className="w-full py-10 flex flex-col items-center background-image bg-no-repeat bg-cover bg-[url('@/assets/img/register-search.png')]">
        <div className="w-[calc(80%)] max-w-[500px]">
          <InputSearch
            placeholder="검색어를 입력하세요."
            onSearch={searchCourseList}
          />
        </div>
      </div>
      {searchQuery && <SearchResult text={searchQuery} />}
      <div className="w-full px-4 mt-10">
        <table className="w-full">
          <thead className="bg-primary bg-opacity-10">
            <TableRow header>
              <TableHeader hiddenMobile>번호</TableHeader>
              <TableHeader>교육 형태</TableHeader>
              {(host === 'kacw' || host === 'r-dev') && (
                <TableHeader>카테고리</TableHeader>
              )}
              <TableHeader>교육명</TableHeader>
              <TableHeader>강의상세</TableHeader>
              <TableHeader hiddenTablet>교육기간</TableHeader>
              <TableHeader>수강률</TableHeader>
              <TableHeader>강의</TableHeader>
              <TableHeader hiddenMobile>질문</TableHeader>
            </TableRow>
          </thead>
          <tbody className="border-b border-disabled">
            {!isLoading ? (
              myCourseList.length !== 0 ? (
                myCourseList.map((item: RegisterCourse, index: number) => {
                  const rowIndex = genTableIndex(
                    paginationInfo.currentPage,
                    paginationInfo.size,
                    index,
                  );
                  return (
                    <TableRow key={index}>
                      <TableData hiddenMobile>{rowIndex}</TableData>
                      <TableData>
                        {item.courseType === 'ONLINE'
                          ? '원격'
                          : item.courseType === 'OFFLINE'
                          ? '집합'
                          : '-'}
                      </TableData>
                      {(host === 'kacw' || host === 'r-dev') && (
                        <TableData>{item.category}</TableData>
                      )}
                      <TableData addClass={'text-start'}>{item.name}</TableData>
                      <TableData>
                        <PrimarySquareButton
                          onClick={() => {
                            navigate(`/course/${item.id}`);
                          }}
                          text="상세보기"
                        />
                      </TableData>
                      <TableData hiddenTablet>
                        <p>{genPerDate(item.startsAt)}</p>
                        <p>~</p>
                        <p>{genPerDate(item.endsAt)}</p>
                      </TableData>
                      <TableData>{item.progressRate}%</TableData>
                      <TableData>
                        <PrimarySquareButtonWithIcon
                          onClick={() => {
                            navigate(`/lecture/${item.id}`);
                          }}
                          icon={<IoPlay style={{verticalAlign: 'middle'}} />}
                        />
                      </TableData>
                      <TableData hiddenMobile>
                        <PrimarySquareButton
                          onClick={() => {
                            navigate(`/question/course/write?id=${item.id}`);
                          }}
                          text="질문하기"
                        />
                      </TableData>
                    </TableRow>
                  );
                })
              ) : (
                <TableRowEmpty text="검색 결과 없음" />
              )
            ) : (
              <tr className="w-full">
                <td colSpan={99} className="text-center h-40">
                  <Skeleton />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!isLoading && paginationInfo && (
        <div className="my-8">
          <Pagination
            totalCount={paginationInfo?.total}
            pageSize={paginationInfo?.size}
            currentPage={paginationInfo?.currentPage}
            totalPage={paginationInfo?.totalPages}
            onClick={nextPage => {
              onChangePage(nextPage);
            }}
          />
        </div>
      )}
    </Layout>
  );
}
