import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  Layout,
  InputSearch,
  TableData,
  TableHeader,
  TableRow,
  TableRowEmpty,
  Skeleton,
  SecondarySquareButton,
  Pagination,
  PrimarySquareButton,
} from '@/components/common';
import {SearchResult} from '@/components/SearchResult';
import {useRegisterCourse} from '@/hooks/useRegisterCourse';
import {genDurationDate, genTableIndex, getToday} from '@/utils/format';
import {RegisterCourse} from '@/types/course.type';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';

export function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');
  const siteData = useRecoilValue(siteState);
  const [courseApplicationInfo, setCourseApplicationInfo] = useState('');
  const host = window.location.host.split('.')[0];

  const {registerCourses, isLoading, paginationInfo, onChangePage, refetch} =
    useRegisterCourse(searchQuery);

  function searchCourseList(query: string) {
    if (!query) {
      navigate('/register');
      return;
    }
    navigate(`/register?search=${query}`);
  }

  useEffect(() => {
    refetch();
  }, [searchQuery, registerCourses]);

  useEffect(() => {
    if (!siteData) return;
    if (!siteData.variation?.courseApplicationInfo) return;

    let courseApplicationInfoData =
      siteData.variation.courseApplicationInfo.split('<body')[1];
    const startIndex = courseApplicationInfoData.indexOf('>') + 1;
    const endIndex = courseApplicationInfoData.indexOf('</body');
    courseApplicationInfoData = courseApplicationInfoData.substring(
      startIndex,
      endIndex,
    );
    setCourseApplicationInfo(courseApplicationInfoData);
  }, [siteData]);

  return (
    <Layout>
      <div className="w-full py-10 flex flex-col items-center background-image bg-no-repeat bg-cover bg-[url('@/assets/img/register-search.png')]">
        <div className="w-[calc(80%)] max-w-[500px]">
          <InputSearch
            placeholder="검색어를 입력하세요."
            onSearch={searchCourseList}
          />
        </div>
      </div>
      {searchQuery && <SearchResult text={searchQuery} />}

      {courseApplicationInfo && (
        <div
          className={
            'mx-4 mt-5 px-10 py-6 bg-[#efefef] text-xs border border-transparent rounded-2xl'
          }
          dangerouslySetInnerHTML={{__html: courseApplicationInfo}}></div>
      )}
      <div className="w-full px-4 mt-10">
        <table className="w-full">
          <thead className="bg-primary bg-opacity-10">
            <TableRow header>
              <TableHeader hiddenMobile>번호</TableHeader>
              <TableHeader>교육 형태</TableHeader>
              {(host === 'kacw' || host === 'r-dev') && (
                <TableHeader>카테고리</TableHeader>
              )}
              <TableHeader>교육명</TableHeader>
              <TableHeader hiddenTablet>모집기간</TableHeader>
              <TableHeader>교육기간</TableHeader>
              <TableHeader hiddenTablet>모집인원</TableHeader>
              <TableHeader>모집상태</TableHeader>
              <TableHeader>교육신청</TableHeader>
            </TableRow>
          </thead>
          <tbody className="border-b border-disabled">
            {!isLoading &&
              registerCourses.length > 0 &&
              registerCourses.map((item: RegisterCourse, index: number) => {
                const isRecruitEnd = new Date() > new Date(item.recruitEndsAt);
                return (
                  <TableRow key={index}>
                    <TableData hiddenMobile>
                      {genTableIndex(
                        paginationInfo.currentPage,
                        paginationInfo.size,
                        index,
                      )}
                    </TableData>
                    <TableData>
                      {item.courseType === 'ONLINE'
                        ? '원격'
                        : item.courseType === 'OFFLINE'
                        ? '집합'
                        : ''}
                    </TableData>
                    {(host === 'kacw' || host === 'r-dev') && (
                      <TableData>{item.category}</TableData>
                    )}
                    <TableData addClass={'text-start'}>{item.name}</TableData>
                    <TableData hiddenTablet>
                      {genDurationDate(
                        item.recruitStartsAt,
                        item.recruitEndsAt,
                      )}
                    </TableData>
                    <TableData>
                      {genDurationDate(item.startsAt, item.endsAt)}
                    </TableData>
                    <TableData hiddenTablet>{item.applicantsCount}명</TableData>
                    <TableData>
                      {item.recruitStatus === 'Y' &&
                      item.applicantsCount > 0 &&
                      getToday() >= item.recruitStartsAt ? (
                        <span className="text-primary">모집 중</span>
                      ) : item.applicantsCount > 0 &&
                        getToday() < item.recruitStartsAt ? (
                        <span>모집 예정</span>
                      ) : (
                        <span>모집 완료</span>
                      )}
                    </TableData>
                    <TableData>
                      {/* 신청됨 -> 신청 완료 */}
                      {item.applicationStatus === 'ENROLLED' && (
                        <span
                          className="text-primary cursor-pointer underline"
                          onClick={() => {
                            navigate(`/register/${item.id}`);
                          }}>
                          신청 완료
                        </span>
                      )}
                      {/* 신청안했는데 신청못함 */}
                      {item.applicationStatus === 'NULL' &&
                        (isRecruitEnd || item.applicantsCount === 0) && (
                          <SecondarySquareButton
                            onClick={() => {
                              navigate(`/register/${item.id}`);
                            }}
                            text="신청불가"
                          />
                        )}
                      {/* 신청안했는데 신청가능 */}
                      {item.applicationStatus === 'NULL' &&
                        !isRecruitEnd &&
                        item.applicantsCount > 0 && (
                          <SecondarySquareButton
                            onClick={() => {
                              navigate(`/register/${item.id}`);
                            }}
                            text="신청하기"
                          />
                        )}
                      {/* 승인대기중 */}
                      {item.applicationStatus === 'WAITING' && (
                        <PrimarySquareButton
                          onClick={() => {
                            navigate(`/register/${item.id}`);
                          }}
                          text="승인 대기 중"
                        />
                      )}
                      {/* 탈락~! */}
                      {item.applicationStatus === 'FAILURE' && (
                        <SecondarySquareButton
                          onClick={() => {
                            navigate(`/register/${item.id}`);
                          }}
                          text="탈락"
                        />
                      )}
                    </TableData>
                  </TableRow>
                );
              })}
            {!isLoading && registerCourses.length === 0 && (
              <TableRowEmpty text="검색 결과 없음" />
            )}
            {isLoading && <Skeleton />}
          </tbody>
        </table>
      </div>
      <div className="my-8">
        <Pagination
          totalCount={paginationInfo.total}
          pageSize={paginationInfo.size}
          currentPage={paginationInfo.currentPage}
          totalPage={paginationInfo.totalPages}
          onClick={nextPage => {
            onChangePage(nextPage);
          }}
        />
      </div>
    </Layout>
  );
}
