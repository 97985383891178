import {useState, ChangeEvent} from 'react';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';
import {Link, useNavigate} from 'react-router-dom';
import {Layout, Input} from '@/components/common';
import Modal from '@/components/common/Modal';
import useModal from '@/hooks/useModal';
import {IoMailOutline, IoKeypad, IoLockClosedOutline} from 'react-icons/io5';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import {ValidateEmailSchema, ValidatePasswordSchema} from '@/utils/validator';
import {
  requestEmailCodeService,
  validateEmailCodeService,
  resetPasswordService,
} from '@/services/userService';

const INIT_FIND_PASSWORD_STATE = {
  email: '',
  code: '',
  password: '',
  rePassword: '',
};

export function UserFindPassword() {
  const navigate = useNavigate();
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const siteData = useRecoilValue(siteState);
  const [form, setForm] = useState(INIT_FIND_PASSWORD_STATE);
  const [formCaption, setFormCaption] = useState(INIT_FIND_PASSWORD_STATE);
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isSentCode, setIsSentCode] = useState(false);
  const [verifiedId, setVerifiedId] = useState('');
  const [visiblePassword, setVisiblePassword] = useState(false);

  if (!siteData) return null;

  const {logo} = siteData;

  function handleForm(e: ChangeEvent<HTMLInputElement>) {
    const {value, id} = e.target;
    const newForm = {...form, [id]: value};
    setForm(newForm);

    try {
      switch (id) {
        case 'email':
          ValidateEmailSchema.validateSync(value);
          break;
        case 'password':
          ValidatePasswordSchema.validateSync(value);
          break;
      }
      setFormCaption({
        ...formCaption,
        [id]: '',
      });
    } catch (e: any) {
      setFormCaption({
        ...formCaption,
        [id]: e.message,
      });
    }
  }

  async function requestEmailCode() {
    const email = form.email;
    try {
      ValidateEmailSchema.validateSync(email);
      setFormCaption(prev => {
        return {
          ...prev,
          email: '',
        };
      });
    } catch (e: any) {
      setFormCaption(prev => {
        return {
          ...prev,
          email: e.message,
        };
      });
      return;
    }

    try {
      await requestEmailCodeService(siteData!.id, 'RESET_PWD', email);
      setIsSentCode(true);
      openModal({
        type: 'check',
        message: '인증 메일이 발송되었습니다.',
      });
    } catch (e: any) {
      setFormCaption(prev => {
        return {
          ...prev,
          email: e.data.error.message,
        };
      });
    }
  }

  async function validateEmailCode() {
    const {email, code} = form;
    if (code.length === 0) {
      setFormCaption(prev => {
        return {
          ...prev,
          code: '이메일 인증 코드를 입력해주세요.',
        };
      });
      return;
    }

    try {
      const authId = await validateEmailCodeService(
        siteData!.id,
        'RESET_PWD',
        email,
        code,
      );

      setVerifiedId(authId);
      setIsEmailChecked(true);
      openModal({
        type: 'check',
        message: '인증되었습니다.\n새로운 비밀번호를 입력해주세요.',
      });
    } catch (e: any) {
      setFormCaption(prev => {
        return {
          ...prev,
          code: e.data.error.message,
        };
      });
    }
  }

  async function handleFindEmail() {
    const {email, password, rePassword} = form;

    if (password !== rePassword) {
      setFormCaption(prev => {
        return {
          ...prev,
          rePassword: '비밀번호가 일치하지 않습니다.',
        };
      });
      return;
    }

    try {
      await resetPasswordService(siteData!.id, email, password, verifiedId);
      openModal({
        type: 'check',
        message:
          '비밀번호가 변경되었습니다.\n새로운 비밀번호로 로그인해주세요.',
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              navigate('/sign-in');
            },
          },
        ],
      });
    } catch (e: any) {
      setFormCaption(prev => {
        return {
          ...prev,
          password: e.data.error.message,
        };
      });
    }
  }

  return (
    <Layout isHiddenFooter isSignHeader>
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
      <div className="w-full h-full mb-0 flex flex-col items-center py-[30%] md:py-[20%] lg:py-[20%]">
        <div className="w-full max-w-[500px] flex flex-col items-center">
          {/* 기관 로고 */}
          <img
            src={logo}
            alt="기관 로고"
            className="w-[200px] md:w-[215px] lg:w-[230px]"
          />
          <h1 className="text-lg md:text-2xl font-semibold text-secondary mt-8">
            비밀번호 찾기
          </h1>
          <form className="w-full flex flex-col items-center px-8 mb-10 mt-4">
            <Input
              id="email"
              type="email"
              placeholder="이메일"
              value={form.email}
              onChange={handleForm}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  event.preventDefault();
                  isSentCode && isEmailChecked ? undefined : requestEmailCode();
                }
              }}
              addClass={
                isEmailChecked ? 'bg-readOnly cursor-default' : 'bg-white'
              }
              leftIcon={<IoMailOutline className="text-lg md:text-xl" />}
              rightIcon={
                <span
                  onClick={
                    isSentCode && isEmailChecked ? undefined : requestEmailCode
                  }
                  className={`w-auto whitespace-nowrap px-3 py-1 cursor-pointer border border-secondary bg-transparent text-center text-secondary font-semibold text-sm w-[50px] transition-all duration-150 rounded-full ${
                    isEmailChecked
                      ? 'button-success cursor-default'
                      : 'hover:bg-secondary hover:text-white'
                  }`}>
                  {(() => {
                    if (!isSentCode) {
                      return '인증 요청';
                    }
                    if (isEmailChecked) {
                      return '인증 완료';
                    }
                    return '재전송';
                  })()}
                </span>
              }
            />
            {formCaption.email && (
              <p className="text-red-500 w-full text-sm pl-8">
                {formCaption.email}
              </p>
            )}
            {!isEmailChecked && isSentCode && (
              <Input
                id="code"
                type="text"
                placeholder="인증번호"
                value={form.code}
                onChange={handleForm}
                onKeyDown={event => {
                  if (event.keyCode === 13) validateEmailCode();
                }}
                addClass={`${isEmailChecked && 'bg-readOnly'}`}
                leftIcon={<IoKeypad className="text-lg md:text-xl" />}
                rightIcon={
                  <span
                    onClick={
                      isSentCode && isEmailChecked
                        ? undefined
                        : validateEmailCode
                    }
                    className={`w-auto whitespace-nowrap px-3 py-1 cursor-pointer border border-secondary bg-transparent text-center text-secondary font-semibold text-sm w-[50px] transition-all duration-150 rounded-full ${
                      isEmailChecked
                        ? 'button-success cursor-default'
                        : 'hover:bg-secondary hover:text-white'
                    }`}>
                    확인
                  </span>
                }
              />
            )}
            {formCaption.email && (
              <p className="text-red-500 w-full text-sm pl-8">
                {formCaption.code}
              </p>
            )}
            {/* 비밀번호 */}
            {verifiedId && (
              <>
                <Input
                  id="password"
                  type={visiblePassword ? 'text' : 'password'}
                  placeholder="새 비밀번호"
                  value={form.password}
                  onChange={handleForm}
                  leftIcon={
                    <IoLockClosedOutline className="text-lg md:text-xl" />
                  }
                  rightIcon={
                    visiblePassword ? (
                      <AiOutlineEye
                        className="cursor-pointer text-2xl"
                        onClick={() => setVisiblePassword(prev => !prev)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="cursor-pointer text-xl md:text-2xl"
                        onClick={() => setVisiblePassword(prev => !prev)}
                      />
                    )
                  }
                />
                {formCaption.password && (
                  <p className="text-red-500 w-full text-sm pl-8">
                    {formCaption.password}
                  </p>
                )}
                {/* 비밀번호 확인 */}
                <Input
                  id="rePassword"
                  type={visiblePassword ? 'text' : 'password'}
                  placeholder="새 비밀번호 확인"
                  value={form.rePassword}
                  onChange={handleForm}
                  leftIcon={
                    <IoLockClosedOutline className="text-lg md:text-xl" />
                  }
                />
                {formCaption.rePassword && (
                  <p className="text-red-500 w-full text-sm pl-8">
                    {formCaption.rePassword}
                  </p>
                )}
                <span
                  onClick={handleFindEmail}
                  className="w-28 text-center text-sm md:text-base md:w-32 border border-transparent mt-4 py-2 bg-primary text-white rounded-full transition-all ease-in-out delay-50 hover:bg-white hover:border-primary hover:text-primary cursor-pointer">
                  확인
                </span>
              </>
            )}
          </form>
          <div className="w-full flex justify-center space-x-4 mt-4">
            <Link
              to="/sign-in"
              className="text-sm font-semibold text-gray-500 hover:underline px-2">
              로그인 하기
            </Link>
            <Link
              to="/user/find/email"
              className="text-sm font-semibold text-gray-500 hover:underline px-2">
              이메일 찾기
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
