import {Api} from '@/App';

export function reqGetSite(host: string) {
  return Api.get(`/sites/domains/${host}`);
}

export function reqGetSitePreferences(siteId: string) {
  return Api.get(`/sites/${siteId}/preferences`);
}

export async function reqAcademies(siteId?: string) {
  try {
    const response = await Api.get('/academies', {
      params: {siteId, status: 'ACTIVE'},
    });

    return response.data.data.content;
  } catch (e) {
    return [];
  }
}

export async function reqGenPayUrl(userId: string, siteId?: string) {
  try {
    const response = await Api.post('/pay-urls', {
      siteId,
      userId,
    });
    return response.data;
  } catch (e) {
    // console.log(e);
  }
}

export async function reqGenKacwPayUrl(userId: string, siteId?: string) {
  try {
    const response = await Api.post('/kacw/pay-urls', {
      siteId,
      userId,
    });
    return response.data;
  } catch (e: any) {
    throw new Error(e.data.error.message ?? '결제를 실패했습니다.');
  }
}

export interface ReqPayResultsParams {
  msg1?: string;
  msg2?: string;
  price?: string;
  product?: string;
  result?: string;
  txid: string;
  userId?: string;
  username?: string;
}
export async function reqPayResults(params: ReqPayResultsParams) {
  try {
    const response = await Api.post(`pay-results`, params);
    return response.data;
  } catch (e) {
    // console.log(e);
    return e;
  }
}

export async function reqKacwPayResults(params: ReqPayResultsParams) {
  try {
    const response = await Api.post(`/kacw/pay-results`, params);
    return response.data;
  } catch (e) {
    // console.log(e);
    return e;
  }
}
