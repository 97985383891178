import {Layout} from '@/components/common';
import {useEffect, useState} from 'react';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {BoardList, Comment} from '@/types/boards.type';
import {
  getBoardDetailService,
  getCommentsService,
} from '@/services/boardService';
import {parseHtmlContentWithSanitize} from '@/utils/parse';
import {replaceDateFormat} from '@/utils/format';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';

export function QuestionCourseDetail() {
  const navigate = useNavigate();
  const {state: locationState} = useLocation();
  const [serviceData, setServiceData] = useState<BoardList>();
  const [comments, setComments] = useState<Comment[]>();

  const {id: serviceId} = useParams<{id: string}>();
  const siteData = useRecoilValue(siteState);
  const siteId = siteData?.id;

  useEffect(() => {
    if (!serviceId) {
      navigate('/notice');
      return;
    }

    if (!locationState) {
      fetchNoticeData(serviceId);
      return;
    }

    setServiceData(locationState);
  }, []);

  useEffect(() => {
    if (!serviceData) return;
    fetchBoardComments();
  }, [serviceData, siteId]);

  async function fetchNoticeData(noticeId: string) {
    try {
      const res = await getBoardDetailService(noticeId);
      setServiceData(res);
    } catch (e: any) {
      console.log(e);
    }
  }

  async function fetchBoardComments() {
    const boardId = serviceData!.id;
    try {
      const res = await getCommentsService(boardId, siteId ? siteId : '');
      setComments(res.content);
    } catch (e: any) {
      console.log(e);
    }
  }

  return (
    <Layout>
      {/* 헤더 */}
      <div className="flex justify-center my-8">
        <h1 className="text-3xl font-semibold">교육 문의</h1>
      </div>
      {/* 해당 강의명 */}
      {serviceData?.name && (
        <div className="flex justify-center ">
          <h2 className="text-2xl font-semibold border border-primary px-[20%] py-1 md:py-2 rounded-xl">
            {serviceData?.name}
          </h2>
        </div>
      )}
      {/* 게시물 영역 */}
      {serviceData && (
        <div>
          <div className="w-full px-4 my-4 pb-2 border-b border-gray-400">
            <h1 className="text-2xl font-bold">{serviceData.title}</h1>
          </div>
          <div className="w-full flex mb-16">
            <span className="text-sm text-gray-400 pl-[16px]">
              작성자:&nbsp;{serviceData.author.name}
            </span>
            <span className="text-sm text-gray-400 ml-auto">
              {replaceDateFormat(serviceData.createdAt, 'YY.MM.DD(ddd) HH:MM')}
            </span>
          </div>
          <div className="w-full px-4 mb-8">
            {parseHtmlContentWithSanitize(serviceData.content)}
          </div>
        </div>
      )}
      {/* 코멘트 영역 */}
      {comments && (
        <div className="mt-4">
          {comments.map((item: Comment, index: number) => (
            <div className="my-8" key={index}>
              <div className="w-full flex justify-between">
                <h3 className="text-2xl font-bold text-gray-400">답변</h3>
                <div className={'flex'}>
                  <span className="text-sm text-gray-400 mr-2">
                    {item.author.name}
                  </span>
                  <span className="text-sm text-gray-400 mr-2">|</span>
                  <span className="text-sm text-gray-400">
                    {replaceDateFormat(item.createdAt, 'YY.MM.DD(ddd) HH:MM')}
                  </span>
                </div>
              </div>
              <div className="w-full px-4 mb-8">
                {parseHtmlContentWithSanitize(item.content)}
              </div>
            </div>
          ))}
        </div>
      )}
    </Layout>
  );
}
