import {Dispatch, SetStateAction} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';
import Modal from '@/components/common/Modal';
import useModal from '@/hooks/useModal';
import useUser from '@/hooks/useUser';
import {useSitePreference} from '@/hooks/useSitePreference';

export function Nav({
  isMobile,
  setIsMobileMenuOpen,
}: {
  isMobile: boolean;
  setIsMobileMenuOpen?: Dispatch<SetStateAction<boolean>>;
}) {
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const user = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const siteInfo = useRecoilValue(siteState);
  const preferences = useSitePreference({siteId: siteInfo?.id ?? ''});

  const navWrapperClass = isMobile
    ? ' top-16 border-b-2 border-t-2 border-gray-200'
    : 'hidden md:flex md:items-center ml-0';
  const navItemClass = isMobile
    ? 'w-full text-sm hover:text-primary duration-100 text-center py-3 hover:bg-gray-100 cursor-pointer bg-white'
    : 'text-sm hover:text-primary duration-100 lg:mx-3 nav-item';

  const navItems = [
    {name: '공지사항', path: '/notice'},
    {name: 'FAQ / 교육 문의', path: '/question'},
    {name: '교육신청', path: '/register'},
    {name: '교육수강', path: '/course'},
    {name: '수료증', path: '/certificate'},
    {name: '고객 문의', path: '/ask'},
  ];
  const handleCloseMenu = () => {
    if (setIsMobileMenuOpen) setIsMobileMenuOpen(false);
  };

  function onClickPayment() {
    if (!user.user || user.user?.id === '') {
      openModal({
        title: '수강권 결제',
        message: '로그인이 필요한 서비스입니다.',
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              closeModal();
            },
          },
        ],
      });
    } else {
      openModal({
        title: '수강권 결제',
        message: '수강권 결제는 집합교육기관에서 납부하고\n' +
            '수강중이시므로 취소버튼을 클릭하시면 됩니다.',
      });
    }
  }

  return (
    <>
      <Modal
        isShowing={isShowing}
        type="alert"
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {
              text: '취소',
              style: 'normal',
              onClick: () => {
                closeModal();
              },
            },
            {
              text: '결제하기',
              style: 'normal',
              onClick: () => {
                closeModal();
                navigate('/pay-results');
              },
            },
          ]
        }
      />
      <nav
        className={`z-40 ${
          isMobile
            ? 'md:hidden w-full absolute h-[calc(100vh-60px)] bg-black bg-opacity-20 mx-0'
            : 'mx-4'
        }`}>
        <ul className={`${navWrapperClass} mx-0`}>
          {navItems.map(item => (
            <Link to={item.path} key={item.name} onClick={handleCloseMenu}>
              <li
                className={`${navItemClass} ${
                  location.pathname.substr(0, item.path.length) === item.path &&
                  'nav-selected'
                } lg:text-sm md:text-xs md:mx-3`}>
                {item.name}
              </li>
            </Link>
          ))}
          {preferences.sitePreference?.payments === true && (
            <li className={`${navItemClass} lg:text-sm md:text-xs md:mx-3`}>
              <button onClick={onClickPayment}>수강권 결제</button>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
}
