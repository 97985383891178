import {
  reqApplyCourse,
  reqApplyKacwCourse,
  reqGetChapterList,
  reqGetCourseDetail,
  reqGetCourseList,
  reqGetCourseProgress,
  reqGetLectureList,
  reqGetXProgress,
} from '@/api/courseApi';
import {
  Chapter,
  Lecture,
  ReqGetCourseProgressParams,
  ReqGetXProgressParams,
  SubmitHomeWork,
  XProgress,
} from '@/types/course.type';
import {Attachment} from '@/types/common.type';

export async function gethMyCourseListService(
  userId: string,
  siteId: string,
  academyId: string | null,
  searchQuery?: string,
) {
  const data = {
    userId,
    siteId,
    academyId,
    status: 'ACTIVE',
    page: 1,
    pageSize: 100,
    filter: '@my',
    ...(searchQuery ? {name: searchQuery} : {}),
  };
  const courseResponse = await reqGetCourseList(data);

  return courseResponse.data.data;
}

export async function getXProgressService({
  siteId,
  xType,
  courseId,
}: ReqGetXProgressParams) {
  try {
    const response = await reqGetXProgress({
      siteId,
      xType,
      courseId,
    });
    return response.data.data;
  } catch (e: any) {
    // console.log(e);
  }
}

export async function searchCourseDetailService(
  courseId: string,
  userId?: string,
) {
  const result = await reqGetCourseDetail(courseId, userId);
  return result.data.data;
}

export async function applyCourseService(
  courseId: string,
  attachments?: Attachment[],
) {
  const response = await reqApplyCourse(courseId, attachments);
  return response.data.data.result;
}

export async function applyKacwCourseService(
  courseId: string,
  attachments?: Attachment[],
) {
  const response = await reqApplyKacwCourse(courseId, attachments);
  return response.data.data;
}
export async function searchXProgressService({
  xType,
  courseId,
  siteId,
}: ReqGetXProgressParams): Promise<Array<XProgress>> {
  const response = await reqGetXProgress({xType, courseId, siteId});

  return response.data.data;
}

export async function getChapterListService(
  siteId: string,
  courseId: string,
  userId: string,
) {
  const lectureResponse = await reqGetLectureList(courseId);

  if (lectureResponse.data.data.content.length === 0) {
    return [];
  }

  const lectureList = lectureResponse.data.data.content;

  const chapterListGroupByLecture: Array<Array<Chapter>> = await Promise.all(
    lectureList.map(async (lecture: Lecture) => {
      try {
        const response = await reqGetChapterList(lecture.id);
        if (response.data.data.content.length === 0) {
          return [];
        }
        return response.data.data.content;
      } catch (e) {
        return [];
      }
    }),
  );

  const chapterList: Array<Chapter> = chapterListGroupByLecture.flat();

  if (chapterList.length === 0) {
    return [];
  }

  const progressResponse = await reqGetXProgress({
    xType: 'CHAPTER',
    courseId,
    siteId,
  });
  const progresses = (progressResponse.data.data as Array<XProgress>) ?? [];
  const submitHomeWorks: Array<SubmitHomeWork> = [];

  // const submitHomeWorks =
  //   (submitHomeWorkResponse.data.data?.content as Array<SubmitHomeWork>) ?? [];

  const chaptersWithMyData = chapterList.map(chapter => {
    return {
      ...chapter,
      progress:
        progresses.find(item => item.chapterId === chapter.id) ?? undefined,
      submitHomeWorks: submitHomeWorks.filter(
        item => item.type.id === chapter.id,
      ),
    };
  });

  return chaptersWithMyData;
}

export async function getCourseProgress({
  userId,
  siteId,
  type,
  typeId,
}: ReqGetCourseProgressParams) {
  const data = {siteId, userId, type, typeId};
  const response = await reqGetCourseProgress(data);
  return response.data.data;
}
