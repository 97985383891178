export function SearchResult({text}: {text: string}) {
  return (
    <div className="w-full px-4 mt-10">
      <h2 className="text-xl">
        <span>{`"`}</span>
        <span className="mx-2 text-primary">{text}</span>
        <span>{`" 검색결과`}</span>
      </h2>
    </div>
  );
}
