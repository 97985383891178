import {requestGet} from '@/api';
import {reqUploadHomework} from '@/api/courseApi';
import {Attachment} from '../types/common.type';

interface HomeworkParams {
  attachments: [];
  chapterId: string;
  content: string;
  createdAt: string;
  deletedAt: string;
  id: string;
  seq: number;
  title: string;
  updatedAt: string;
}

export interface HomeworksResponse {
  homework: HomeworkParams;
}

export async function searchHomeworksService(chapterId: string) {
  try {
    const response = await requestGet<HomeworkParams[]>(
      `/chapters/${chapterId}/homeworks`,
    );
    return response.data.data;
  } catch (e: any) {
    throw e.error.message;
  }
}

export interface SubmitHomeworkParams {
  title: string;
  content: string;
  attachments?: Attachment[];
}
export async function submitHomeworkService(
  homeworkId: string,
  homework: SubmitHomeworkParams,
) {
  const response = await reqUploadHomework(homeworkId, homework);
  return response.data.data;
}
