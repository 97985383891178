import {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  Layout,
  InputSearch,
  TableHeader,
  TableRow,
  TableData,
  SquareButton,
  TableRowEmpty,
  Skeleton,
  Pagination,
} from '@/components/common';
import {SearchResult} from '@/components/SearchResult';
import {useMyCourse} from '@/hooks/useMyCourse';
import {genTableIndex, genPerDate} from '@/utils/format';
import KacwCertificateTemplate from '@/assets/img/kacw-certificate-template.png';
import DefaultCertificateTemplate from '@/assets/img/new-certificate-template.png';
import moment from 'moment/moment';
import {printCertificateService} from '@/services/certificateService';
import {useReactToPrint} from 'react-to-print';

interface myCourseListProp {
  courseId: string;
  progressRate: number;
  attendRate: number;
  homeworkRate: number;
  name: string;
  startsAt: string;
  endsAt: string;
}
interface Certificate {
  code: string;
  course: string;
  startsAt: string;
  endsAt: string;
  name: string;
  email: string;
  phone: string;
  completeDate: string;
}
export function Certificate() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');
  const {
    myCourseList,
    isLoading,
    paginationInfo,
    onChangePage,
    refetchMyCourses,
  } = useMyCourse(searchQuery);
  // const {courseId} = useParams<{courseId: string}>();
  const [certificate, setCertificate] = useState<Certificate | null>(null);
  const kacwComponentRef = useRef<HTMLDivElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const host = window.location.host.split('.')[0];

  const fetchCertificate = async (courseId: string) => {
    try {
      // 수료증 출력 api
      const res = await printCertificateService(courseId);
      setCertificate(res.data.data);
    } catch (e: any) {
      alert(e.data.error.message);
      self.close();
    }
  };
  const handlePrintKacwCertificate = useReactToPrint({
    content: () => kacwComponentRef.current,
    documentTitle: `${certificate?.course}_수료증`,
  });
  const handlePrintCertificate = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${certificate?.course}_수료증`,
  });
  useEffect(() => {
    if (certificate?.completeDate && certificate.completeDate.length > 0) {
      host === 'kacw' || host === 'r-dev'
        ? handlePrintKacwCertificate()
        : handlePrintCertificate();
    }
  }, [certificate]);

  function searchCourseList(query: string) {
    if (!query) {
      navigate('/certificate');
      return;
    }
    navigate(`/certificate?search=${query}`);
  }

  useEffect(() => {
    refetchMyCourses();
  }, [searchQuery]);

  return (
    <Layout>
      <div className="w-full py-10 flex flex-col items-center background-image bg-no-repeat bg-cover bg-[url('@/assets/img/register-search.png')]">
        <div className="w-[calc(80%)] max-w-[500px]">
          <InputSearch
            placeholder="검색어를 입력하세요."
            onSearch={searchCourseList}
          />
        </div>
      </div>
      {searchQuery && <SearchResult text={searchQuery} />}
      <div className="w-full px-4 mt-10">
        <table className="w-full">
          <thead className="bg-primary bg-opacity-10">
            <TableRow header>
              <TableHeader hiddenMobile>번호</TableHeader>
              <TableHeader>교육명</TableHeader>
              <TableHeader hiddenTablet>교육기간</TableHeader>
              <TableHeader>수강률</TableHeader>
              <TableHeader>출석률</TableHeader>
              <TableHeader hiddenTablet>과제수행률</TableHeader>
              <TableHeader>수료증</TableHeader>
            </TableRow>
          </thead>
          <tbody className="border-b border-disabled">
            {!isLoading &&
              myCourseList.length > 0 &&
              myCourseList.map((item: myCourseListProp, index: number) => {
                // 번호 순번 지정
                const rowIndex = genTableIndex(
                  paginationInfo.currentPage,
                  paginationInfo.size,
                  index,
                );
                // 수료증 출력 가능 여부
                const {progressRate, attendRate, homeworkRate} = item;
                const isCertificateIssuable =
                  progressRate >= 90 && attendRate >= 90 && homeworkRate >= 90;

                return (
                  <TableRow key={index}>
                    <TableData hiddenMobile>{rowIndex}</TableData>
                    <TableData addClass={'text-start'}>{item.name}</TableData>
                    <TableData hiddenTablet>
                      <p>{genPerDate(item.startsAt)}</p>
                      <p>~</p>
                      <p>{genPerDate(item.endsAt)}</p>
                    </TableData>
                    <TableData>{progressRate}%</TableData>
                    <TableData>{attendRate}%</TableData>
                    <TableData hiddenTablet>{homeworkRate}%</TableData>
                    <TableData>
                      {isCertificateIssuable ? (
                        <SquareButton
                          text={'수료증 출력'}
                          type={'primary'}
                          onClick={() => fetchCertificate(item.courseId)}
                        />
                      ) : (
                        <SquareButton
                          text={'수료증 예정'}
                          type={'disabled'}
                          hasNoHoverEffect
                        />
                      )}
                    </TableData>
                  </TableRow>
                );
              })}
            {!isLoading && myCourseList.length === 0 && (
              <TableRowEmpty text="검색 결과 없음" />
            )}
            {isLoading && (
              <tr className="w-full">
                <td colSpan={99} className="text-center h-40">
                  <Skeleton />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!isLoading && paginationInfo && (
        <div className="my-8">
          <Pagination
            totalCount={paginationInfo?.total}
            pageSize={paginationInfo?.size}
            currentPage={paginationInfo?.currentPage}
            totalPage={paginationInfo?.totalPages}
            onClick={nextPage => {
              onChangePage(nextPage);
            }}
          />
        </div>
      )}

      {/* kacw 수료증 */}
      {certificate ? (
        <div style={{display: 'none'}}>
          {host === 'kacw' || host === 'r-dev'} ? (
          <div
            ref={kacwComponentRef}
            className="font-ChosunGs relative flex justify-center items-center w-[600px] h-[845px]">
            <div>
              <img src={KacwCertificateTemplate} alt="" />
            </div>
            <div className="absolute w-full h-full">
              <div className="absolute flex flex-col top-[188px] left-[145px] ">
                <div>
                  <span className="font-ChosunGs mr-[145px]">
                    {certificate.name}
                  </span>
                  <span className="font-ChosunGs ">
                    {certificate.phone.substring(0, 3)}-
                    {certificate.phone.substring(3, 7)}-
                    {certificate.phone.substring(7, 11)}
                  </span>
                </div>
              </div>
              <span
                className={'font-ChosunGs absolute left-[160px] top-[219px]'}>
                {moment(certificate.completeDate).year()}년{' '}
                {moment(certificate.completeDate).month() + 1}월{' '}
                {moment(certificate.completeDate).date()}일
              </span>
              <div
                className={
                  'font-ChosunGs w-[55%] absolute left-[175px] top-[282px]'
                }>
                {certificate.course}
              </div>
              <div className="w-[70%] flex flex-col justify-center absolute top-[443px] left-[300px] -translate-x-[50%] ">
                <div className="absolute">
                  <p className="font-ChosunGs absolute left-[144px]">
                    {moment(certificate.completeDate).year()}
                  </p>
                  <p className={'font-ChosunGs absolute left-[202px] '}>
                    {moment(certificate.completeDate).month() + 1}
                  </p>
                  <p className={'font-ChosunGs absolute left-[240px] '}>
                    {moment(certificate.completeDate).date()}
                  </p>
                </div>
              </div>
            </div>
          </div>
          ) : (
          <div className="w-[100vw] font-bold h-[100vh]  flex justify-center items-center ">
            <div
              ref={componentRef}
              className="relative flex justify-center items-center w-[600px] h-[845px]">
              <div>
                <img src={DefaultCertificateTemplate} alt="" />
              </div>
              <div className="absolute w-full h-full">
                <div className="absolute top-[70px] left-[70px] text-base">
                  제 {certificate.code} 호
                </div>

                <div className="w-[70%] flex justify-center break-keep absolute top-[19%] left-[50%] -translate-x-[50%] font-bold text-2xl">
                  {`${certificate.course} 수료증`}
                </div>

                <div className="absolute flex flex-col top-[278px] left-[238px] ">
                  <span className="text-lg mb-1">{certificate.name}</span>
                  <span className="text-lg">
                    {`${moment(certificate.completeDate).year()}년`}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {`${moment(certificate.completeDate).month() + 1}월`}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {`${moment(certificate.completeDate).date()}일`}
                  </span>
                </div>

                <div className="w-[70%] flex flex-col justify-center absolute top-[450px] left-[50%] -translate-x-[50%] ">
                  <span className="flex justify-center text-lg mb-1">{`위 사람은 상기 ${certificate.course}을(를) 수료하였기에 수료증을 수여합니다.`}</span>
                </div>

                <div className="absolute flex space-x-16 top-[600px] left-[50%] -translate-x-[50%]">
                  <span className="text-xl">
                    {`${moment(certificate.completeDate).year()}년`}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {`${moment(certificate.completeDate).month() + 1}월`}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {`${moment(certificate.completeDate).date()}일`}
                  </span>
                </div>
              </div>
            </div>
          </div>
          )
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
}
