import {useQuery} from 'react-query';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';
import {Site} from '@/types/site.type';
import {searchRegisterCourseListService} from '@/services/registerService';
import {useEffect, useState} from 'react';
import {PAGINATION_DATA} from '@/constants/common';
import userState from '@/stores/userAtom';

export function useRegisterCourse(searchQuery?: string | null) {
  const [pageParams, setPageParams] = useState({page: 1, pageSize: 10});

  const siteData = useRecoilValue(siteState);
  const user = useRecoilValue(userState);

  const {
    data: nextRegisterCourse,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      'registerCourses',
      {
        site: siteData,
        userId: user?.id,
      },
    ],
    queryFn: async ({queryKey}) => {
      const {site, userId} = queryKey[1] as {
        site: Site;
        userId: string;
      };
      if (!site) {
        return PAGINATION_DATA;
      }
      // 전 기관 교육과정 조회를 위해 academyId를 null로 전달
      const data = {
        page: pageParams.page,
        pageSize: pageParams.pageSize,
        siteId: site.id,
        academyId: null,
        userId,
        ...(searchQuery ? {name: searchQuery} : {}),
      };

      return await searchRegisterCourseListService(data);
    },
    enabled: !!siteData || !!user,
    initialData: PAGINATION_DATA,
  });

  useEffect(() => {
    refetch().then().catch();
  }, [pageParams]);

  function onChangePage(page: number) {
    setPageParams({
      ...pageParams,
      page,
    });
  }

  return {
    registerCourses: nextRegisterCourse.content,
    isLoading,
    paginationInfo: nextRegisterCourse.page,
    onChangePage,
    refetch,
  };
}
