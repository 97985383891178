import {useState} from 'react';
import {Link} from 'react-router-dom';
import {IoChevronBackSharp, IoChevronForwardSharp} from 'react-icons/io5';
import {Container, CircleButton} from '@/components/common';
import {useRegisterCourse} from '@/hooks/useRegisterCourse';
import {Site} from '@/types/site.type';
import {RegisterCourse} from '@/types/course.type';
import {replaceDateFormat} from '@/utils/format';
import skeletonImg from '@/assets/img/sample-02.png';

interface MainCourseProps {
  siteData: Site;
}

export function MainCourses({siteData}: MainCourseProps) {
  const cardsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(0);
  const {
    name,
    variation: {bottomDecorationImage, courseDescription},
  } = siteData;

  const {registerCourses} = useRegisterCourse();

  const handleNext = () => {
    const totalCards = registerCourses.length;
    const totalPage = (totalCards % cardsPerPage) + 1;
    if (currentPage === totalPage - 1) {
      setCurrentPage(0);
      return;
    }
    setCurrentPage(prev => prev + 1);
  };

  const handlePrev = () => {
    const totalCards = registerCourses.length;
    const totalPage = (totalCards % cardsPerPage) + 1;
    if (currentPage === 0) {
      setCurrentPage(totalPage - 1);
      return;
    }
    setCurrentPage(prev => prev - 1);
  };

  return (
    <Container className="my-12">
      <div
        className={`w-full flex justify-between max-w-[450px] px-6 mx-2 md:max-w-full bg-contain`}>
        <div className="w-full flex flex-col">
          {bottomDecorationImage ? (
            <img src={bottomDecorationImage} alt="기관 로고" className="w-44" />
          ) : (
            <></>
          )}

          <p className="text-sm my-4 leading-6 truncate md:text-base">
            {courseDescription[0]}
            <br />
            {courseDescription[1]}
          </p>
        </div>
        <div className="text-black flex items-end space-x-1 pt-4 md:pt-6 pr-8 md:pr-8">
          {registerCourses && registerCourses.length > 3 && (
            <>
              <CircleButton
                onClick={handlePrev}
                icon={<IoChevronBackSharp />}
                size={8}
              />
              <CircleButton
                onClick={handleNext}
                icon={<IoChevronForwardSharp />}
                size={8}
              />
            </>
          )}
        </div>
      </div>
      {/* 강의 리스트 - 캐러셀 */}
      <div className="relative min-w-[330px] px-6 mx-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
        <div className="absolute t-0 l-0 background-image w-full bg-no-repeat bg-contain bg-[url('@/assets/svgs/main-circle.svg')] z-0"></div>
        {registerCourses &&
          registerCourses.length > 0 &&
          registerCourses
            .slice(currentPage * cardsPerPage, currentPage * cardsPerPage + 3)
            .map((item: RegisterCourse, index: number) => (
              <div key={index} className="w-full flex justify-center">
                <Link
                  to={`/register/${item.id}`}
                  className="w-[90%] h-full border-shadow bg-white my-4 rounded-2xl z-10">
                  <img
                    src={item.thumbnailUrl || skeletonImg}
                    alt="course-thumbnail-image"
                    className="w-full p-3 rounded-3xl"
                  />

                  <h2 className="text-xl font-semibold px-4 mb-2">
                    {item.name}
                  </h2>
                  <div className="flex items-center space-x-2 px-4">
                    <p className="text-base font-bold">모집기간</p>
                    <p>
                      {replaceDateFormat(item.recruitStartsAt, 'YY.MM.DD')} ~
                      {replaceDateFormat(item.recruitEndsAt, 'YY.MM.DD')}
                    </p>
                  </div>
                  <div className="flex items-center space-x-2 px-4">
                    <p className="text-base font-bold">교육기간</p>
                    <p>
                      {replaceDateFormat(item.startsAt, 'YY.MM.DD')} ~{' '}
                      {replaceDateFormat(item.endsAt, 'YY.MM.DD')}
                    </p>
                  </div>
                  <div className="flex justify-between px-4 pt-4">
                    <div className="flex items-center">
                      <p className="text-2xl font-bold">
                        {item.applicantsCount}
                      </p>
                      <p className="text-lg ml-2">/ 선착순</p>
                    </div>
                    {item.recruitStatus === 'Y' && (
                      <button className="text-lg text-white bg-black px-5 py-2 border border-transparent rounded-full whitespace-nowrap">
                        모집중
                      </button>
                    )}
                    {item.recruitStatus === 'N' && (
                      <button className="text-lg text-black bg-white px-4 py-2 border border-black rounded-full whitespace-nowrap">
                        모집 마감
                      </button>
                    )}
                  </div>
                </Link>
              </div>
            ))}
        {registerCourses && registerCourses.length === 0 && (
          <>
            <div></div> <div>교육과정이 없습니다.</div>
          </>
        )}
      </div>
    </Container>
  );
}
