export function getEnv() {
  const value = process.env.REACT_APP_ENV;
  if (!value) {
    throw new Error('Failed get Environment');
  }
  return value;
}

export function getEndpoint() {
  const value = process.env.REACT_APP_API_END_POINT;
  if (!value) {
    throw new Error('Failed get Api Endpoint');
  }
  return value;
}

export function getDomain() {
  const value = process.env.REACT_APP_DOMAIN;
  if (!value) {
    throw new Error('Failed get DOMAIN');
  }
  return value;
}

export function getPlaySaveInterval() {
  const value = process.env.REACT_APP_PLAY_SAVE_INTERVAL;
  if (!value) {
    console.error('Failed get Play Save Interval');
    return 1000 * 60;
  }
  return Number(value);
}
