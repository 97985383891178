import {ChangeEvent, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  Layout,
  BoardContentEditor,
  PrimarySquareButton,
  SecondarySquareButton,
} from '@/components/common';
import Modal from '@/components/common/Modal';
import useModal from '@/hooks/useModal';
import {submitQuestionService} from '@/services/boardService';
import {useMyCourse} from '@/hooks/useMyCourse';
import {RegisterCourse} from '@/types/course.type';

export function QuestionWrite() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const courseIdQuery = queryParams.get('id');

  const {isShowing, closeModal, modalData, openModal} = useModal();
  const {myCourseList, isLoading: isLoadingCourse} = useMyCourse();
  const [course, setCourse] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('<br><br><br><br><br><br><br>');

  function handleTitleInput(e: ChangeEvent<HTMLInputElement>) {
    setTitle(e.target.value);
  }

  function handleOption(e: ChangeEvent<HTMLSelectElement>) {
    setCourse(e.target.value);
  }

  async function submitQuestion() {
    // if (myCourseList.length === 0) {
    //   openModal({
    //     type: 'error',
    //     message: '현재 수강중인 교육이 없습니다.',
    //   });
    //   return;
    // }

    if (!course) {
      openModal({
        type: 'error',
        message: '강의를 선택해주세요.',
      });
      return;
    }

    if (!title) {
      openModal({
        type: 'error',
        message: '제목을 입력해주세요.',
      });
      return;
    }
    if (!content) {
      openModal({
        type: 'error',
        message: '문의 내용을 작성해주세요.',
      });
      return;
    }

    const submitData = {
      course,
      title,
      content,
    };
    try {
      await submitQuestionService(submitData);
      openModal({
        type: 'check',
        message: '문의접수가 완료되었습니다.',
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => navigate('/question/course'),
          },
        ],
      });
    } catch (e: any) {
      openModal({
        type: 'error',
        message: '문제가 발생했습니다.',
      });
    }
  }

  useEffect(() => {
    if (!courseIdQuery) return;

    if (myCourseList && myCourseList.length > 0) {
      try {
        setCourse(courseIdQuery!);
      } catch (e: any) {
        return;
      }
    }
  }, [myCourseList]);

  return (
    <Layout>
      {/* 모달 */}
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
      <div className="px-16">
        {/* 헤더 */}
        <div className="flex justify-center my-8">
          <h1 className="text-3xl font-semibold">교육 문의</h1>
        </div>
        {/* 교육 선택 */}
        <div className="mb-8">
          <h2 className="text-2xl font-semibold mr-4 mb-2">교육 선택</h2>
          <select
            id="course"
            onChange={handleOption}
            value={course ?? ''}
            className="w-full focus:ring-0">
            <option value="">교육 선택</option>
            {!isLoadingCourse &&
              myCourseList.length > 0 &&
              myCourseList.map((item: RegisterCourse, index: number) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            <option value="etc">기타</option>
          </select>
        </div>
        {/* 제목 입력 */}
        <div className="mb-8">
          <h2 className="text-2xl font-semibold mr-4 mb-2">제목</h2>
          <input
            type="text"
            id="title"
            value={title}
            onChange={handleTitleInput}
            className="w-full"
          />
        </div>
        {/* 문의 내용 작성 에디터 */}
        <div className="mb-8">
          <h2 className="text-2xl font-semibold mr-4 mb-2">내용</h2>
          <BoardContentEditor value={content} onChange={setContent} />
        </div>
        {/* 버튼 항목 */}
        <div className="w-full px-4 pb-2 flex justify-center">
          <SecondarySquareButton
            text="취소"
            onClick={() => navigate('/question/course')}
          />
          <PrimarySquareButton onClick={submitQuestion} text="문의하기" />
        </div>
      </div>
    </Layout>
  );
}
