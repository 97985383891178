import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';

// 비로그인 상태일 때 접근 제한
function PrivateRoute() {
  const accessToken = localStorage.getItem('at');
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(!!accessToken);

  useEffect(() => {
    const accessToken = localStorage.getItem('at');
    setIsLoggedIn(!!accessToken);
  }, [location]);

  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={`/sign-in?redirect=${location.pathname}`} />
  );
}

export default PrivateRoute;
