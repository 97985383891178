import {
  DisabledSquareButton,
  Layout,
  PrimarySquareButton,
  SecondarySquareButton,
} from '@/components/common';
import {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {RegisterCourse} from '@/types/course.type';
import {searchCourseDetailService} from '@/services/courseService';
import {CourseInfoCard} from '@/components/CourseInfoCard';
import {parseHtmlContentWithSanitize} from '@/utils/parse';
import useModal from '@/hooks/useModal';
import {RegisterCourseModal} from '@/components/RegisterCourseModal';
import useUser from '@/hooks/useUser';

export function RegisterDetail() {
  const modal = useModal();
  const navigate = useNavigate();
  const {id: courseId} = useParams<{id: string}>();
  const [courseInfo, setCourseInfo] = useState<RegisterCourse>();
  const {user} = useUser();
  useEffect(() => {
    fetchCoursedInfo();
  }, []);

  useEffect(() => {
    fetchCoursedInfo();
  }, [user]);

  async function fetchCoursedInfo() {
    if (!courseId) {
      alert('잘못된 요청이거나 해당 교육 과정이 없습니다.');
      navigate('/register');
      return;
    }

    try {
      let res;
      if (user?.id) {
        res = await searchCourseDetailService(courseId, user.id);
      } else {
        res = await searchCourseDetailService(courseId);
      }
      setCourseInfo(res);
    } catch (e: any) {
      console.log(e);
    }
  }

  async function handleRegisterCourse() {
    if (!courseId) {
      return;
    }
    if (user?.id.length === 0) {
      navigate(`/sign-in?redirect=${location.pathname}`);
    }
    modal.openModal({});
  }

  return (
    <Layout>
      {/* 첨부파일 업로드 모달 */}
      {courseId && <RegisterCourseModal modal={modal} courseId={courseId} />}
      {/* 상단 수강 정보 카드 */}
      {<CourseInfoCard courseInfo={courseInfo!} />}
      {/* 버튼 영역 */}
      <div className="flex justify-center mt-4 mb-8">
        <SecondarySquareButton
          onClick={() => navigate('/register')}
          text="목록"
        />
        {courseInfo?.applicationStatus === 'ENROLLED' ? (
          <PrimarySquareButton
            text={'교육 수강'}
            onClick={() => navigate(`/course/${courseId}`)}
          />
        ) : courseInfo?.applicationStatus === 'WAITING' ? (
          <PrimarySquareButton text={'승인 대기 중'} />
        ) : courseInfo?.applicationStatus === 'NULL' &&
          courseInfo.recruitStatus === 'Y' ? (
          <PrimarySquareButton
            text={'교육 신청'}
            onClick={handleRegisterCourse}
          />
        ) : courseInfo?.applicationStatus === 'NULL' &&
          courseInfo.recruitStatus === 'N' ? (
          <DisabledSquareButton
            text={'신청 불가'}
            onClick={handleRegisterCourse}
          />
        ) : courseInfo?.applicationStatus === 'FAILURE' ? (
          <DisabledSquareButton text={'탈락'} />
        ) : (
          <></>
        )}
      </div>
      {/* 교육 소개 자료 */}
      <div className="w-[80%] mx-auto flex flex-col align-center mb-16">
        {parseHtmlContentWithSanitize(courseInfo?.content ?? '')}
      </div>
    </Layout>
  );
}
