import {Api} from '@/App';

export function reqGetChapter(chapterId: string) {
  return Api.get(`/chapters/${chapterId}`);
}

export function reqSavePlayTimeHistory(
  chapterId: string,
  eventType: string,
  eventTime: number,
) {
  return Api.post(`/chapters/${chapterId}/play-time-histories`, {
    eventType,
    eventTime,
  });
}

export function reqHomeworkList() {
  return Api.get('/homeworks/submit', {
    params: {
      submit: 0,
    },
  });
}

export function reqMyHomeworkList() {
  return Api.get('/homeworks/submit', {
    params: {
      submit: 1,
    },
  });
}

export function reqGetHomework(
  chapterId: string,
){
  return Api.get('/homeworks/submit',{
    params:{
      chapterId: chapterId
    },
  });
}