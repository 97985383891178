import {InputSearch} from '@/components/common';
import {useNavigate} from 'react-router-dom';
import Modal from '@/components/common/Modal';
import useModal from '@/hooks/useModal';
import {Site} from '@/types/site.type';

interface MainHeaderProps {
  siteData: Site;
}

export function MainHeader({siteData}: MainHeaderProps) {
  const navigate = useNavigate();
  const {isShowing, closeModal, modalData, openModal} = useModal();

  const {variation} = siteData;
  const {catchPhrase, leftDecorationImage, rightDecorationImage, description} =
    variation;

  function searchCourseList(query: string) {
    if (!localStorage.getItem('at') ? true : false) {
      openModal({
        type: 'error',
        message: '로그인이 필요합니다.',
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              closeModal();
            },
          },
        ],
      });
    }
    if (!query) {
      navigate('/register');
      return;
    }
    navigate(`/register?search=${query}`);
  }

  return (
    <div className="flex justify-evenly px-12 py-4 justify-center mt-1 md:space-x-4 lg:space-x-8 xl:space-x-16">
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
      <div
        className={`flex flex-col justify-center items-center  w-[420px] ${
          rightDecorationImage && 'md:items-start'
        }`}>
        {catchPhrase.map(item => (
          <label
            key={item}
            className="text-secondary font-extrabold text-4xl mb-2">
            {item}
          </label>
        ))}
        {leftDecorationImage && (
          <img
            src={
              leftDecorationImage ??
              'https://fs.lpin.io/lecture-on/assets/lecture-on-logo.png'
            }
            className="w-[300px] object-contains mt-3 mb-8"
          />
        )}
        {description.map(item => (
          <label key={item} className="text-gray-600 mb-1 text-sm">
            {item}
          </label>
        ))}
        <InputSearch
          placeholder="찾고 싶은 강의를 검색하세요."
          onSearch={searchCourseList}
        />
      </div>
      {rightDecorationImage && (
        <div className="hidden md:flex items-center justify-center">
          <img
            src={rightDecorationImage}
            alt="메인화면 배경"
            className="w-[400px] lg:w-[440px] xl:w-[480px]"
          />
        </div>
      )}
    </div>
  );
}
