import {
  reqGetBoardList,
  reqGetBoardDetail,
  reqGetComments,
  reqSiteQNA,
  reqWriteQuestion,
} from '@/api/boardApi';
import {
  ReqBoardListParams,
  QNADataParams,
  QuestionData,
} from '@/types/boards.type';

export async function getBoardList(params: ReqBoardListParams) {
  try {
    const response = await reqGetBoardList(params);
    return response.data.data;
  } catch (e: any) {
    // console.log(e);
  }
  return;
}

export async function getBoardDetailService(boardId: string) {
  try {
    const response = await reqGetBoardDetail(boardId);
    return response.data.data;
  } catch (e: any) {
    // console.log(e);
  }
}

export async function getCommentsService(boardId: string, siteId: string) {
  try {
    const response = await reqGetComments(boardId, siteId);
    return response.data.data;
  } catch (e: any) {
    // console.log(e);
  }
}

export async function submitQuestionService(data: QuestionData) {
  try {
    const response = await reqWriteQuestion(data);
    return response.data.data;
  } catch (e: any) {
    // console.log(e);
  }
}

export async function sendSiteQNA(data: QNADataParams) {
  try {
    const response = await reqSiteQNA(data);
    return response.data.data;
  } catch (e: any) {
    // console.log(e);
  }
}
