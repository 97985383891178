import {ButtonWithIconProps, ButtonWithTextProps} from '@/types/common.type';

export function PrimaryButton({text, onClick}: ButtonWithTextProps) {
  return (
    <button
      onClick={onClick}
      className="whitespace-nowrap bg-transparent text-gray-700 text-sm font-[Pippins] px-4 py-1 mt-1 rounded-full hover:text-primary md:px-6 md:py-2 md:mx-4 md:border md:border-primary md:text-primary md:hover:bg-primary md:hover:text-white md:hover:border-transparent duration-100">
      {text}
    </button>
  );
}

export function CircleButton({icon, onClick, size}: ButtonWithIconProps) {
  return (
    <button
      onClick={onClick}
      className={`w-6 h-6 w-${size} h-${size} flex justify-center items-center border border-black rounded-full text-center text-black text-lg hover:bg-black hover:text-white transition duration-300 cursor-pointer`}>
      {icon}
    </button>
  );
}

export function TableButton({text, onClick}: ButtonWithTextProps) {
  return (
    <button
      onClick={onClick}
      className="whitespace-nowrap text-secondary border border-secondary py-2 md:px-2 rounded hover:bg-secondary hover:text-white">
      {text}
    </button>
  );
}

export function PrimarySquareButton({text, onClick}: ButtonWithTextProps) {
  return (
    <button
      onClick={onClick}
      className="whitespace-nowrap bg-transparent text-sm font-[Pippins] px-2 md:px-3 lg:px-4 py-1 mx-4 mt-1 border border-primary rounded text-primary hover:bg-primary hover:text-white hover:border-transparent duration-100">
      {text}
    </button>
  );
}

export function PrimarySquareButtonWithIcon({
  icon,
  onClick,
}: {
  icon: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <button
      onClick={onClick}
      className="bg-primary text-sm font-[Pippins] px-4 py-1 mx-4 mt-1 border border-transparent rounded text-white hover:bg-transparent hover:text-primary hover:border-primary duration-100">
      {icon}
    </button>
  );
}

export function SecondarySquareButton({text, onClick}: ButtonWithTextProps) {
  return (
    <button
      onClick={onClick}
      className="whitespace-nowrap bg-transparent text-sm font-[Pippins] px-2 md:px-3 lg:px-4 py-1 mx-4 mt-1 border border-secondary rounded text-secondary hover:bg-secondary hover:text-white hover:border-transparent duration-100">
      {text}
    </button>
  );
}

export function DisabledSquareButton({text}: ButtonWithTextProps) {
  return (
    <button className="whitespace-nowrap bg-disabled text-sm font-[Pippins] px-2 md:px-3 lg:px-4 py-1 mx-4 mt-1 border border-disabled rounded text-gray-500 duration-100 cursor-default">
      {text}
    </button>
  );
}

export function SquareButton({
  text,
  onClick,
  type,
  hasNoHoverEffect,
  addClass,
}: ButtonWithTextProps) {
  let typeOfStyle;
  let hoverStyle;

  if (type === 'primary') {
    typeOfStyle = 'border-primary text-primary';
    hoverStyle = 'hover:bg-primary hover:text-white hover:border-transparent';
  }
  if (type === 'secondary') {
    typeOfStyle = 'border-secondary text-secondary';
    hoverStyle = 'hover:bg-secondary hover:text-white hover:border-transparent';
  }
  if (type === 'disabled') {
    typeOfStyle = 'text-gray-400 border-gray-400';
    hoverStyle = 'cursor-default';
  }

  // No hover effect
  if (hasNoHoverEffect) {
    hoverStyle = 'cursor-default';
  }

  return (
    <button
      onClick={type !== 'disabled' ? onClick : undefined}
      className={`whitespace-nowrap px-2 md:px-3 lg:px-4 py-1 mx-4 mt-1 border rounded text-sm font-[Pippins] duration-100 
    ${typeOfStyle} ${hoverStyle} ${addClass}
    `}>
      {text}
    </button>
  );
}
