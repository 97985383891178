import {ReactNode, useEffect, useMemo, useState} from 'react';
import {
  AiOutlineVerticalLeft,
  AiOutlineVerticalRight,
  AiOutlineLeft,
  AiOutlineRight,
} from 'react-icons/ai';

function Page({
  disable,
  selected,
  children,
  onClick,
}: {
  disable?: boolean;
  selected?: boolean;
  children: ReactNode;
  onClick: () => void;
}) {
  const fontColor = useMemo(() => {
    if (disable) {
      return 'text-slate-400';
    }
    if (selected) {
      return 'text-white';
    }
    return 'text-slate-700 hover:text-white';
  }, [disable, selected]);

  const backgroundColor = useMemo(
    () => (selected ? 'bg-primary hover:bg-primary' : 'bg-white'),
    [selected],
  );

  const hoverStyle = useMemo(
    () => (disable ? undefined : 'hover:bg-secondary'),
    [disable],
  );

  const cursorStyle = useMemo(
    () => (disable ? 'cursor-default' : 'cursor-pointer'),
    [disable],
  );

  return (
    <span
      className={`flex justify-center items-center w-[32px] h-[32px] m-0.5 rounded-full ${fontColor} ${backgroundColor} ${cursorStyle} ${hoverStyle}`}
      onClick={onClick}>
      {children}
    </span>
  );
}

interface BoardPaginationProps {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPage: number;
  onClick: (page: number) => void;
}
export function Pagination({
  totalCount,
  pageSize,
  currentPage,
  totalPage,
  onClick,
}: BoardPaginationProps) {
  const [pageList, setPageList] = useState<Array<number>>([]);
  useEffect(() => {
    const nextTotalPage = Math.ceil(totalCount / pageSize);
    const nextPageList: Array<number> = [];

    if (nextTotalPage === 0) {
      setPageList(nextPageList);
      return;
    }

    if (currentPage <= nextTotalPage && nextTotalPage <= 5) {
      for (let i = 1; i <= nextTotalPage; i++) {
        nextPageList.push(i);
      }
      setPageList(nextPageList);
      return;
    }

    if (currentPage === totalPage) {
      nextPageList.push(currentPage);
      setPageList(nextPageList);
      return;
    }

    if (currentPage % 5 === 0) {
      for (let i = currentPage - 5 + 1; i <= currentPage; i++) {
        nextPageList.push(i);
      }
      setPageList(nextPageList);
      return;
    }

    const startPage = Math.floor(currentPage / 5) * 5 + 1;
    for (let i = startPage; i < startPage + 5; i++) {
      nextPageList.push(i);
    }
    setPageList(nextPageList);
  }, [totalCount]);

  return (
    <div className={'w-max min-w-full flex justify-center items-center'}>
      <Page
        disable={currentPage === 1}
        onClick={() => (currentPage === 1 ? {} : onClick(1))}>
        <AiOutlineVerticalRight />
      </Page>
      <Page
        disable={currentPage === 1}
        onClick={() => (currentPage === 1 ? {} : onClick(currentPage - 1))}>
        <AiOutlineLeft />
      </Page>
      {pageList.map(page => {
        return (
          <Page
            key={page}
            selected={page === currentPage}
            onClick={() => onClick(page)}>
            <span>{page}</span>
          </Page>
        );
      })}
      <Page
        disable={currentPage === totalPage || !totalPage || totalPage === 0}
        onClick={() =>
          currentPage + 1 > totalPage ? {} : onClick(currentPage + 1)
        }>
        <AiOutlineRight />
      </Page>
      <Page
        disable={currentPage === totalPage || !totalPage || totalPage === 0}
        onClick={() => (currentPage === totalPage ? {} : onClick(totalPage))}>
        <AiOutlineVerticalLeft />
      </Page>
    </div>
  );
}
