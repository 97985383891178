import {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {HiBars3, HiXMark} from 'react-icons/hi2';
import {Nav, PrimaryButton} from '@/components/common';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';
import {clearLoginStatus} from '@/utils/common';
import Modal from '@/components/common/Modal';
import useModal from '@/hooks/useModal';

export function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const siteData = useRecoilValue(siteState);
  const {logo} = siteData ? siteData : {logo: null};
  const handleToggleClick = () => {
    setIsMobileMenuOpen(prev => !prev);
  };

  function onLogout() {
    clearLoginStatus();
    setIsLoggedIn(localStorage.getItem('at') ? true : false);
    openModal({
      title: '로그아웃',
      message: '정상적으로 로그아웃되었습니다.',
    });
  }

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem('at') ? true : false);
  }, []);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  return (
    <header>
      <Modal
        isShowing={isShowing}
        type="alert"
        title={modalData?.title}
        message={modalData?.message}
        buttons={[
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              closeModal();
              window.location.href = '/';
            },
          },
        ]}
      />
      <div className="w-full flex items-center relative justify-between max-w-screen-xl mx-auto py-3.5 pl-4 box-border 2xl:py-4">
        {/* 헤더 좌측 */}
        <div className="flex items-center">
          {/* 헤더 좌측 - 로고 영역 */}
          <Link to="/" className="cursor-pointer">
            {logo && (
              <img
                src={logo}
                alt="logo"
                className="h-3 object-contain 2xl:h-[40px] md:h-[32px]"
              />
            )}
          </Link>
          {/* 헤더 좌측 - 네비게이션 영역 */}
          <Nav isMobile={false} />
        </div>
        {/* 헤더 우측 */}
        <div className="flex items-center ">
          {isLoggedIn ? (
            <>
              <button
                onClick={() => navigate('/user/update')}
                className={`bg-transparent text-sm font-[Pippins] px-2 py-1 mt-1
                ${
                  location.pathname !== '/user/update'
                    ? 'text-gray-700 hover:text-primary'
                    : 'text-primary'
                }
                `}>
                정보 수정
              </button>
              <PrimaryButton text="로그아웃" onClick={onLogout} />
            </>
          ) : (
            <PrimaryButton
              text="로그인"
              onClick={() => {
                navigate('/sign-in');
              }}
            />
          )}
          {/* 햄버거 메뉴 토글 버튼 */}
          <button
            onClick={handleToggleClick}
            className="text-3xl cursor-pointer ml-2 mr-4  md:hidden block">
            {isMobileMenuOpen ? (
              <HiXMark name="menu" />
            ) : (
              <HiBars3 name="menu" />
            )}
          </button>
        </div>
      </div>
      {/* 토글 오픈 시 보이는 메뉴 */}
      {isMobileMenuOpen && (
        <Nav isMobile={true} setIsMobileMenuOpen={setIsMobileMenuOpen} />
      )}
    </header>
  );
}

export function SignPageHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const siteData = useRecoilValue(siteState);

  const {logo} = siteData ? siteData : {logo: null};

  return (
    <header>
      <div className="w-full flex items-center relative justify-between max-w-screen-xl mx-auto py-3.5 pl-4 box-border 2xl:py-4">
        {/* 헤더 좌측 */}
        <div className="flex items-center">
          {/* 헤더 좌측 - 로고 영역 */}
          <Link to="/" className="cursor-pointer">
            {logo && (
              <img
                src={logo}
                alt="logo"
                className="h-[32px] object-contain 2xl:h-[40px]  "
              />
            )}
          </Link>
        </div>
        {/* 헤더 우측 */}
        <div className="flex items-center ">
          {/* 로그인 버튼 */}
          <button
            onClick={() => navigate('/sign-in')}
            className={`bg-transparent text-gray-700 text-sm font-[Pippins] py-1 mt-1 hover:text-primary duration-100 md:text-base ${
              location.pathname === '/sign-in' && 'text-primary'
            }`}>
            로그인
          </button>
          <button
            onClick={() => navigate('/sign-up')}
            className={`bg-transparent text-gray-700 text-sm font-[Pippins] py-1 mt-1 hover:text-primary duration-100 md:text-base mx-8 ${
              location.pathname === '/sign-up' && 'text-primary'
            }`}>
            회원가입
          </button>
        </div>
      </div>
    </header>
  );
}
