import {useState, ChangeEvent} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {IoLockClosedOutline} from 'react-icons/io5';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import {Layout, Input} from '@/components/common';
import Modal from '@/components/common/Modal';
import {deleteUserService} from '@/services/userService';
import useModal from '@/hooks/useModal';
import siteState from '@/stores/siteAtom';
import useUser from '@/hooks/useUser';
import {clearLoginStatus} from '@/utils/common';

export function UserWithDraw() {
  const navigate = useNavigate();
  const {user, refreshUserState} = useUser();
  const siteData = useRecoilValue(siteState);
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const [password, setPassword] = useState('');
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState(false);

  const logo = siteData?.logo;

  function handleChangePassword(e: ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  async function updateUserInfo() {
    const userId = user!.id;

    // 비밀번호 입력 여부 검증
    if (!password) {
      openModal({
        type: 'error',
        message: '비밀번호를 입력해주세요.',
      });
      return;
    }

    try {
      await deleteUserService(password, userId);
      clearLoginStatus();
      refreshUserState();
      openModal({
        type: 'check',
        message: '회원 탈퇴가 완료되었습니다.',
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              navigate('/');
            },
          },
        ],
      });
    } catch (e: any) {
      openModal({
        type: 'error',
        message: e.data.error.message,
      });
    }
  }

  return (
    <Layout isHiddenFooter>
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
      <div className="w-full h-full mb-0 flex flex-col items-center py-12">
        <div className="w-full max-w-[500px] flex flex-col items-center my-12">
          {/* 기관 로고 */}
          {logo && (
            <img
              src={logo}
              alt="기관 로고"
              className="w-[200px] md:w-[215px] lg:w-[230px]"
            />
          )}
          {/* 회원가입 폼 */}
          <form className="w-full flex flex-col items-center px-8 my-10">
            <h2 className="text-lg md:text-2xl font-semibold text-secondary mb-4">
              회원 탈퇴
            </h2>
            <Input
              id="currentPassword"
              type={isCurrentPasswordVisible ? 'text' : 'password'}
              placeholder="비밀번호"
              value={password}
              onChange={handleChangePassword}
              leftIcon={<IoLockClosedOutline className="text-lg md:text-xl" />}
              rightIcon={
                isCurrentPasswordVisible ? (
                  <AiOutlineEye
                    className="cursor-pointer text-2xl"
                    onClick={() => {
                      setIsCurrentPasswordVisible(prev => !prev);
                    }}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="cursor-pointer text-xl md:text-2xl"
                    onClick={() => {
                      setIsCurrentPasswordVisible(prev => !prev);
                    }}
                  />
                )
              }
            />
            {/* 확인 버튼 */}
            <span
              onClick={updateUserInfo}
              className="w-28 text-center text-sm md:text-base md:w-32 border border-transparent mt-4 py-2 bg-primary text-white rounded-full transition-all ease-in-out delay-50 hover:bg-white hover:border-primary hover:text-primary cursor-pointer">
              회원 탈퇴
            </span>
          </form>
        </div>
      </div>
    </Layout>
  );
}
