import {reqGetCourseList} from '@/api/courseApi';
import {PAGINATION_DATA} from '@/constants/common';

interface SearchRegisterCourseListServiceParams {
  page: number;
  pageSize: number;
  siteId: string;
  academyId?: string | null;
  name?: string;
  userId?: string;
}

export async function searchRegisterCourseListService(
  data: SearchRegisterCourseListServiceParams,
) {
  try {
    const result = await reqGetCourseList({
      ...data,
      status: 'ACTIVE',
    });

    return result.data.data;
  } catch (e) {
    console.error('searchRegisterCourseListService: ', e);
    return PAGINATION_DATA;
  }
}
