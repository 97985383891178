import {reqGetSite, reqGetSitePreferences, reqAcademies} from '@/api/siteApi';
import {SitePreference} from '@/types/site.type';

export async function getCurrentSiteService(host: string) {
  const splitHost = host.split('.') ?? [];

  const response = await reqGetSite(splitHost[0]);

  if (!response.data.data.variation) {
    throw new Error('사이트 정보가 존재하지 않습니다.');
  }

  return response.data.data;
}

export async function getSitePreferencesService(
  siteId: string,
): Promise<SitePreference> {
  try {
    const response = await reqGetSitePreferences(siteId);
    return response.data.data;
  } catch (e) {
    throw new Error('사이트 설정 정보가 존재하지 않습니다.');
  }
}
export async function getAcademies(siteId?: string) {
  const response = await reqAcademies(siteId);
  return response;
}
