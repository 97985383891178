import {useState} from 'react';
import {ModalButton} from '@/components/common/Modal';

interface OpenModalParams {
  type?: 'error' | 'alert' | 'question' | 'check' | 'policy';
  title?: string;
  message?: string;
  content?: React.ReactNode;
  buttons?: ModalButton[];
}

export default function useModal() {
  const [isShowing, setIsShowing] = useState(false);
  const [modalData, setModalData] = useState<OpenModalParams>();

  function openModal(newModalData: OpenModalParams) {
    setModalData(prev => {
      return {
        ...prev,
        ...newModalData,
      };
    });
    setIsShowing(true);
  }

  function closeModal() {
    setModalData({});
    setIsShowing(false);
  }

  return {
    isShowing,
    modalData,
    openModal,
    closeModal,
  };
}
