import {Container} from '@/components/common';
import {Site} from '@/types/site.type';

interface MainFooterProps {
  siteData: Site;
}

// 함께하는 분들
export function MainFooter({siteData}: MainFooterProps) {
  const {withUs} = siteData.variation;

  return (
    <Container>
      <div className="w-full flex flex-col items-center">
        <div className="w-16 h-1 bg-primary"></div>
      </div>
      <div
        // 컬럼 수도 반영해야함
        className={`w-full px-8 grid grid-cols-${3} gap-4 justify-items-center items-center my-4`}>
        {withUs.map((item, index) => (
          <img
            key={item.id}
            src={item.url}
            alt={`기관 로고 ${index + 1}`}
            className="h-[83px]"
          />
        ))}
      </div>
    </Container>
  );
}
