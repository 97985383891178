export function isClient() {
  return typeof window !== 'undefined';
}

export function checkLoginStatus() {
  const at = localStorage.get('at');
  if (at) true;
  return false;
}

export function clearLoginStatus() {
  localStorage.removeItem('at');
  localStorage.removeItem('rt');
}

// 객체에서 특정한 값들을 선택
export function pick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
  return keys.reduce((acc, key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {} as Pick<T, K>);
}
