import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {SecondarySquareButton, PrimarySquareButton} from '@/components/common';
import {ModalWrapper} from '@/components/common/Modal';
import {uploadAllTypeFileService} from '@/services/commonService';
import {
  applyCourseService,
  applyKacwCourseService,
} from '@/services/courseService';

export function RegisterCourseModal({
  modal,
  courseId,
}: {
  modal: any;
  courseId: string;
}) {
  const navigate = useNavigate();
  const {isShowing, closeModal} = modal;
  const [fileName, setFileName] = useState('');
  const [uploadFileLabel, setUploadFileLabel] = useState('파일 업로드');
  const [fileUrl, setFileUrl] = useState('/');
  const [attachedFile, setAttachedFile] = useState({
    id: '',
    fileName: '',
    fileUrl: '',
  });
  const host = window.location.host.split('.')[0];
  //  과제 파일업로드
  const onClickUploadFile = () => {
    uploadAllTypeFileService(
      {parentId: courseId, parentType: 'COURSE_APPLICATION'},
      ({fileId, fileName, fileUrl}) => {
        setFileName(fileName);
        setUploadFileLabel(fileName);
        setFileUrl(fileUrl);
        setAttachedFile({
          id: fileId,
          fileName: fileName,
          fileUrl: fileUrl,
        });
      },
    );
  };
  const onClickDeleteAttachment = () => {
    setAttachedFile({id: '', fileName: '', fileUrl: ''});
    setUploadFileLabel('파일 업로드');
  };
  useEffect(() => {
    return () =>
      document.body
        .querySelectorAll(':scope > input')
        .forEach(item => item.remove());
  }, []);

  const submitApplication = async () => {
    if (!courseId) {
      return;
    }

    const applicationFile = {
      id: attachedFile.id,
      fileName: attachedFile.fileName,
      fileUrl: attachedFile.fileUrl,
    };

    if (
      window.location.host.includes('.') &&
      (window.location.host.split('.')[0] === 'kacw' ||
        window.location.host.split('.')[0] === 'r-dev')
    ) {
      try {
        const response = await applyKacwCourseService(courseId, [
          applicationFile,
        ]);
        // if (response.paymentUrl && response.paymentUrl.length > 0) {
        //   alert('결제 후 강의 수강이 가능합니다. 결제 페이지로 이동합니다.');
        //   navigate('/pay-results');
        // } else if (response.result === true) {
        //   alert('교육 신청이 완료되었습니다.');
        //   window.location.reload();
        // }
        if (response.result === true) {
          alert('교육 신청이 완료되었습니다.');
          window.location.reload();
        }
      } catch (e: any) {
        alert(e.data.error.message ?? '교육 신청에 실패했습니다.');
      } finally {
        closeModal();
      }
    } else if (host === 'gwcloud' || host === 'gwtp-t') {
      /**
       * TODO: url 말고 사이트 옵션으로 처리
       */
      if (applicationFile.fileUrl === '') {
        alert('파일을 첨부해주세요.');
        return;
      }
      try {
        const result = await applyCourseService(courseId, [applicationFile]);
        if (result) {
          alert('교육 신청이 완료되었습니다.');
          window.location.reload();
        }
        closeModal();
      } catch (e: any) {
        alert(e.data.error.message ?? '교육 신청에 실패했습니다.');
      }
    } else {
      try {
        const result = await applyCourseService(courseId, [applicationFile]);
        if (result) {
          alert('교육 신청이 완료되었습니다.');
          window.location.reload();
        }
        closeModal();
      } catch (e: any) {
        alert(e.data.error.message ?? '교육 신청에 실패했습니다.');
      }
    }
  };

  return (
    <ModalWrapper isShowing={isShowing} modalWidth={'xl'}>
      {/* 과제 정보 */}
      <>
        <div className="w-full flex flex-col justify-center">
          <h3 className="text-2xl text-center font-semibold pt-8 mb-2">
            교육 신청
          </h3>
          <div className="sm:text-center">
            <p>교육 신청을 위해 필요한 파일을 업로드해주세요.</p>
            {/* TODO: url 말고 사이트 옵션으로 처리 */}
            {(host === 'gwcloud' || host === 'gwtp-t') && (
              <p className={'text-red-500 font-semibold'}>
                재직 증명서 및 경력 증명서를 압축하여 1개의 파일로 필수 첨부
              </p>
            )}
          </div>
        </div>
      </>

      <>
        <div className="w-full px-4">
          {/* 파일 첨부 */}
          <div className="my-2 flex flex-col justify-center">
            {attachedFile.fileName === '' ? (
              <button
                onClick={onClickUploadFile}
                className={
                  'underline border-[0.5px] border-transparent text-lg text-slate-700 my-1 mx-1 py-1.5 px-3.5 rounded hover:bg-slate-50 hover:text-slate-700'
                }>
                파일 업로드
              </button>
            ) : (
              <button
                onClick={onClickDeleteAttachment}
                className={
                  'underline border-[0.5px] border-transparent text-lg text-slate-700 my-1 mx-1 py-1.5 px-3.5 rounded hover:bg-slate-50 hover:text-slate-700'
                }>
                {fileName} &nbsp; 🅧
              </button>
            )}
          </div>

          {/* 버튼 항목 */}
          <div className="w-full px-4 pb-2 flex justify-center mb-[24px]">
            <SecondarySquareButton text="취소" onClick={closeModal} />
            <PrimarySquareButton onClick={submitApplication} text="신청하기" />
          </div>
        </div>
      </>
    </ModalWrapper>
  );
}
