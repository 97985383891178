import {
  reqGetChapter,
  reqSavePlayTimeHistory,
  reqHomeworkList,
  reqMyHomeworkList, reqGetHomework,
} from '@/api/chapterApi';
import {Chapter} from '@/types/course.type';

export async function searchChapterService(
  chapterId: string,
): Promise<Chapter> {
  const response = await reqGetChapter(chapterId);
  return response.data.data;
}

export async function savePLayTimeHistoryService(
  chapterId?: string | null,
  eventType?: string,
  eventTime?: number,
) {
  if (!chapterId || !eventType || !eventTime) {
    return;
  }
  const response = await reqSavePlayTimeHistory(
    chapterId,
    eventType,
    Math.ceil(eventTime),
  );
  return response.data.data;
}

export async function getHomeworkListService() {
  const response = await reqHomeworkList();
  return response.data.data;
}

export async function getMyHomeworkListService() {
  const response = await reqMyHomeworkList();
  return response.data.data;
}

export async function getHomeworkDetailService(
  chapterId: string,
){
  const response=await reqGetHomework(chapterId);
  return response.data.data;
}