import React, {ReactNode} from 'react';

export function TableHeader({
  children,
  hiddenMobile,
  hiddenTablet,
}: {
  children: ReactNode;
  hiddenMobile?: boolean;
  hiddenTablet?: boolean;
}) {
  return (
    <th
      className={`
      border-b-[1px]
      whitespace-nowrap
      ${hiddenTablet ? 'hidden md:table-cell' : ''}
      ${hiddenMobile ? 'hidden lg:table-cell' : ''}
      text-center table-cell px-5 py-3 text-sm font-semibold text-slate-800`}>
      {children}
    </th>
  );
}

export function TableData({
  width,
  span,
  children,
  hiddenMobile,
  hiddenTablet,
  addClass,
}: {
  width?: number | string;
  span?: number;
  children: ReactNode;
  hiddenMobile?: boolean;
  hiddenTablet?: boolean;
  addClass?: string;
}) {
  function visibleStyle() {
    if (hiddenTablet && hiddenMobile) {
      return 'hidden';
    }
    if (hiddenTablet) {
      return 'hidden md:table-cell';
    }
    if (hiddenMobile) {
      return 'hidden lg:table-cell';
    }
    return '';
  }

  return (
    <td
      width={width}
      colSpan={span}
      className={`
      border-slate-300 p-4 text-sm text-slate-800 text-center 
      ${visibleStyle()}
      ${addClass}`}>
      {children}
    </td>
  );
}

export function TableRow({
  header,
  children,
  onClick,
  addClass,
}: {
  header?: boolean;
  children: ReactNode;
  addClass?: string;
  onClick?: () => void;
}) {
  return (
    <tr
      className={`
      ${header ? 'cursor-default' : 'hover:bg-[#fafafa]'}
      ${addClass}
       border-b-[1px]`}
      onClick={onClick}>
      {children}
    </tr>
  );
}

export function TableRowEmpty({text}: {text: string}) {
  return (
    <>
      <tr className="w-full">
        <td colSpan={99} className="text-center h-40">
          {text}
        </td>
      </tr>
    </>
  );
}
