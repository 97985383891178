import {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {useQuery} from 'react-query';
import {
  gethMyCourseListService,
  getXProgressService,
} from '@/services/courseService';
import siteState from '@/stores/siteAtom';
import useUser from '@/hooks/useUser';
import {Site} from '@/types/site.type';
import {User} from '@/types/user.type';
import {RegisterCourse} from '@/types/course.type';

export function useMyCourse(searchQuery?: string | null) {
  const [pageParams, setPageParams] = useState({page: 1, pageSize: 10});
  const {user} = useUser();

  const siteData = useRecoilValue(siteState);

  const {data, isLoading, refetch} = useQuery({
    queryKey: [
      'myCourse',
      {
        user,
        site: siteData,
      },
    ],
    queryFn: async ({queryKey}) => {
      const {user, site} = queryKey[1] as {
        user: User;
        site: Site;
      };

      const myCourseResponse = await gethMyCourseListService(
        user.id,
        site.id,
        null,
        searchQuery || undefined,
      );

      const progressResponse = await getXProgressService({
        siteId: site.id,
        xType: 'COURSE',
      });

      const newMyCourseContent = myCourseResponse.content.map(
        (item: RegisterCourse, index: number) => {
          for (const progressRes of progressResponse) {
            if (progressRes.courseId === item.id) {
              return {...item, ...progressResponse[index]};
            }
          }
        },
      );

      return {
        ...myCourseResponse,
        content: newMyCourseContent,
      };
    },
  });

  useEffect(() => {
    refetch().then().catch();
  }, [pageParams]);

  function onChangePage(page: number) {
    setPageParams({
      ...pageParams,
      page,
    });
  }

  return {
    myCourseList: data?.content,
    isLoading,
    paginationInfo: data?.page,
    onChangePage,
    refetchMyCourses: refetch,
  };
}
