import {Header, SignPageHeader, Footer} from '@/components/common';
import {LayoutProps, ComponentWithChildren} from '@/types/common.type';

export function Layout({children, isHiddenFooter, isSignHeader}: LayoutProps) {
  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-center w-full">
        <div className="relative flex flex-col w-full max-w-screen-lg mx-auto min-w-[360px]">
          {!isSignHeader ? <Header /> : <SignPageHeader />}
          {children}
          {!isHiddenFooter && <Footer />}
        </div>
      </div>
    </div>
  );
}

export function Container({children, className}: ComponentWithChildren) {
  return (
    <div className={`w-full flex flex-col items-center px-8 ${className}`}>
      {children}
    </div>
  );
}
