import {useState, useEffect} from 'react';
import useUser from '@/hooks/useUser';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';
import {
  Layout,
  SquareButton,
  TableHeader,
  TableRow,
  TableData,
} from '@/components/common';
import {CourseModal} from '@/components/CourseModal';
import {Attachment} from '@/types/common.type';
import {HomeworkForChapter} from '@/types/course.type';
import useModal from '@/hooks/useModal';
import {replaceDateFormat} from '@/utils/format';
import {searchHomeworksService} from '@/services/homeworkService';
import {
  searchChapterService,
  getHomeworkDetailService,
} from '@/services/chapterService';

interface Homework {
  attachments: [];
  chapterId: string;
  content: string;
  createdAt: string;
  deletedAt: string;
  id: string;
  seq: number;
  title: string;
  updatedAt: string;
}

export function Homework() {
  const modal = useModal();
  const [selectedHomework, setSelectedHomework] =
    useState<HomeworkForChapter>();
  const [attachments, setAttachments] = useState<Array<Attachment>>();

  const siteData = useRecoilValue(siteState);
  const [myHomeworkList, setMyHomeworkList] =
    useState<Array<HomeworkForChapter>>();
  const params = new URLSearchParams(location.search);
  const chapterId = params.get('chapter') || '';
  const [chapterName, setChapterName] = useState('');
  const {user} = useUser();
  const userId = user?.id;

  useEffect(() => {
    // 강의 제목 받아옴
    fetchChapterInfo();

    // 해당 강의의 과제 리스트
    fetchMyHomeworkList();
  }, [siteData, userId]);
  async function fetchChapterInfo() {
    try {
      const chapter = await searchChapterService(chapterId!);
      setChapterName(chapter.name);
    } catch (e) {
      // console.log(e);
    }
  }

  // 제출한 과제 리스트 조회
  async function fetchMyHomeworkList() {
    try {
      // 모든 챕터의 모든 제출한 과제리스트
      const res = await getHomeworkDetailService(chapterId);
      const homeworkList: HomeworkForChapter[] = [];
      res.content.map((item: HomeworkForChapter) => {
        if (item.userId === userId) {
          homeworkList.push(item);
        }
      });
      setMyHomeworkList(homeworkList);
    } catch (e: any) {
      // console.log(e);
    }
  }

  // 과제 관리 모달로 이동
  async function onClickHomework(id: string) {
    myHomeworkList?.map((item: HomeworkForChapter) => {
      if (item.homeworkId === id) {
        setSelectedHomework(item);
      }
    });
    try {
      const res = await searchHomeworksService(chapterId);
      res.map((item: Homework) => {
        if (item.id === id) {
          setAttachments(item.attachments);
        }
      });
    } catch (e) {
      // console.log(e);
    }
    modal.openModal({});
  }

  return (
    <Layout>
      {selectedHomework && (
        <CourseModal
          modal={modal}
          homework={selectedHomework}
          attachments={attachments}
        />
      )}
      <div className="flex justify-center my-8">
        <h1 className="text-3xl font-semibold">{chapterName}</h1>
      </div>

      <div className="w-full px-4 mt-10">
        <table className="w-full">
          <thead className="bg-primary bg-opacity-10">
            <TableRow header>
              <TableHeader hiddenMobile>번호</TableHeader>
              <TableHeader>과제 제목</TableHeader>
              <TableHeader>등록 일시</TableHeader>
              <TableHeader>제출 현황</TableHeader>
            </TableRow>
          </thead>
          <tbody className="border-b border-disabled cursor-pointer">
            {myHomeworkList &&
              myHomeworkList.length > 0 &&
              myHomeworkList.map((item: HomeworkForChapter, index: number) => {
                let score = '0';
                if (
                  item.submitHomeworkScore &&
                  item.submitHomeworkScore !== 0
                ) {
                  score = item.submitHomeworkScore?.toString();
                }
                return (
                  <TableRow
                    key={index}
                    onClick={() => onClickHomework(item.homeworkId)}>
                    {/* 번호 */}
                    <TableData hiddenMobile>{index + 1}</TableData>
                    {/* 과제 제목 */}
                    <TableData>{item.homeworkTitle}</TableData>
                    {/* 일시 */}
                    <TableData>
                      {replaceDateFormat(
                        item.homeworkAt,
                        'YY.MM.DD(ddd) HH:MM',
                      )}
                    </TableData>
                    {/* 제출 현황 */}
                    <TableData>
                      {score >= '1' && score <= '100' && (
                        <button
                          className={`whitespace-nowrap px-2 md:px-3 lg:px-4 py-1 mx-4 mt-1 border rounded text-sm font-[Pippins] duration-100 bg-primary text-white hover:bg-white hover:text-primary hover:border-primary`}>
                          합격
                        </button>
                      )}
                      {score === '0' && (
                        <button
                          className={`whitespace-nowrap px-2 md:px-3 lg:px-4 py-1 mx-4 mt-1 border rounded text-sm font-[Pippins] duration-100 bg-secondary text-white hover:bg-white hover:text-secondary hover:border-secondary`}>
                          불합격
                        </button>
                      )}
                      {score === '-1' && (
                        <SquareButton type="primary" text="제출" />
                      )}
                      {score === '-10' && (
                        <SquareButton type="secondary" text="미제출" />
                      )}
                    </TableData>
                  </TableRow>
                );
              })}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
