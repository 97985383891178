import {useState, useEffect, ChangeEvent} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {AiOutlineClose} from 'react-icons/ai';
import {Attachment} from '@/types/common.type';
import {Chapter, HomeWork, HomeworkForChapter} from '@/types/course.type';
import useUser from '@/hooks/useUser';
import {
  TableHeader,
  TableRow,
  TableData,
  SquareButton,
  BoardContentEditor,
  SecondarySquareButton,
  PrimarySquareButton,
} from '@/components/common';
import {ModalWrapper} from '@/components/common/Modal';
import {replaceDateFormat} from '@/utils/format';
import {uploadAllTypeFileService} from '@/services/commonService';
import {
  SubmitHomeworkParams,
  submitHomeworkService,
} from '@/services/homeworkService';
import {useHomework} from '../hooks/useHomework';
import {parseHtmlContentWithSanitize} from '@/utils/parse';

export function CourseModal({
  modal,
  homework,
  attachments,
}: {
  modal: any;
  homework: HomeworkForChapter;
  attachments?: Attachment[];
}) {
  const navigate = useNavigate();
  const {isShowing, closeModal, modalData, openModal} = modal;
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('<br><br><br><br><br><br><br>');
  const [fileName, setFileName] = useState('');
  const [uploadFileLabel, setUploadFileLabel] = useState('파일 업로드');
  const [fileUrl, setFileUrl] = useState('/');
  const [attachedFile, setAttachedFile] = useState({
    id: '',
    fileName: '',
    fileUrl: '',
  });
  const [downloadUrl, setDownloadUrl] = useState('');
  const [downloadSubmittedHomeworkUrl, setDownloadSubmittedHomeworkUrl] =
    useState('');

  // 과제 파일 다운로드
  useEffect(() => {
    (async () => {
      if (attachments && attachments[0] && attachments[0].fileUrl) {
        const res = await fetch(attachments[0].fileUrl.split('?')[0]);
        const blob = await res.blob();
        setDownloadUrl(window.URL.createObjectURL(blob));
      }
    })();
  }, [attachments]);

  // 제출 파일 다운로드
  useEffect(() => {
    (async () => {
      if (
        homework &&
        homework.attachments[0] &&
        homework.attachments[0].fileUrl
      ) {
        const res = await fetch(homework.attachments[0].fileUrl.split('?')[0]);
        const blob = await res.blob();
        setDownloadSubmittedHomeworkUrl(window.URL.createObjectURL(blob));
      }
    })();
  }, [homework]);

  //  과제 파일업로드
  const onClickUploadFile = () => {
    uploadAllTypeFileService(
      {parentType: 'SUBMIT_HOMEWORK'},
      ({fileId, fileName, fileUrl}) => {
        setFileName(fileName);
        setUploadFileLabel(fileName);
        setFileUrl(fileUrl);
        setAttachedFile({
          id: fileId,
          fileName: fileName,
          fileUrl: fileUrl,
        });
      },
    );
  };
  const onClickDeleteAttachment = () => {
    setAttachedFile({id: '', fileName: '', fileUrl: ''});
    setUploadFileLabel('파일 업로드');
  };
  useEffect(() => {
    return () =>
      document.body
        .querySelectorAll(':scope > input')
        .forEach(item => item.remove());
  }, []);

  function handleTitleInput(e: ChangeEvent<HTMLInputElement>) {
    setTitle(e.target.value);
  }

  async function deleteHomework() {
    if (!homework.homeworkId) {
      return;
    }
  }
  const submitHomework = async () => {
    if (!homework.homeworkId) {
      return;
    }

    if (!title) {
      alert('제목을 입력해주세요');
      return;
    }
    if (!content || content === '<br><br><br><br><br><br><br>') {
      alert('내용을 입력해주세요');
      return;
    }
    const homeworkFile = {
      id: attachedFile.id,
      fileName: attachedFile.fileName,
      fileUrl: attachedFile.fileUrl,
    };
    const submitData = {
      title: title,
      content: content,
      attachments: homeworkFile.id.length > 0 ? [homeworkFile] : [],
    };

    try {
      const result = await submitHomeworkService(
        homework.homeworkId,
        submitData,
      );
      if (result) {
        alert('과제 제출이 완료되었습니다.');
        window.location.reload();
      }
      closeModal();
    } catch (e: any) {
      alert('과제 제출에 실패했습니다.');
    }
  };

  return (
    <ModalWrapper isShowing={isShowing} modalWidth={'4xl'}>
      {/* 과제 정보 */}
      <>
        <div className="w-full flex justify-center">
          <h3 className="text-2xl font-semibold pt-8">과제</h3>
        </div>
        <div className="w-full flex m-4 justify-center border border-primary rounded-lg">
          {/* 과제 이름 */}
          <h3 className="text-2xl font-semibold ">{homework.homeworkTitle}</h3>
        </div>
        {/* 출제된 과제 자료 */}
        <div className="m-4 w-full ">
          {attachments &&
          attachments.length > 0 &&
          attachments[0] &&
          attachments[0].fileName ? (
            <a
              href={downloadUrl}
              className={'flex pl-4 underline text-sm mb-2'}
              download={attachments[0].fileName}>
              {attachments[0].fileName}
            </a>
          ) : (
            <button className="flex pl-4 underline text-sm mb-2">
              {'첨부파일이 없습니다'}
            </button>
          )}
          <p className="text-lg pl-4 ">{homework.homeworkContent}</p>
        </div>
      </>

      <>
        {/* 내 제출 */}
        {homework.submitHomeworkId &&
          homework.submitHomeworkAt &&
          homework.submitHomeworkContent && (
            <>
              <div className="w-full px-4 my-4 pb-2 border-b border-gray-400">
                <h3 className="text-2xl font-bold text-gray-400">내 제출</h3>
              </div>
              <div className="w-full px-4 my-4 pb-2">
                <h1 className="text-2xl font-bold">
                  {homework.submitHomeworkTitle}
                </h1>
              </div>
              <div className="w-full flex flex-col mb-4">
                {homework.attachments[0] && homework.attachments[0].fileUrl ? (
                  <a
                    href={downloadSubmittedHomeworkUrl}
                    className={'flex pl-4 underline w'}
                    download={homework.attachments[0].fileName}>
                    {homework.attachments[0].fileName}
                  </a>
                ) : (
                  <></>
                )}

                <span className="text-sm text-gray-400 ml-auto">
                  {replaceDateFormat(
                    homework.submitHomeworkAt,
                    'YY.MM.DD(ddd) HH:MM',
                  )}
                </span>
                {homework.submitHomeworkScore === -1 ? (
                  <button
                    className="text-sm text-gray-400 ml-auto"
                    onClick={deleteHomework}>
                    삭제
                  </button>
                ) : (
                  <></>
                )}
              </div>

              <div className="w-full px-4 mb-8">
                {parseHtmlContentWithSanitize(homework.submitHomeworkContent)}
              </div>
            </>
          )}

        {/* 제출한 적 없으면 에디터 */}
        {!homework.submitHomeworkId ? (
          <div className="w-full px-4">
            <div className="mb-8">
              <h2 className="text-2xl font-semibold mr-4 mb-2">제목</h2>
              <input
                type="text"
                id="title"
                value={title}
                onChange={handleTitleInput}
                className="w-full"
              />
            </div>

            {/* 파일 첨부 */}
            <div className="mb-8">
              {attachedFile.fileName === '' ? (
                <button
                  onClick={onClickUploadFile}
                  className={
                    'underline border-[0.5px] border-transparent text-lg text-slate-700 my-1 mx-1 py-1.5 px-3.5 rounded hover:bg-slate-50 hover:text-slate-700'
                  }>
                  파일 업로드
                </button>
              ) : (
                <button
                  onClick={onClickDeleteAttachment}
                  className={
                    'underline border-[0.5px] border-transparent text-lg text-slate-700 my-1 mx-1 py-1.5 px-3.5 rounded hover:bg-slate-50 hover:text-slate-700'
                  }>
                  {fileName} &nbsp; 🅧
                </button>
              )}
            </div>

            {/* 내용 */}
            <div className="mb-8">
              <h2 className="text-2xl font-semibold mr-4 mb-2">내용</h2>
              <BoardContentEditor value={content} onChange={setContent} />
            </div>

            {/* 버튼 항목 */}
            <div className="w-full px-4 pb-2 flex justify-center mb-8">
              <SecondarySquareButton text="취소" onClick={closeModal} />
              <PrimarySquareButton onClick={submitHomework} text="제출하기" />
            </div>
          </div>
        ) : (
          <></>
        )}
      </>

      <>
        {/* 모달 닫기 */}
        {homework.submitHomeworkId ? (
          <div className="flex items-center my-8">
            <button
              type="submit"
              onClick={closeModal}
              className="w-28 text-sm md:text-base md:w-32 border border-transparent mt-4 py-2 bg-primary text-white rounded-full transition-all ease-in-out delay-50 hover:bg-white hover:border-primary hover:text-primary">
              확인
            </button>
          </div>
        ) : (
          <></>
        )}
      </>
    </ModalWrapper>
  );
}
