import '@/styles/globals.css';
import {QueryClient, QueryClientProvider} from 'react-query';
import {RecoilRoot} from 'recoil';
import Router from '@/components/router';
import {initializeAxios} from '@/utils/http-request';
import {ReactQueryDevtools} from 'react-query/devtools';

const queryClient = new QueryClient();

export const Api = initializeAxios();

function App() {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Router />
      </QueryClientProvider>
    </RecoilRoot>
  );
}
export default App;
