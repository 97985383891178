import {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';
import siteState from '@/stores/siteAtom';
import {getAcademies, getCurrentSiteService} from '@/services/siteService';
// Import pages components
import {Main} from '@/pages/main';
import {ServicePolicy, PrivacyPolicy} from '@/pages/policy';

import {PayResults} from '@/pages/pay-results';
import {Certificate} from '@/pages/certificate';
import {Course, CourseDetail, Homework} from '@/pages/course';
import {Lecture} from '@/pages/lecture';
import {Notice, NoticeDetail} from '@/pages/notice';
import {
  Question,
  QuestionDetail,
  QuestionCourse,
  QuestionCourseDetail,
  QuestionWrite,
} from '@/pages/question';
import {Register, RegisterDetail} from '@/pages/register';
import {
  SignIn,
  SignUp,
  UserFindEmail,
  UserFindPassword,
  UserUpdate,
  UserWithDraw,
} from '@/pages/user';
import {AskPage} from '@/pages/Ask';
import {NotFound} from '@/pages/NotFound';
// Import custom routes
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import {getDomain} from '@/utils/env';

function Router() {
  const setSiteState = useSetRecoilState(siteState);

  useEffect(() => {
    (async () => {
      let host = window.location.host;

      try {
        if (process.env.NODE_ENV === 'development') {
          host = 'dev.lecture-on.com';
        }
        const currentSite = await getCurrentSiteService(host);
        const academies = await getAcademies(currentSite.id);
        setSiteState({...currentSite, academies});
      } catch (e) {
        alert('등록된 사이트 정보가 없습니다.');
        location.replace(getDomain()); // 랜딩 페이지로 리다이렉트
      }
    })();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* 메인 페이지 */}
        <Route path="/" element={<Main />} />
        {/* 공지 페이지 */}
        <Route path="/notice" element={<Notice />} />
        <Route path="/notice/:id" element={<NoticeDetail />} />
        {/* 약관 페이지 */}
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/service" element={<ServicePolicy />} />
        {/* 서비스 문의 페이지 */}
        <Route path="/ask" element={<AskPage />} />
        {/* 문의 관련 */}
        <Route path="/question" element={<Question />} />
        <Route path="/question/service/:id" element={<QuestionDetail />} />
        <Route path="/question/course" element={<QuestionCourse />} />
        <Route path="/question/course/:id" element={<QuestionCourseDetail />} />
        {/* 수강 신청 */}
        <Route path="/register" element={<Register />} />
        <Route path="/register/:id" element={<RegisterDetail />} />
        {/* Public Routes */}
        <Route element={<PublicRoute />}>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/user/find/email" element={<UserFindEmail />} />
          <Route path="/user/find/password" element={<UserFindPassword />} />
        </Route>

        {/* Private Routes */}
        <Route element={<PrivateRoute />}>
          {/* 결제중 페이지 */}
          <Route path="/pay-results" element={<PayResults />} />
          {/* 수료증 페이지 */}
          <Route path="/certificate" element={<Certificate />} />
          {/* 유저  */}
          <Route path="/user/update" element={<UserUpdate />} />
          <Route path="/user/withdraw" element={<UserWithDraw />} />
          {/* 교육과정 */}
          <Route path="/course" element={<Course />} />
          <Route path="/course/:id" element={<CourseDetail />} />
          <Route path="/lecture/:courseId" element={<Lecture />} />
          <Route path="/homework/:courseId" element={<Homework />} />
          {/* 문의 관련 */}
          <Route path="/question/course/write" element={<QuestionWrite />} />
        </Route>
        {/* 404 페이지 라우팅 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
