import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';
import {
  MainHeader,
  MainBoards,
  MainCourses,
  MainFooter,
} from '@/components/main';
// import { MainCategory } from '@/components/main';
import {Layout} from '@/components/common';

export function Main() {
  const siteData = useRecoilValue(siteState);
  if (!siteData) return null;

  return (
    <Layout>
      <main>
        {/* 헤더 */}
        <MainHeader siteData={siteData} />
        {/* 메인 */}
        {/* 공지사항 & 문의하기 */}
        <MainBoards siteData={siteData} />
        {/* 인기있는 따끈따끈한 강의 리스트: 캐러셀 */}
        <MainCourses siteData={siteData} />
        {/* 수업 카테고리 */}
        {/* <MainCategory /> */}
        {/* 함께하는 분들 */}
        <MainFooter siteData={siteData} />
      </main>
    </Layout>
  );
}
