import ReactHtmlParser from 'react-html-parser';
import sanitize, {defaults} from 'sanitize-html';

export function parseJsonString(jsonString: string) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return {};
  }
}

export function parseArrayStringJson(jsonArrayString: string): Array<string> {
  try {
    return JSON.parse(jsonArrayString);
  } catch (e) {
    return [];
  }
}

export function parseHtmlContentWithSanitize(content: string) {
  return ReactHtmlParser(
    sanitize(content, {
      allowedTags: defaults.allowedTags.concat(['img']),
    }),
  );
}

export function parseSubDomain(host: string) {
  try {
    const splitHost = host.replace('.json', '').split('.');

    if (splitHost.length === 1) {
      return '';
    }
    if (splitHost.length === 2) {
      return splitHost[0] === 'lecture-on' ? '' : splitHost[0];
    }
    if (splitHost.length === 3) {
      return splitHost[0]
        .replace('www', '')
        .replace('https://', '')
        .replace('http://', '');
    }
    if (splitHost.length === 4) {
      return splitHost[1];
    }

    return '';
  } catch (e) {
    return '';
  }
}

export {};
