import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {
  Layout,
  TableData,
  TableHeader,
  TableRow,
  InputSearch,
  Pagination,
  Skeleton,
  TableRowEmpty,
} from '@/components/common';
import {useBoards} from '@/hooks/useBoards';
import siteState from '@/stores/siteAtom';
import {genTableIndex, replaceDateFormat} from '@/utils/format';
import {BoardList} from '@/types/boards.type';

export function Notice() {
  const navigate = useNavigate();
  const siteData = useRecoilValue(siteState);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');

  const {
    data: noticeData,
    isLoading,
    paginationInfo,
    onChangePage,
    refetch,
  } = useBoards({
    category: 'Notice',
    searchQuery,
  });

  function searchNoticeList(query: string) {
    if (!query) {
      navigate('/notice');
      return;
    }
    navigate(`/notice?search=${query}`);
  }

  useEffect(() => {
    refetch();
  }, [siteData, searchQuery]);

  return (
    <Layout>
      <div className="flex justify-center my-8">
        <h1 className="text-3xl font-semibold">공지사항</h1>
      </div>
      <div className="w-full flex flex-col items-center">
        <div className="w-[80%] max-w-[500px]">
          <InputSearch
            placeholder="검색어를 입력하세요."
            onSearch={searchNoticeList}
          />
        </div>
      </div>
      {searchQuery && (
        <div className="w-full px-4 mt-10">
          <h2 className="text-xl">
            <span>{`"`}</span>
            <span className="mx-2 text-primary">{searchQuery}</span>
            <span>{`" 검색결과`}</span>
          </h2>
        </div>
      )}
      <div className="w-full px-4 mt-10">
        <table className="w-full">
          <thead className="bg-primary bg-opacity-10">
            <TableRow header>
              <TableHeader>번호</TableHeader>
              <TableHeader>제목</TableHeader>
              <TableHeader>일시</TableHeader>
            </TableRow>
          </thead>
          <tbody className="border-b border-disabled">
            {!isLoading &&
              noticeData.length > 0 &&
              noticeData.map((item: BoardList, index: number) => {
                const rowIndex = genTableIndex(
                  paginationInfo.currentPage,
                  paginationInfo.size,
                  index,
                );
                return (
                  <TableRow
                    key={index}
                    addClass="cursor-pointer"
                    onClick={() =>
                      navigate(`/notice/${item.id}`, {state: item})
                    }>
                    <TableData>{rowIndex}</TableData>
                    <TableData addClass="font-bold text-start">
                      {item.title}
                    </TableData>
                    <TableData>
                      {replaceDateFormat(item.createdAt, 'YY.MM.DD(ddd) HH:MM')}
                    </TableData>
                  </TableRow>
                );
              })}
            {!isLoading && noticeData.length === 0 && (
              <TableRowEmpty text="검색 결과 없음" />
            )}
            {isLoading && <Skeleton />}
          </tbody>
        </table>
      </div>
      <div className="my-8">
        <Pagination
          totalCount={paginationInfo.total}
          pageSize={paginationInfo.size}
          currentPage={paginationInfo.currentPage}
          totalPage={paginationInfo.totalPages}
          onClick={onChangePage}
        />
      </div>
    </Layout>
  );
}
