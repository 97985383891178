import {useState, ChangeEvent, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';
import {Layout, Input} from '@/components/common';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import {IoCaretDown} from 'react-icons/io5';
import {
  ValidateEmailSchema,
  ValidateSignUpFormSchema,
  ValidateSignUpAdditionalFormSchema,
} from '@/utils/validator';
import {encodeHyphenPhoneNumber, decodeHyphenPhoneNumber} from '@/utils/format';
import {
  checkIsDuplicatedEmailService,
  requestSmsCodeService,
  validateSmsCodeService,
  signUpService,
  signInService,
  validateAuthService,
} from '@/services/userService';
import useUser from '@/hooks/useUser';
import Modal from '@/components/common/Modal';
import useModal from '@/hooks/useModal';
import {useBoards} from '@/hooks/useBoards';
import {useSitePreference} from '@/hooks/useSitePreference';

export function SignUp() {
  const navigate = useNavigate();
  const siteData = useRecoilValue(siteState);
  const preference = useSitePreference({siteId: siteData?.id ?? ''});

  const {isShowing, closeModal, modalData, openModal} = useModal();
  const {refreshUserState} = useUser();

  const logo = siteData?.logo;

  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    rePassword: '',
    isAuthPhone: false,
    acceptPrivacyPolicy: false,
    acceptServicePolicy: false,
    birthDate: '',
    gender: '',
  });
  const [verificationForm, setVerificationForm] = useState({
    phone: '',
    telecom: '', // KT, SKT
    birthDate: '',
    name: '',
    gender: '',
  });
  const [needAdditionalForm, setNeedAdditionalForm] = useState(false);
  const [code, setCode] = useState('');
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isSentCode, setIsSentCode] = useState(false);
  const [isPhoneChecked, setIsPhoneChecked] = useState(false);
  const [authId, setAuthId] = useState('');
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [formCaption, setFormCaption] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    rePassword: '',
    isAuthPhone: '',
    acceptPrivacyPolicy: '',
    acceptServicePolicy: '',
    birthDate: '',
    gender: '',
    telecom: '',
  });
  const telecoms: any[] = [
    {id: 'SKT', name: 'SKT', value: 'SKT'},
    {id: 'KT', name: 'KT', value: 'KT'},
    {id: 'LGT', name: 'LG U+', value: 'LG U+'},
  ];
  const [viewDropdown, setViewDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const {
    data: servicePolicyData,
    isLoading: isLoadingServicePolicy,
    refetch: refetchServicePolicy,
  } = useBoards({
    category: 'SERVICE_POLICY',
  });
  const {
    data: privacyPolicyData,
    isLoading: isLoadingPrivacyPolicy,
    refetch: refetchPrivacyPolicy,
  } = useBoards({
    category: 'PRIVACY_POLICY',
  });
  useEffect(() => {
    refetchServicePolicy();
    refetchPrivacyPolicy();
  }, [siteData]);

  useEffect(() => {
    if (!preference) return;
    if (preference.sitePreference?.authVerification === true) {
      setNeedAdditionalForm(true);
    }
  }, [preference]);

  function handleForm(e: ChangeEvent<HTMLInputElement>) {
    const {value, id} = e.target;
    let newValue;
    let newVerificationValue;
    if (id === 'phone') {
      newValue = {...form, phone: encodeHyphenPhoneNumber(value)};
      newVerificationValue = {
        ...verificationForm,
        phone: value,
      };
    } else {
      newValue = {...form, [id]: value};
      newVerificationValue = {...verificationForm, [id]: value};
    }

    setForm(newValue);
    setVerificationForm(newVerificationValue);

    // validation
    try {
      if (needAdditionalForm) {
        ValidateSignUpAdditionalFormSchema.validateSyncAt(id, {
          ...newValue,
          phone: decodeHyphenPhoneNumber(newValue.phone),
        });
      } else {
        ValidateSignUpFormSchema.validateSyncAt(id, {
          ...newValue,
          phone: decodeHyphenPhoneNumber(newValue.phone),
        });
      }

      if (id === 'name' && value === '')
        throw new Error('이름을 입력해주세요.');
      setFormCaption({...formCaption, [id]: ''});
    } catch (e: any) {
      setFormCaption({...formCaption, [id]: e.message});
    }
  }

  function handleGender(e: ChangeEvent<HTMLInputElement>) {
    const {id} = e.target;
    const newValue = {...form, gender: id};
    const newVerivicationValue = {...verificationForm, gender: id};
    setForm(newValue);
    setVerificationForm(newVerivicationValue);
  }

  async function checkEmailDuplicated() {
    if (formCaption.email) {
      setFormCaption({
        ...formCaption,
        email: '다른 이메일을 입력해주세요',
      });
      return;
    }

    const email = form.email;
    try {
      // 이메일 포맷 확인
      ValidateEmailSchema.validateSync(email);
      // 이메일 중복 여부 확인 요청
      const res = await checkIsDuplicatedEmailService(
        siteData?.id || '',
        email,
      );
      if (!res) {
        setFormCaption({
          ...formCaption,
          email: '이미 가입된 이메일입니다.',
        });
        return;
      }
      // 이메일 중복 확인 완료
      setIsEmailChecked(true);
    } catch (e: any) {
      openModal({
        type: 'error',
        message: '이메일을 확인해주세요.',
      });
    }
  }

  async function requestSmsCode() {
    if (!siteData?.id)
      return openModal({
        type: 'error',
        message: `사이트 정보가 없습니다.\n재접속 해주세요.`,
      });

    // 인증 완료 상태일 경우
    if (isPhoneChecked) return;

    // 핸드폰 포맷 확인
    try {
      ValidateSignUpFormSchema.validateSyncAt('phone', {
        phone: decodeHyphenPhoneNumber(form.phone),
      });
    } catch (err: any) {
      openModal({
        type: 'error',
        message: err.message,
      });
      return;
    }

    let authVerificationResult;
    if (needAdditionalForm) {
      if (!verificationForm.name) {
        openModal({
          type: 'error',
          message: '이름을 입력해주세요.',
        });
        return;
      }
      if (!verificationForm.birthDate) {
        openModal({
          type: 'error',
          message: '생년월일을 입력해주세요.',
        });
        return;
      }
      if (!verificationForm.gender) {
        openModal({
          type: 'error',
          message: '성별을 선택해주세요.',
        });
        return;
      }
      if (!verificationForm.telecom) {
        openModal({
          type: 'error',
          message: '통신사를 선택해주세요.',
        });
        return;
      }
      if (!verificationForm.phone) {
        openModal({
          type: 'error',
          message: '이름을 입력해주세요.',
        });
        return;
      }

      try {
        // 명의 인증 요청
        authVerificationResult = await validateAuthService(
          siteData.id,
          verificationForm.phone,
          verificationForm.telecom,
          verificationForm.birthDate,
          verificationForm.name,
          verificationForm.gender,
        );
      } catch (e: any) {
        openModal({
          type: 'error',
          message: '본인 인증에 실패했습니다. 개인 정보를 확인해주세요.',
        });
      }
    }
    if (!needAdditionalForm || authVerificationResult) {
      try {
        // 인증번호 발송 요청
        await requestSmsCodeService(
          siteData!.id,
          'SIGN_UP',
          decodeHyphenPhoneNumber(form.phone),
        );
        // 인증번호 발송 후 폰 캡션 제거
        setFormCaption({...formCaption, phone: ''});
        // 인증번호 발송 여부 갱신
        setIsSentCode(true);
        openModal({
          type: 'check',
          message: '인증 문자가 발송되었습니다.',
        });
      } catch (e: any) {
        setFormCaption({
          ...formCaption,
          phone: e.data.error.message,
        });
      }
    }
  }

  async function validateSmsCode() {
    if (form.isAuthPhone) return; // 이미 인증 완료 시

    try {
      const phoneNumber = decodeHyphenPhoneNumber(form.phone);
      const res = await validateSmsCodeService(
        siteData!.id,
        'SIGN_UP',
        phoneNumber,
        code,
      );
      setAuthId(res);

      setForm({...form, isAuthPhone: true});

      setIsPhoneChecked(true);
    } catch (e: any) {
      openModal({
        type: 'error',
        message: e.data.error.message,
      });
    }
  }

  async function handleSignUp() {
    if (!isEmailChecked) {
      openModal({
        type: 'error',
        message: '이메일 중복 확인을 해주세요.',
      });
      return;
    }

    if (!form.password) {
      openModal({
        type: 'error',
        message: '비밀번호를 입력해주세요.',
      });
      return;
    }

    if (!form.rePassword) {
      openModal({
        type: 'error',
        message: '비밀번호 확인을 입력해주세요.',
      });
      return;
    }

    if (!form.name) {
      openModal({
        type: 'error',
        message: '이름을 입력해주세요.',
      });
      return;
    }

    if (!form.isAuthPhone) {
      openModal({
        type: 'error',
        message: '휴대폰 인증을 진행해주세요.',
      });
      return;
    }

    try {
      ValidateSignUpFormSchema.validateSync({
        ...form,
        phone: decodeHyphenPhoneNumber(form.phone),
      });
    } catch (e: any) {
      openModal({
        type: 'error',
        message: e.message,
      });
      return;
    }

    try {
      await signUpService({
        authId,
        siteId: siteData!.id,
        name: form.name,
        gender: form.gender,
        birthDate: form.birthDate,
        email: form.email,
        phone: decodeHyphenPhoneNumber(form.phone),
        password: form.password,
      });

      openModal({
        type: 'check',
        title: '회원가입 성공',
        message: '회원가입이 완료되었습니다.',
        buttons: [
          {
            text: '확인',
            style: 'normal',
            onClick: () => {
              closeModal();
              // 자동 로그인 및 메인 페이지 이동
              autoSignIn();
            },
          },
        ],
      });
    } catch (e: any) {
      openModal({
        type: 'error',
        message: e.data.error.message,
      });
    }
  }

  async function autoSignIn() {
    await signInService(siteData?.id ?? '', form.email, form.password);
    await refreshUserState();
    navigate('/');
  }

  function popupServicePolicy() {
    if (form.acceptServicePolicy === false && !isLoadingServicePolicy) {
      openModal({
        type: 'policy',
        title: servicePolicyData[0].title,
        message: servicePolicyData[0].content,
        buttons: [
          {
            text: '동의',
            style: 'normal',
            onClick: () => {
              // 동의 시
              setForm(prev => ({
                ...prev,
                acceptServicePolicy: true,
              }));
              closeModal();
            },
          },
          {
            text: '취소',
            style: 'negative',
            onClick: () => {
              closeModal();
            },
          },
        ],
      });

      return;
    } else {
      // 체크 해제
      setForm(prev => ({
        ...prev,
        acceptServicePolicy: false,
      }));
    }
  }

  function popupPrivacyPolicy() {
    if (form.acceptPrivacyPolicy === false && !isLoadingPrivacyPolicy) {
      openModal({
        type: 'policy',
        title: privacyPolicyData[0].title,
        message: privacyPolicyData[0].content,
        buttons: [
          {
            text: '동의',
            style: 'normal',
            onClick: () => {
              // 동의 시
              setForm(prev => ({
                ...prev,
                acceptPrivacyPolicy: true,
              }));
              closeModal();
            },
          },
          {
            text: '취소',
            style: 'negative',
            onClick: () => {
              closeModal();
            },
          },
        ],
      });

      return;
    } else {
      // 체크 해제
      setForm(prev => ({
        ...prev,
        acceptPrivacyPolicy: false,
      }));
    }
  }

  /**
   * TODO: 쿼리스트링에 저장된 url이 있을 시 로그인 성공 이후 해당 페이지로 리다이렉트
   */
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setViewDropdown(!viewDropdown);
  };
  const handleItemClick = (id: string, value: string) => {
    setSelectedItem(value);
    setViewDropdown(false);
    const newVerificationValue = {...verificationForm, telecom: id};
    setVerificationForm(newVerificationValue);
  };

  return (
    <Layout isHiddenFooter isSignHeader>
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
      <div className="w-full h-full mb-0 flex flex-col items-center py-12">
        <div className="w-full max-w-[500px] flex flex-col items-center">
          {/* 기관 로고 */}
          {logo && (
            <img
              src={logo}
              alt="기관 로고"
              className="w-[200px] md:w-[215px] lg:w-[230px]"
            />
          )}
          {/* 회원가입 폼 */}
          <form action="" className="w-full flex flex-col px-8 my-10">
            {/* 로그인 정보 */}
            <span className={'px-4 font-bold'}>로그인 정보</span>
            <Input
              id="email"
              type="email"
              placeholder="이메일"
              value={form.email}
              onChange={handleForm}
              addClass={
                isEmailChecked ? 'bg-readOnly cursor-default' : 'bg-white'
              }
              onKeyDown={event => {
                if (event.keyCode === 13) checkEmailDuplicated();
              }}
              rightIcon={
                <span
                  onClick={!isEmailChecked ? checkEmailDuplicated : () => ({})}
                  className={`w-auto whitespace-nowrap px-3 py-1 border border-secondary bg-transparent text-center text-secondary font-semibold text-sm w-[50px] transition-all duration-150 rounded-full
                  ${
                    isEmailChecked
                      ? 'button-success cursor-default'
                      : 'cursor-pointer hover:bg-secondary hover:text-white'
                  }
                  `}>
                  {isEmailChecked ? '사용 가능' : '중복 확인'}
                </span>
              }
            />
            {/* 이메일 */}
            {formCaption.email && (
              <p className="text-red-500 w-full text-sm pl-8">
                {formCaption.email}
              </p>
            )}
            {/* 비밀번호 */}
            <Input
              id="password"
              type={visiblePassword ? 'text' : 'password'}
              placeholder="비밀번호"
              value={form.password}
              onChange={handleForm}
              rightIcon={
                visiblePassword ? (
                  <AiOutlineEye
                    className="cursor-pointer text-2xl"
                    onClick={() => setVisiblePassword(prev => !prev)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="cursor-pointer text-xl md:text-2xl"
                    onClick={() => setVisiblePassword(prev => !prev)}
                  />
                )
              }
            />
            {formCaption.password && (
              <p className="text-red-500 w-full text-sm pl-8">
                {formCaption.password}
              </p>
            )}
            {/* 비밀번호 확인 */}
            <Input
              id="rePassword"
              type={visiblePassword ? 'text' : 'password'}
              placeholder="비밀번호 확인"
              value={form.rePassword}
              onChange={handleForm}
            />
            {formCaption.rePassword && (
              <p className="text-red-500 w-full text-sm pl-8">
                {formCaption.rePassword}
              </p>
            )}
            {/* 회원 정보 */}
            <span className={'px-4 mt-[20px] font-bold'}>회원 정보</span>
            {/* 이름 */}
            <Input
              id="name"
              type="text"
              placeholder="이름"
              value={form.name}
              onChange={handleForm}
            />
            {formCaption.name && (
              <p className="text-red-500 w-full text-sm pl-8">
                {formCaption.name}
              </p>
            )}
            {/* 폼 추가(생년월일, 성별, 통신사, 휴대폰번호) */}
            {/* 생년월일 */}
            {needAdditionalForm && (
              <>
                <div className={'flex justify-around'}>
                  <div className={'flex flex-col w-full mr-4'}>
                    <Input
                      id="birthDate"
                      type="text"
                      placeholder="생년월일 (YYYYMMDD)"
                      value={form.birthDate}
                      onChange={handleForm}
                      addClass={'bg-white'}
                    />
                  </div>

                  <div
                    className={`relative w-[40%] h-[44px] md:h-[46px] lg:h-[48px] flex justify-center items-center button-shadow rounded-full px-4 my-2 border border-transparent bg-white`}>
                    <div
                      className={
                        'flex justify-center items-center justify-items-center mx-2'
                      }>
                      <input
                        id="M"
                        type="radio"
                        name="gender"
                        value={form.gender}
                        onChange={handleGender}
                        className={'mr-2'}
                      />
                      <label htmlFor="M" className={'whitespace-nowrap'}>
                        남성
                      </label>
                      {formCaption.gender && (
                        <p className="text-red-500 text-sm pl-8">
                          {formCaption.gender}
                        </p>
                      )}
                    </div>
                    <div className={'flex justify-center items-center mx-2'}>
                      <input
                        id="F"
                        type="radio"
                        name="gender"
                        value={form.gender}
                        onChange={handleGender}
                        className={'mr-2'}
                      />
                      <label htmlFor="F" className={'whitespace-nowrap'}>
                        여성
                      </label>
                      {formCaption.gender && (
                        <p className="text-red-500 text-sm pl-8">
                          {formCaption.gender}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {formCaption.birthDate && (
                  <p className="text-red-500 w-full text-sm pl-8">
                    {formCaption.birthDate}
                  </p>
                )}
              </>
            )}
            {/* 휴대폰 */}
            <div className={'flex justify-between'}>
              {/* 통신사 */}
              {needAdditionalForm && (
                <div
                  className={
                    'relative w-[130px] h-[44px] md:h-[46px] lg:h-[48px] flex flex-col items-center button-shadow rounded-full px-4 my-2 border border-transparent justify-center justify-items-center mr-4 whitespace-nowrap bg-white'
                  }>
                  <button
                    className={
                      'w-[120px] flex justify-center justify-items-center items-center border border-transparent bg-white px-4'
                    }
                    onClick={handleButtonClick}>
                    <p className={'mr-1 text-lg'}>
                      {selectedItem ? selectedItem : '통신사'}
                    </p>
                    <IoCaretDown
                      className={'text-slate-600 text-sm react-icons'}
                    />
                  </button>
                  {viewDropdown && (
                    <div
                      className={
                        'absolute w-full min-h-[32px] max-h-[160px] top-[45px] left-0 flex flex-col bg-white border border-slate-100 rounded-[25%] z-[3000]'
                      }>
                      {telecoms.map(telecom => (
                        <button
                          className={'h-[44px]'}
                          key={telecom.value}
                          onClick={() =>
                            handleItemClick(telecom.id, telecom.value)
                          }>
                          {telecom.name}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {/* 휴대폰 번호 */}
              <div className={'flex flex-col w-full'}>
                <Input
                  id="phone"
                  type="text"
                  placeholder="휴대폰 번호"
                  value={form.phone}
                  maxLength={13}
                  onChange={handleForm}
                  onKeyDown={event => {
                    if (event.keyCode === 13) requestSmsCode();
                  }}
                  addClass={
                    isPhoneChecked ? 'bg-readOnly cursor-default' : 'bg-white'
                  }
                  rightIcon={
                    isPhoneChecked && (
                      <span
                        onClick={
                          isSentCode && isPhoneChecked
                            ? undefined
                            : requestSmsCode
                        }
                        className={`w-auto whitespace-nowrap px-3 py-1 cursor-pointer border border-secondary bg-transparent text-center text-secondary font-semibold text-sm w-[50px] transition-all duration-150 rounded-full ${
                          isPhoneChecked
                            ? 'button-success cursor-default'
                            : 'hover:bg-secondary hover:text-white'
                        }`}>
                        인증 완료
                      </span>
                    )
                  }
                />
                {formCaption.phone && (
                  <p className="text-red-500 w-full text-sm pl-8">
                    {formCaption.phone}
                  </p>
                )}
              </div>
            </div>
            {/* 인증번호 */}
            <>
              <Input
                id="code"
                type="text"
                placeholder="인증번호"
                value={code}
                onChange={e => setCode(e.target.value)}
                onKeyDown={event => {
                  if (event.keyCode === 13) validateSmsCode();
                }}
                addClass={
                  isPhoneChecked ? 'bg-readOnly cursor-default' : 'bg-white'
                }
                rightIcon={
                  <>
                    {isSentCode && !isPhoneChecked && (
                      <span
                        onClick={validateSmsCode}
                        className={`w-auto whitespace-nowrap px-3 py-1 mr-2 cursor-pointer border border-secondary bg-transparent text-center text-secondary font-semibold text-sm w-[50px] transition-all duration-150 rounded-full ${
                          isPhoneChecked
                            ? 'button-success'
                            : 'hover:bg-secondary hover:text-white'
                        }`}>
                        확인
                      </span>
                    )}
                    {!isSentCode ? (
                      <span
                        onClick={requestSmsCode} // 폰명의 체크 api 호출, 번호 유효하면 isPhoneChecked true
                        className={`w-auto whitespace-nowrap px-3 py-1 mr-2 cursor-pointer border border-secondary bg-transparent text-center text-secondary font-semibold text-sm w-[50px] transition-all duration-150 rounded-full`}>
                        전송
                      </span>
                    ) : (
                      <span
                        onClick={requestSmsCode}
                        className={`w-auto whitespace-nowrap px-3 py-1 mr-2 cursor-pointer border border-secondary bg-transparent text-center text-secondary font-semibold text-sm w-[50px] transition-all duration-150 rounded-full ${
                          isPhoneChecked
                            ? 'hidden'
                            : 'hover:bg-secondary hover:text-white'
                        }`}>
                        재전송
                      </span>
                    )}
                  </>
                }
              />
              {code && <p className="text-red-500 w-full text-sm pl-8"></p>}
            </>
            <div className={'flex flex-row justify-center'}>
              <div className="my-4 mx-4">
                <input
                  id="privacy_policy"
                  type="checkbox"
                  checked={form.acceptPrivacyPolicy}
                  onChange={popupPrivacyPolicy}
                  className="focus:outline-none focus:ring-0 mx-1"
                />
                <label
                  htmlFor="privacy_policy"
                  className="no-drag inline-block pl-[0.15rem] hover:cursor-pointer text-sm">
                  개인정보처리방침 동의
                </label>
              </div>
              <div className="my-4">
                <input
                  id="service_policy"
                  type="checkbox"
                  checked={form.acceptServicePolicy}
                  onChange={popupServicePolicy}
                  className="focus:outline-none focus:ring-0 mx-1"
                />
                <label
                  htmlFor="service_policy"
                  className="no-drag inline-block pl-[0.15rem] hover:cursor-pointer text-sm">
                  서비스이용약관 동의
                </label>
              </div>
            </div>
            <div className={'flex justify-center'}>
              <div
                onClick={handleSignUp}
                className="text-center text-sm md:text-base md:w-32 border border-transparent mt-4 py-2 bg-primary text-white rounded-full transition-all ease-in-out delay-50 hover:bg-white hover:border-primary hover:text-primary cursor-pointer">
                회원가입
              </div>
            </div>
          </form>
          {/* 로그인 관련 페이지 이동 버튼 */}
          <div className="w-full flex justify-center space-x-4">
            <p className="text-sm text-gray-500">
              이미 가입한 계정이 있으신가요?
            </p>
            <Link
              to="/sign-in"
              className="text-sm font-semibold text-gray-500 underline text-primary">
              로그인
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
