import axios, {AxiosHeaders, AxiosInstance} from 'axios';
import {isClient} from '@/utils/common';
import {getEndpoint} from '@/utils/env';

function isAuthError(statusCode: number, errorCode: string) {
  if (statusCode === 401) {
    return true;
  }

  switch (errorCode) {
    case '0401':
    case '1000':
    case '1010':
    case '1011':
    case '1012':
    case '1013':
    case '1041':
    case '1043':
      return true;
    default:
      return false;
  }
}

async function refreshToken(token: string) {
  if (!token) {
    throw new Error('인증 정보가 존재하지 않습니다.');
  }

  const axiosInstance = axios.create({
    baseURL: getEndpoint(),
    headers: {},
  });

  const response = await axiosInstance.post('/users/refresh-token', {
    refreshToken: token,
  });

  const {accessToken: nextAccessToken, refreshToken: nextRefreshToken} =
    response.data.data;

  localStorage.setItem('at', nextAccessToken);
  localStorage.setItem('rt', nextRefreshToken);

  return nextAccessToken;
}

export function initializeAxios(): AxiosInstance {
  const baseUrl = getEndpoint();
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {},
  });

  axiosInstance.interceptors.request.use(
    config => {
      config.headers = (config.headers ?? {}) as AxiosHeaders;
      if (!config.headers.get('Authorization') && isClient()) {
        const accessToken = localStorage.getItem('at');

        if (accessToken) {
          config.headers.set('Authorization', `Bearer ${accessToken}`);
        }
      }

      return config;
    },
    error => {
      console.log(error);
    },
  );

  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      console.error(error);
      const statusCode = error.response?.status;
      const errorCode = error.response?.data?.error?.code;

      if (isAuthError(statusCode, errorCode) && isClient()) {
        const rt = localStorage.getItem('rt');
        if (!rt) {
          throw new Error();
        }

        return refreshToken(rt)
          .then(token => {
            const originRequest = error.config;
            originRequest.headers['Authorization'] = `Bearer ${token}`;
            const axiosInstance = axios.create({
              baseURL: getEndpoint(),
              headers: {},
            });
            return axiosInstance.request(originRequest);
          })
          .catch(e => {
            console.error(e);
            localStorage.removeItem('at');
            localStorage.removeItem('rt');
            window.location.href = '/sign-in';
          });
      }
      throw error.response;
    },
  );

  return axiosInstance;
}
