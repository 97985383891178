import {Navigate, Outlet} from 'react-router-dom';

// 로그인 상태일 때 접근 제한
function PublicRoute() {
  const accessToken = localStorage.getItem('at');
  const isLoggedIn = !!accessToken;

  return isLoggedIn ? <Navigate to="/" /> : <Outlet />;
}

export default PublicRoute;
