import {Api} from '@/App';
import {Attachment} from '@/types/common.type';
import {
  ReqGetProgressParams,
  ReqGetCourseListParams,
  ReqGetXProgressParams,
  ReqGetCourseProgressParams,
} from '@/types/course.type';

export function reqGetCourseList(params: ReqGetCourseListParams) {
  return Api.get('/courses', {
    params,
  });
}

export function reqGetCourseDetail(courseId: string, userId?: string) {
  if (userId) return Api.get(`/courses/${courseId}?userId=${userId}`);
  else return Api.get(`/courses/${courseId}`);
}

export function reqApplyCourse(courseId: string, attachments?: Attachment[]) {
  return Api.post(`/courses/${courseId}/apply`, {attachments: attachments});
}

export function reqApplyKacwCourse(
  courseId: string,
  attachments?: Attachment[],
) {
  return Api.post(`/kacw/courses/${courseId}/apply`, {
    attachments: attachments,
  });
}

export function reqGetProgress({
  type,
  typeId,
  siteId,
  userId,
}: ReqGetProgressParams) {
  return Api.get('/progresses', {
    params: {
      type,
      typeId,
      siteId,
      userId,
    },
  });
}

export function reqGetXProgress({
  xType,
  siteId,
  courseId,
}: ReqGetXProgressParams) {
  return Api.get('/progresses', {
    params: {
      type: xType,
      siteId,
      uid: courseId,
    },
  });
}

export function reqGetLectureList(courseId: string) {
  return Api.get(`/courses/${courseId}/lectures`);
}

// TODO: Pagination 로직 개선하기
export function reqGetChapterList(lectureId: string) {
  return Api.get(`/lectures/${lectureId}/chapters`, {
    params: {
      pageSize: 1000,
      page: 1,
    },
  });
}

export function reqGetCourseProgress({
  type,
  typeId,
  siteId,
  userId,
}: ReqGetCourseProgressParams) {
  return Api.get('/courses/progresses', {
    params: {
      type,
      typeId,
      siteId,
      userId,
    },
  });
}

export interface SubmitHomeworkParams {
  title: string;
  content: string;
  attachments?: Attachment[];
}

export function reqUploadHomework(
  homeworkId: string,
  homework: SubmitHomeworkParams,
) {
  return Api.post(`/homeworks/${homeworkId}/submit`, {
    title: homework.title,
    content: homework.content,
    attachments: homework.attachments,
  });
}
