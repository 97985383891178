import {useEffect} from 'react';
import {parseJwt} from '@/utils/token';
import {useRecoilState, useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';
import userState from '@/stores/userAtom';
import {searchUserInfoService} from '@/services/userService';

export default function useUser() {
  const site = useRecoilValue(siteState);
  const [user, setUser] = useRecoilState(userState);

  async function refreshUserState() {
    const accessToken = localStorage.getItem('at');
    if (!accessToken || !site?.id) return;

    const {userId} = parseJwt(accessToken).payloadObj as {
      userId: string;
    };

    const newUserInfo = await searchUserInfoService(site.id, userId);

    setUser(newUserInfo);
  }

  useEffect(() => {
    (async () => {
      refreshUserState();
    })();
  }, [site?.id]);

  return {
    user,
    isLoggedIn: user?.status !== null,
    refreshUserState,
  };
}
