import {InputHTMLAttributes, ReactNode} from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  caption?: ReactNode;
  addClass?: string;
}

export function Input({leftIcon, rightIcon, addClass, ...props}: InputProps) {
  return (
    <div
      className={`relative w-full h-[44px] md:h-[46px] lg:h-[48px] flex items-center button-shadow rounded-full px-4 my-2 border border-transparent ${addClass}`}>
      {leftIcon}
      <input
        {...props}
        className="w-full bg-transparent text-black appearance-none border-none focus:ring-0 text-lg"
      />
      {rightIcon}
    </div>
  );
}
