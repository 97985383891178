import {useRef, useState, useEffect} from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import videojs, {VideoJsPlayer} from 'video.js';
import moment from 'moment';
import {
  searchChapterService,
  savePLayTimeHistoryService,
} from '@/services/chapterService';
import {Layout} from '@/components/common';
import {useChapter} from '@/hooks/useChapter';
import XhrOptions = videojs.XhrOptions;
import {Chapter} from '@/types/chapter.type';
import {getEndpoint, getPlaySaveInterval} from '@/utils/env';

export function Lecture() {
  const navigate = useNavigate();
  const location = useLocation();

  const {courseId: rawCourseId} = useParams<{courseId?: string}>();
  const courseId = rawCourseId ?? '"';
  const {chapterList} = useChapter(courseId);

  // Get now chapter id from query parameter in URL
  const params = new URLSearchParams(location.search);
  const chapterId = params.get('chapter');
  let streamKey = '';
  const [testStreamKey, setTestStreamKey] = useState<string>('');
  // Create a Ref object to bind with the video element
  const videoNode = useRef<HTMLVideoElement>(null);

  const [player, setPlayer] = useState<VideoJsPlayer | null>(null);
  const [playTimeHandlerId, setPlayTimeHandlerId] = useState<number | null>(
    null,
  );

  const getStreamKey = async () => {
    if (!chapterId) {
      return;
    }
    try {
      const chapter = await searchChapterService(chapterId);
      streamKey = chapter.streamKey;
      setTestStreamKey(chapter.streamKey);
      return chapter.streamKey;
    } catch (e) {
      // console.log(e)
    }
  };

  function initVideoEvents() {
    const handlerId = setInterval(playTimeHandler, getPlaySaveInterval());
    setPlayTimeHandlerId(Number(handlerId));
  }

  function handleChapterClick(clickedChapter: string) {
    if (clickedChapter === chapterId) {
      navigate(`/lecture/${courseId}`);
      return;
    }
    navigate(`/lecture/${courseId}?chapter=${clickedChapter}`);
  }

  async function playTimeHandler() {
    if (!videoNode.current) {
      return;
    }
    const currentTime = videoNode.current?.currentTime;
    const eventType = videoNode.current?.ended
      ? 'FINISH'
      : videoNode.current?.paused
      ? 'PAUSE'
      : 'PLAY';
    await savePLayTimeHistoryService(chapterId, eventType, currentTime);
  }

  useEffect(() => {
    if (!courseId) {
      alert(
        '일시적인 오류가 발생했습니다. 새로고침을 진행해도 오류가 발생하면 관리자에게 문의해주세요.',
      );
      navigate('/course');
    }
  }, [courseId]);

  useEffect(() => {
    getStreamKey();
  }, [chapterId]);

  useEffect(() => {
    if (playTimeHandlerId) {
      clearInterval(Number(playTimeHandlerId));
      setPlayTimeHandlerId(null);
    }

    if (player) {
      player.dispose();
    }

    if (chapterId === '' || !videoNode.current) {
      setPlayer(null);
      return;
    }

    videojs.Vhs.xhr.beforeRequest = (options: XhrOptions) => {
      return {
        ...options,
        headers: {
          ...options.headers,
          'Request-Time': moment().format('X'),
          'Stream-Key': `${streamKey}`,
          'Chapter-Id': `${chapterId}`,
          Authorization: `Bearer ${localStorage.getItem('at')}`,
        },
      };
    };

    const nextPlayer = videojs(
      videoNode.current,
      {
        html5: {
          vhs: {
            overrideNative: true,
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false,
        },
        sources: [],
      },
      async () => {
        if (!chapterId) {
          return;
        }
        try {
          const chapter = await searchChapterService(chapterId);
          streamKey = chapter.streamKey;
          nextPlayer.src(chapter.url as string);
          initVideoEvents();
        } catch (e) {
          console.log(e);
        }
      },
    );
    setPlayer(nextPlayer);

    return () => {
      if (chapterId) {
        clearInterval(chapterId);
      }
    };
  }, [chapterId]);

  const renderContent = (item: Chapter) => {
    if (item.url.endsWith('.html')) {
      const htmlUrl =
        item.url +
        `?streamKey=${testStreamKey}&token=${
          localStorage.getItem('at') || ''
        }&chapterId=${item.id}&callback_url=${getEndpoint()}`;
      return (
        <div
          className={'w-full flex flex-col items-center'}
          style={{
            height: '100%',
          }}>
          <iframe
            style={{
              width: '1024px',
              height: '576px',
            }}
            src={htmlUrl}
            title="Korean Content"
            className="full-size-iframe"
            allow={'autoplay; fullscreen; encrypted-media;'}></iframe>
        </div>
      );
    } else if (
      item.url.endsWith('.m3u8') ||
      item.url.endsWith('.mp4') ||
      item.url.endsWith('.ts')
    ) {
      return (
        <div>
          <video
            ref={videoNode}
            id={`video-${item.id}`}
            className="vjs-lecture-on video-js vjs-default-skin vjs-big-play-centered vjs-16-9"
            controls
            controlsList={'nodownload'}
            playsInline
          />
        </div>
      );
    } else {
      return <>강의 주소가 잘못되었습니다.</>;
    }
  };

  return (
    <Layout>
      <div className="flex justify-center my-4">
        <h1 className="text-3xl font-semibold">강의</h1>
      </div>
      <div className="w-full px-4 mt-10 cursor-pointer ">
        {chapterList &&
          chapterList.map((item: Chapter) => (
            <div
              key={item.id}
              className={`flex between rounded-lg p-4 my-1 hover:bg-[#fafafa] ${
                item.id === chapterId ? 'flex-col items-center' : ''
              }`}
              onClick={() => {
                handleChapterClick(item.id);
              }}>
              {item.id === chapterId ? (
                <div className="w-full flex flex-col items-center">
                  {/* selected */}
                  <div
                    className="cursor-default w-[95%]"
                    onClick={e => {
                      e.stopPropagation();
                    }}>
                    {renderContent(item)}
                  </div>
                  <h2 className="w-full flex-start p-4 text-xl md:text-2xl font-bold text-primary">
                    {item.name}
                  </h2>
                  {!item.url ? (
                    <p className={'w-full flex-start py-2 px-5'}>
                      등록된 강의가 없습니다.
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <>
                  {/* non selected */}
                  <div className="w-full flex items-center space-x-8">
                    {item.thumbnailUrl ? (
                      <img
                        src={item.thumbnailUrl}
                        alt={item.description}
                        className="w-[30%]"
                      />
                    ) : (
                      <div className="w-[30%]"></div>
                    )}
                    <div className="flex items-center">
                      <span className="xs:text-sm lg:text-lg font-semibold">
                        {item.name}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <span>{item.progressRate ?? '0'}%</span>
                  </div>
                </>
              )}
            </div>
          ))}
        {chapterList && chapterList.length === 0 ? (
          <div className="w-full flex justify-center p-4 text-xl md:text-2xl  ">
            등록된 강의가 없습니다.
          </div>
        ) : (
          ''
        )}
      </div>
    </Layout>
  );
}
