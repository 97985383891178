import {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Layout,
  BoardContentEditor,
  PrimarySquareButton,
  SecondarySquareButton,
} from '@/components/common';
import {sendSiteQNA} from '@/services/boardService';
import {encodeHyphenPhoneNumber, decodeHyphenPhoneNumber} from '@/utils/format';
import {ValidateEmailSchema, ValidatePhoneSchema} from '@/utils/validator';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';

const INIT_ASK_DATA = {
  userName: '',
  userEmail: '',
  userPhone: '',
};

export function AskPage() {
  const navigate = useNavigate();
  const [askData, setAskData] = useState(INIT_ASK_DATA);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const siteData = useRecoilValue(siteState);

  function handleForm(e: ChangeEvent<HTMLInputElement>) {
    const {value, id} = e.target;

    let newValue;
    if (id === 'title') {
      setTitle(value);
      return;
    } else if (id === 'phone') {
      newValue = {...askData, userPhone: encodeHyphenPhoneNumber(value)};
    } else {
      newValue = {...askData, [id]: value};
    }

    setAskData(newValue);
  }

  async function submitAsk() {
    const {userName, userEmail, userPhone} = askData;
    // 데이터 입력 여부 판별
    if (!userName) {
      alert('고객명을 입력해주세요.');
      return;
    }
    try {
      ValidateEmailSchema.validateSync(userEmail);
    } catch (e: any) {
      alert(e.message);
      return;
    }
    try {
      ValidatePhoneSchema.validateSync(decodeHyphenPhoneNumber(userPhone));
    } catch (e: any) {
      alert(e.message);
      return;
    }
    if (!title) {
      alert('제목을 입력해주세요.');
      return;
    }
    // 문의하기 api 요청
    try {
      const data = {
        ...askData,
        title,
        content,
        siteId: siteData?.id || '',
      };
      await sendSiteQNA(data);
      alert('문의가 완료되었습니다.');
      navigate('/');
    } catch (e: any) {
      alert(e);
    }
  }

  useEffect(() => {
    setContent(
      `<p>원할한 상담을 위해 문의 유형을 선택해주세요.</p><p>1. 환불문의&nbsp;&nbsp;&nbsp;&nbsp;2. 장애 신고&nbsp;&nbsp;&nbsp;&nbsp;3. 개선 요구&nbsp;&nbsp;&nbsp;&nbsp;4. 기타 문의</p><p><br></p><p><br></p><p>유형:</p><p>내용:</p><p><br></p><p><br></p>`,
    );
  }, []);

  return (
    <Layout>
      <div className="flex justify-center my-8">
        <h1 className="text-3xl font-semibold">고객 문의</h1>
      </div>
      <div className="w-full px-[10%] md:px-[10%]">
        <div className="w-full px-8 my-4 pb-2 flex items-center truncate">
          <h2 className="text-lg font-semibold mr-4">고객명</h2>
          <input
            id="userName"
            type="text"
            value={askData.userName}
            className="h-8 w-full border-gray-300 focus:border-primary focus:ring-0"
            onChange={handleForm}
          />
        </div>
        <div className="w-full px-8 my-4 pb-2 flex items-center truncate">
          <h2 className="text-lg font-semibold mr-4">이메일</h2>
          <input
            id="userEmail"
            type="email"
            value={askData.userEmail}
            className="h-8 w-full border-gray-300 focus:border-primary focus:ring-0"
            onChange={handleForm}
          />
        </div>
        <div className="w-full px-8 my-4 pb-2 flex items-center truncate">
          <h2 className="text-lg font-semibold mr-4">연락처</h2>
          <input
            id="userPhone"
            type="tel"
            value={askData.userPhone}
            maxLength={13}
            className="h-8 w-full border-gray-300 focus:border-primary focus:ring-0"
            onChange={handleForm}
          />
        </div>
        <div className="w-full px-8 pb-2 mb-8">
          <h2 className="text-lg font-semibold mb-4">제목</h2>
          <input
            id="title"
            type="text"
            value={title}
            maxLength={13}
            className="h-8 w-full border-gray-300 focus:border-primary focus:ring-0"
            onChange={handleForm}
          />
        </div>
        <div className="w-full px-8 pb-2 mb-8">
          <h2 className="text-lg font-semibold mb-4">내용</h2>
          <BoardContentEditor value={content} onChange={setContent} />
        </div>
        <div className="w-full px-4 pb-2 flex justify-center">
          <SecondarySquareButton text="취소" onClick={() => navigate('/')} />
          <PrimarySquareButton onClick={submitAsk} text="문의하기" />
        </div>
      </div>
    </Layout>
  );
}
