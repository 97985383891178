import {RegisterCourse} from '@/types/course.type';
import {genDurationDate, getToday} from '@/utils/format';

export function CourseInfoCard({courseInfo}: {courseInfo?: RegisterCourse}) {
  return (
    <>
      {/* TODO: Skeleton 등으로 courseInfo 가 undefined 일 때 대비하기 */}
      {courseInfo && (
        <div className="w-[80%] mx-auto border border-primary rounded-lg flex flex-col items-center">
          <h2 className="px-4 text-2xl font-bold my-6">{courseInfo.name}</h2>
          <div className="lg:grid lg:grid-cols-2 mb-6">
            <div className={'flex flex-col'}>
              <div className="w-full flex  lg:px-8 px-4 mb-1">
                <span className=" font-semibold whitespace-nowrap mr-4">
                  모집기간
                </span>
                <span>
                  {genDurationDate(
                    courseInfo.recruitStartsAt,
                    courseInfo.recruitEndsAt,
                  )}
                </span>
              </div>
              <div className="w-full flex  lg:px-8 px-4 mb-2">
                <span className=" font-semibold whitespace-nowrap mr-4">
                  교육기간
                </span>
                <span>
                  {genDurationDate(courseInfo.startsAt, courseInfo.endsAt)}
                </span>
              </div>
              <div className="w-full flex  lg:px-8 px-4 mb-2">
                <span className=" font-semibold whitespace-nowrap mr-4">
                  수강료&nbsp;&nbsp;&nbsp;
                </span>
                <span>{courseInfo.courseFee}원</span>
              </div>
              <div className="w-full  flex  lg:px-8 px-4 mb-2">
                <span className=" font-semibold whitespace-nowrap mr-4">
                  모집인원
                </span>
                <span>{courseInfo.applicantsCount}명</span>
              </div>
              <div className="w-full flex  lg:px-8 px-4 mb-2">
                <span className=" font-semibold whitespace-nowrap mr-4">
                  우대조건
                </span>
                <span>{courseInfo.preferentialCondition}</span>
              </div>
              <div className="w-full flex  lg:px-8 px-4 mb-2">
                <span className=" font-semibold whitespace-nowrap mr-4">
                  강의계획
                </span>
                <span>
                  {courseInfo.attachments.length > 0 ? (
                    <a
                      href={courseInfo.attachments[0].fileUrl?.split('?')[0]}
                      className={'underline cursor-pointer'}>
                      {courseInfo.attachments[0].fileName}
                    </a>
                  ) : (
                    `-`
                  )}
                </span>
              </div>
            </div>

            <div className={'flex flex-col'}>
              <div className="w-full flex  lg:px-8 px-4 mb-2">
                <span className=" font-semibold whitespace-nowrap mr-4">
                  교육형태
                </span>
                {courseInfo.courseType === 'ONLINE'
                  ? '원격'
                  : courseInfo.courseType === 'OFFLINE'
                  ? '집합'
                  : '-'}
              </div>
              <div className="w-full flex  lg:px-8 px-4 mb-2">
                <span className=" font-semibold whitespace-nowrap mr-4">
                  모집상태
                </span>
                {courseInfo.recruitStatus === 'Y' &&
                courseInfo.applicantsCount > 0 &&
                getToday() >= courseInfo.recruitStartsAt ? (
                  <span className="text-primary">모집 중</span>
                ) : courseInfo.applicantsCount > 0 &&
                  getToday() < courseInfo.recruitStartsAt ? (
                  <span>모집 예정</span>
                ) : (
                  <span>모집 완료</span>
                )}
                {!courseInfo.recruitStatus && <span> - </span>}
              </div>
              <div className="w-full flex  lg:px-8 px-4 mb-2">
                <span className=" font-semibold whitespace-nowrap mr-4">
                  교육일정
                </span>
                <span>{courseInfo.schedule}</span>
              </div>
              <div className="w-full flex  lg:px-8 px-4 mb-2">
                <span className=" font-semibold whitespace-nowrap mr-4">
                  교육비&nbsp;&nbsp;&nbsp;
                </span>
                <span>{courseInfo.courseIncentive}</span>
              </div>
              <div className="w-full flex  lg:px-8 px-4 mb-2">
                <span className=" font-semibold whitespace-nowrap mr-4">
                  지원조건
                </span>
                <span>{courseInfo.applicationCondition}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
