import {useState, ChangeEvent} from 'react';
import {Layout, Input} from '@/components/common';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';
import {useNavigate, Link} from 'react-router-dom';
import useUser from '@/hooks/useUser';
import {
  IoMailOutline,
  IoLockClosedOutline,
  IoPersonOutline,
  IoPhonePortraitSharp,
} from 'react-icons/io5';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import {encodeHyphenPhoneNumber} from '@/utils/format';
import {ValidatePasswordSchema} from '@/utils/validator';
import {changeUserInfoService} from '@/services/userService';
import Modal from '@/components/common/Modal';
import useModal from '@/hooks/useModal';
import {ValidationError} from 'yup';

const INIT_USER_INFO = {
  name: '',
  email: '',
  phone: '',
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export function UserUpdate() {
  const navigate = useNavigate();
  const siteData = useRecoilValue(siteState);
  const {user, refreshUserState} = useUser();
  const {isShowing, closeModal, modalData, openModal} = useModal();
  const [form, setForm] = useState(INIT_USER_INFO);
  const [formCaption, setFormCaption] = useState(INIT_USER_INFO);
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const logo = siteData?.logo;

  function handleFormChange(e: ChangeEvent<HTMLInputElement>) {
    const {value, id} = e.target;

    const newValue = {
      ...form,
      [id]: value,
    };
    setForm(newValue);

    // validation
    try {
      if (!value) {
        switch (id) {
          case 'currentPassword':
            throw new Error('기존 비밀번호를 입력해주세요.');
          case 'newPassword':
            throw new Error('새 비밀번호를 입력해주세요.');
          case 'confirmPassword':
            throw new Error('비밀번호 확인을 해주세요.');
        }
      }
      setFormCaption({...formCaption, [id]: ''});
    } catch (e: any) {
      setFormCaption({...formCaption, [id]: e.message});
    }
  }

  async function updateUserInfo() {
    const {currentPassword, newPassword, confirmPassword} = form;

    // validation
    if (!currentPassword) {
      setFormCaption({
        ...formCaption,
        currentPassword: '기존 비밀번호를 입력해주세요.',
      });
      return;
    }

    if (!newPassword) {
      setFormCaption({
        ...formCaption,
        newPassword: '새 비밀번호를 입력해주세요.',
      });
      return;
    }

    if (!confirmPassword) {
      setFormCaption({
        ...formCaption,
        confirmPassword: '비밀번호 확인을 해주세요.',
      });
      return;
    }

    try {
      ValidatePasswordSchema.oneOf(
        [newPassword],
        '비밀번호가 일치하지 않습니다.',
      ).validateSync(confirmPassword);
    } catch (e: any) {
      if (e instanceof ValidationError) {
        setFormCaption({
          ...formCaption,
          confirmPassword: e.message,
        });
        return;
      }
    }

    // 비밀번호 변경 api 요청
    try {
      const userId = user!.id;
      const res = await changeUserInfoService(userId, {
        password: currentPassword,
        newPassword,
      });

      if (res) {
        openModal({
          type: 'check',
          message: '정보 수정이 완료되었습니다.',
          buttons: [
            {
              text: '확인',
              style: 'normal',
              onClick: () => {
                navigate('/');
              },
            },
          ],
        });
      }
    } catch (e: any) {
      const error = e.data.error;
      if (error.code === '1202') {
        setFormCaption({
          ...formCaption,
          currentPassword: '기존 비밀번호가 일치하지 않습니다.',
        });
        return;
      }
      if (error.code === '1201') {
        setFormCaption({
          ...formCaption,
          newPassword:
            '현재 비밀번호와 일치합니다. 새로운 비밀번호를 입력해주세요.',
        });
        return;
      }
    }
  }

  return (
    <Layout isHiddenFooter>
      <Modal
        isShowing={isShowing}
        type={modalData?.type}
        title={modalData?.title}
        message={modalData?.message}
        buttons={
          modalData?.buttons || [
            {text: '확인', style: 'normal', onClick: closeModal},
          ]
        }
      />
      <div className="w-full h-full mb-0 flex flex-col items-center py-12">
        <div className="w-full max-w-[500px] flex flex-col items-center my-12">
          {/* 기관 로고 */}
          {logo && (
            <img
              src={logo}
              alt="기관 로고"
              className="w-[200px] md:w-[215px] lg:w-[230px]"
            />
          )}
          {/* 회원가입 폼 */}
          <form className="w-full flex flex-col items-center px-8 my-10">
            {/* 이름 */}
            <Input
              id="name"
              type="text"
              value={user!.name}
              addClass="bg-readOnly"
              disabled
              leftIcon={<IoPersonOutline className="text-lg md:text-xl" />}
            />
            {/* 이메일 */}
            <Input
              id="email"
              type="text"
              value={user!.email}
              addClass="bg-readOnly"
              disabled
              leftIcon={<IoMailOutline className="text-lg md:text-xl" />}
            />
            {/* 휴대폰 번호 */}
            <Input
              id="phone"
              type="text"
              value={encodeHyphenPhoneNumber(user!.phone)}
              addClass="bg-readOnly"
              disabled
              leftIcon={<IoPhonePortraitSharp className="text-lg md:text-xl" />}
            />
            {/* 기존 비밀번호 */}
            <Input
              id="currentPassword"
              type={isCurrentPasswordVisible ? 'text' : 'password'}
              placeholder="기존 비밀번호"
              value={form.currentPassword}
              onChange={handleFormChange}
              leftIcon={<IoLockClosedOutline className="text-lg md:text-xl" />}
              rightIcon={
                isCurrentPasswordVisible ? (
                  <AiOutlineEye
                    className="cursor-pointer text-2xl"
                    onClick={() => {
                      setIsCurrentPasswordVisible(prev => !prev);
                    }}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="cursor-pointer text-xl md:text-2xl"
                    onClick={() => {
                      setIsCurrentPasswordVisible(prev => !prev);
                    }}
                  />
                )
              }
            />
            {formCaption.currentPassword && (
              <p className="text-red-500 w-full text-sm pl-8">
                {formCaption.currentPassword}
              </p>
            )}
            {/* 새 비밀번호 */}
            <Input
              id="newPassword"
              type={isNewPasswordVisible ? 'text' : 'password'}
              placeholder="새 비밀번호"
              value={form.newPassword}
              onChange={handleFormChange}
              leftIcon={<IoLockClosedOutline className="text-lg md:text-xl" />}
              rightIcon={
                isNewPasswordVisible ? (
                  <AiOutlineEye
                    className="cursor-pointer text-2xl"
                    onClick={() => {
                      setIsNewPasswordVisible(prev => !prev);
                    }}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="cursor-pointer text-xl md:text-2xl"
                    onClick={() => {
                      setIsNewPasswordVisible(prev => !prev);
                    }}
                  />
                )
              }
            />
            {formCaption.newPassword && (
              <p className="text-red-500 w-full text-sm pl-8">
                {formCaption.newPassword}
              </p>
            )}
            {/* 새 비밀번호 확인 */}
            <Input
              id="confirmPassword"
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              placeholder="비밀번호 확인"
              value={form.confirmPassword}
              onChange={handleFormChange}
              leftIcon={<IoLockClosedOutline className="text-lg md:text-xl" />}
              rightIcon={
                isConfirmPasswordVisible ? (
                  <AiOutlineEye
                    className="cursor-pointer text-2xl"
                    onClick={() => {
                      setIsConfirmPasswordVisible(prev => !prev);
                    }}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="cursor-pointer text-xl md:text-2xl"
                    onClick={() => {
                      setIsConfirmPasswordVisible(prev => !prev);
                    }}
                  />
                )
              }
            />
            {formCaption.confirmPassword && (
              <p className="text-red-500 w-full text-sm pl-8">
                {formCaption.confirmPassword}
              </p>
            )}
            {/* 확인 버튼 */}
            <span
              onClick={updateUserInfo}
              className="w-28 text-center text-sm md:text-base md:w-32 border border-transparent mt-4 py-2 bg-primary text-white rounded-full transition-all ease-in-out delay-50 hover:bg-white hover:border-primary hover:text-primary cursor-pointer">
              수정
            </span>
          </form>
          <div className="w-full flex justify-center space-x-2">
            <p className="text-sm text-gray-500">탈퇴하고 싶으신가요?</p>
            <Link
              to="/user/withdraw"
              className="text-sm font-semibold text-gray-500 underline text-primary">
              회원 탈퇴
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
