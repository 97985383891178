import {InputHTMLAttributes, useState} from 'react';
import SearchIcon from '@/assets/svgs/main-search-icon.svg';

interface InputSearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onSearch?: (query: string) => void;
}

export function InputSearch({onSearch, ...props}: InputSearchProps) {
  const [searchQuery, setSearchQuery] = useState('');

  function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter' && onSearch) {
      onSearch(searchQuery);
    }
  }

  function handleInput(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(e.target.value);
  }

  return (
    <div className="relative flex items-center w-full h-10 my-4 lg:my-8 rounded-full border border-transparent primary-shadow focus-within:border focus-within:border-primary">
      <input
        type="search"
        id="search-input"
        value={searchQuery}
        onChange={handleInput}
        onKeyPress={handleKeyPress}
        className="w-full h-full px-5 focus:ring-0 border-none rounded-full placeholder-black placeholder:font-light text-black font-base"
        {...props}
      />
      <label
        htmlFor="search-input"
        className="absolute h-7 md:h-8 my-4 right-1 cursor-pointer">
        <img
          src={SearchIcon}
          alt="검색 버튼"
          onClick={() => onSearch && onSearch(searchQuery)}
          className="h-full"
        />
      </label>
    </div>
  );
}
