import {atom} from 'recoil';
import {User} from '@/types/user.type';

const userState = atom<User | null>({
  key: 'userState',
  default: {
    id: '',
    name: '',
    email: '',
    phone: '',
    status: null,
  },
});

export default userState;
