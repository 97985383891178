import {requestPost} from '@/api';
import axios from 'axios';

interface PreSignedResponse {
  id: string;
  fileName: string;
  fileUrl: string;
}
export async function uploadFileService(
  fileName: string,
  file: File,
  parentType?: string,
  parentId?: string,
): Promise<{fileUrl: string; fileId: string}> {
  const data = {
    parentType,
    parentId,
    fileName: [fileName],
  };
  const preSignedResponse = await requestPost<PreSignedResponse[]>(
    '/presigned-url',
    data,
  );

  const preSignedUrls = preSignedResponse.data.data;
  const preSignedUrl = preSignedUrls[0].fileUrl;

  const axiosInstance = axios.create();
  await axiosInstance.put(preSignedUrl, file);

  return {
    fileUrl: preSignedUrl.split('?')[0],
    fileId: preSignedResponse.data.data[0].id,
  };
}

export function uploadImageService(
  {parentId, parentType}: {parentId?: string; parentType: string},
  callback: ({
    fileName,
    fileUrl,
    fileId,
  }: {
    fileName: string;
    fileUrl: string;
    fileId: string;
  }) => void,
) {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.setAttribute('style', 'display:none');
  document.body.appendChild(input);

  input.click();

  input.onchange = async () => {
    if (!input.files || input.files?.length === 0) {
      return;
    }

    const file = input.files[0];
    const fileName = input.files[0].name;

    const {fileUrl, fileId} = await uploadFileService(
      fileName,
      file,
      parentType,
      parentId,
    );

    document.body.querySelector(':scope > input')?.remove();

    callback({fileName, fileUrl, fileId});
  };
}

export function uploadAllTypeFileService(
  {parentId, parentType}: {parentId?: string; parentType: string},
  callback: ({
    fileName,
    fileUrl,
    fileId,
  }: {
    fileName: string;
    fileUrl: string;
    fileId: string;
  }) => void,
) {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('style', 'display:none');
  document.body.appendChild(input);

  input.click();

  input.onchange = async () => {
    if (!input.files || input.files?.length === 0) {
      return;
    }

    const file = input.files[0];
    const fileName = input.files[0].name;

    const {fileUrl, fileId} = await uploadFileService(
      fileName,
      file,
      parentType,
      parentId,
    );

    document.body.querySelector(':scope > input')?.remove();

    callback({fileName, fileUrl, fileId});
  };
}
