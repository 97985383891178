import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface BoardContentEditorProps {
  value: string;
  onChange: (v: string) => void;
}

const QuillModules = {
  toolbar: [
    [{header: [1, 2, 3, 4, 5, false]}],
    ['bold', 'italic', 'underline', 'clean'],
    [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
    [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
    [{color: []}, {background: []}],
    // ['link', 'image'],
    ['link'],
  ],
};

export function BoardContentEditor({value, onChange}: BoardContentEditorProps) {
  return (
    <ReactQuill
      theme={'snow'}
      value={value}
      onChange={onChange}
      modules={QuillModules}
      style={{width: '100%', height: '100%'}}
    />
  );
}
