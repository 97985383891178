import {Link} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import siteState from '@/stores/siteAtom';

export function Footer() {
  const site = useRecoilValue(siteState);
  if (!site) return null;
  const footerData = site.variation.footer;

  return (
    <div className="w-full flex flex-col items-center px-8 mb-6">
      <div className="w-full max-w-[450px] md:max-w-full flex flex-col md:flex-row justify-center space-x-4 md:mt-6">
        <div className="flex justify-evenly my-6 md:flex-col md:mr-8">
          <Link to="/privacy" className="text-sm font-semibold hover:text-primary">
            개인정보처리방침
          </Link>
          <Link to="/service" className="text-sm font-semibold hover:text-primary">
            이용약관
          </Link>
        </div>
        <div className="flex flex-col justify-center ">
          {footerData.map((item: string) => {
            return (
              <p key={item} className="mx-1 text-sm text-gray-700 md:mb-1">
                {item}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
}
